import React from 'react';
import './LoadingScreen.css'; // Asegúrate de crear y vincular este archivo CSS

function LoadingScreen({ message }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-blue-50 z-50">
      <div className="text-center">
        <p className="text-blue-600 text-lg font-semibold mb-2">{message}</p>
        <div className="load"></div>
      </div>
    </div>
  );
}

export default LoadingScreen;
