import React, { useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import ModalPreguntas from './ModalPreguntas'; // Importa el componente del modal

function ResultadoSimulador({ resultado, tipoSimulador, preguntas }) {
    const { correctas, incorrectas, sinResponder, puntaje, aprobado } = resultado;
    const [modalOpen, setModalOpen] = useState(false);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-blue-50 px-4 py-24"> {/* Ajuste de padding */}
            <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4 text-center"> {/* Texto ajustado */}
                Resultado Simulador Licencia Tipo {tipoSimulador}
            </h1>
            <h2 className="text-gray-500 text-sm sm:text-md mb-4 text-center"> {/* Texto ajustado */}
                La nota mínima para aprobar es 16/20 en el examen.
            </h2>
            <div className={`flex items-center py-2 px-4 rounded-full shadow-sm mb-6 ${aprobado ? 'bg-green-100' : 'bg-red-100'}`}>
                {aprobado ? (
                    <CheckCircleOutlineIcon className="text-green-600 mr-2" />
                ) : (
                    <CancelOutlinedIcon className="text-red-600 mr-2" />
                )}
                <p className={`text-lg sm:text-xl font-semibold ${aprobado ? 'text-green-800' : 'text-red-800'}`}>
                    {aprobado ? '¡Aprobado!' : 'Reprobado'}
                </p>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-lg max-w-md w-full">
                <div className="flex justify-between items-center mb-4">
                    <p className="flex items-center text-sm sm:text-lg font-medium text-gray-700">
                        <ThumbUpOutlinedIcon className="mr-2" />
                        Respuestas Correctas
                    </p>
                    <p className="text-sm sm:text-lg font-semibold text-gray-800">{correctas}</p>
                </div>
                <div className="flex justify-between items-center mb-4">
                    <p className="flex items-center text-sm sm:text-lg font-medium text-gray-700">
                        <ThumbDownOutlinedIcon className="mr-2" />
                        Respuestas Incorrectas
                    </p>
                    <p className="text-sm sm:text-lg font-semibold text-gray-800">{incorrectas}</p>
                </div>
                <div className="flex justify-between items-center mb-4">
                    <p className="flex items-center text-sm sm:text-lg font-medium text-gray-700">
                        <HelpOutlineOutlinedIcon className="mr-2" />
                        Sin responder
                    </p>
                    <p className="text-sm sm:text-lg font-semibold text-gray-800">{sinResponder}</p>
                </div>
                <div className="bg-blue-50 p-4 rounded-xl mb-4 text-center">
                    <p className="text-lg sm:text-xl font-semibold text-gray-700">Puntuación total</p>
                    <p className="text-xl sm:text-2xl font-bold text-gray-900">
                        {puntaje}/20
                    </p>
                </div>
            </div>
            <button
                onClick={handleModalOpen}
                className="mt-6 bg-blue-600 text-white py-2 px-4 rounded-full shadow-md"
            >
                Ver Preguntas
            </button>
            <ModalPreguntas
                open={modalOpen}
                handleClose={handleModalClose}
                preguntas={preguntas || []} // Usa un array vacío como fallback
                respuestas={resultado.respuestas || []} // Usa un array vacío como fallback
            />
            <div className="mt-6 p-4 bg-yellow-50 rounded-xl text-center max-w-md w-full shadow-sm">
                <p className="text-yellow-800 font-medium text-sm sm:text-md">
                    {aprobado
                        ? '¡Felicidades, has aprobado el examen!'
                        : '¡Has reprobado! Aún puedes mejorar tu nota revisando el banco de preguntas antes de rendir nuevamente el simulador.'}
                </p>
            </div>
        </div>
    );
}

export default ResultadoSimulador;
