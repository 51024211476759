import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Simulators from './pages/Simulators.js';
import Simulador from './components/Simulador.js';
import PreguntasCorrectas from './components/PreguntasCorrectas.js';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Simulators />} />
        <Route path="/simulador/:simulador" element={<Simulador />} />
        <Route path="/preguntas/:type" element={<PreguntasCorrectas />} />
        {/* Agrega más rutas aquí si necesitas más páginas */}
      </Routes>
    </Router>
  );
}

export default App;
