import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ResultadoSimulador from './ResultadoSimulador';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { preguntasPorSimulador } from '../components/Cuestionario';

function mezclarRespuestas(pregunta) {
  const respuestas = [pregunta.respuesta, ...pregunta.distractores];
  return respuestas.sort(() => Math.random() - 0.5);
}

function cargarPreguntas(simulador) {
  const preguntas = preguntasPorSimulador[simulador] || [];
  return preguntas
    .sort(() => Math.random() - 0.5)
    .slice(0, 20)
    .map((pregunta) => ({
      ...pregunta,
      respuestasMezcladas: mezclarRespuestas(pregunta),
    }));
}

function formatearTiempo(segundos) {
  const minutos = Math.floor(segundos / 60);
  const segundosRestantes = segundos % 60;
  return `${minutos}:${segundosRestantes < 10 ? '0' : ''}${segundosRestantes}`;
}

function Simulador() {
  const { simulador } = useParams(); // Este es el tipo de simulador
  const navigate = useNavigate();
  const [preguntas, setPreguntas] = useState([]);
  const [preguntaActual, setPreguntaActual] = useState(0);
  const [respuestas, setRespuestas] = useState([]);
  const [respuestaSeleccionada, setRespuestaSeleccionada] = useState(null);
  const [tiempoRestante, setTiempoRestante] = useState(1800);
  const [modalVisible, setModalVisible] = useState(false);
  const [resultado, setResultado] = useState(null);

  useEffect(() => {
    const preguntasAleatorias = cargarPreguntas(simulador);
    setPreguntas(preguntasAleatorias);
    setRespuestas(Array(preguntasAleatorias.length).fill(null));
  }, [simulador]);

  useEffect(() => {
    if (tiempoRestante > 0) {
      const timer = setInterval(() => {
        setTiempoRestante((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);

      return () => clearInterval(timer);
    } else {
      finalizarSimulador();
    }
  }, [tiempoRestante]);

  useEffect(() => {
    setRespuestaSeleccionada(respuestas[preguntaActual]);
  }, [preguntaActual, respuestas]);

  const handleRespuestaSeleccionada = (opcion) => {
    const nuevasRespuestas = [...respuestas];
    nuevasRespuestas[preguntaActual] = opcion;
    setRespuestas(nuevasRespuestas);
    setRespuestaSeleccionada(opcion);
  };

  const siguientePregunta = () => {
    setPreguntaActual((prev) => prev + 1);
  };

  const anteriorPregunta = () => {
    if (preguntaActual > 0) {
      setPreguntaActual((prev) => prev - 1);
    }
  };

  const calcularResultadoFinal = () => {
    const correctas = respuestas.filter((respuesta, index) => respuesta === preguntas[index].respuesta).length;
    const incorrectas = respuestas.filter((respuesta, index) => respuesta !== preguntas[index].respuesta && respuesta !== null).length;
    const sinResponder = preguntas.length - correctas - incorrectas;
    const puntaje = correctas;

    const aprobado = puntaje >= 16; // Considerando que la nota mínima para aprobar es 16/20

    setResultado({
      correctas,
      incorrectas,
      sinResponder,
      puntaje,
      aprobado,
      respuestas,
    });
    setTiempoRestante(null);
  };

  const finalizarSimulador = () => {
    calcularResultadoFinal();
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const salirDelSimulador = () => {
    navigate('/'); // Redirige a la página de inicio o donde desees
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-blue-50 p-4 relative">
      <div className="fixed top-0 left-0 right-0 bg-white shadow p-4 flex items-center justify-between z-10">
        {tiempoRestante !== null && (
          <div className="text-blue-600 font-bold text-lg flex items-center">
            <AccessTimeIcon className="mr-2" />
            {`${Math.floor(tiempoRestante / 60).toString().padStart(2, '0')}:${(tiempoRestante % 60)
              .toString()
              .padStart(2, '0')}`}
          </div>
        )}
        <button
          onClick={toggleModal}
          className="text-blue-600"
          title="Salir del simulador"
        >
          <LogoutIcon style={{ fontSize: 30 }} />
        </button>
      </div>

      {resultado ? (
        <ResultadoSimulador
          resultado={resultado}
          tipoSimulador={simulador}
          preguntas={preguntas}
        />
      ) : (
        <>
          <header className="mb-4 text-center pt-28">
            <h1 className="text-3xl font-bold text-blue-800">
              Simulador Licencia Tipo {simulador}
            </h1>
          </header>

          {preguntas.length > 0 && (
            <div className="bg-white p-4 rounded-md shadow-md max-w-md w-full mb-12">
              <h2 className="text-xl font-semibold mb-3 text-center text-gray-800">
                Pregunta {preguntaActual + 1}
              </h2>
              <p className="text-md mb-3 text-center text-gray-600">
                {preguntas[preguntaActual].pregunta}
              </p>
              {preguntas[preguntaActual].imagen && (
                <img
                  src={preguntas[preguntaActual].imagen}
                  alt="Imagen relacionada"
                  className="mb-3 max-h-32 mx-auto"
                />
              )}
              <ul className="space-y-2">
                {preguntas[preguntaActual].respuestasMezcladas.map((opcion, i) => (
                  <li key={i}>
                    <input
                      type="radio"
                      id={`opcion-${i}`}
                      name={`respuesta-${preguntaActual}`}
                      checked={respuestaSeleccionada === opcion}
                      onChange={() => handleRespuestaSeleccionada(opcion)}
                      className="hidden peer"
                    />
                    <label
                      htmlFor={`opcion-${i}`}
                      className="block p-2 text-blue-800 bg-gray-100 rounded-lg shadow-sm cursor-pointer peer-checked:bg-blue-200"
                    >
                      {opcion}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="fixed bottom-0 left-0 right-0 bg-white p-3 shadow-inner flex justify-between">
            {preguntaActual > 0 && (
              <button
                onClick={anteriorPregunta}
                className="bg-blue-600 text-white py-2 px-4 rounded-full text-sm flex items-center"
              >
                <ArrowBackIcon className="mr-2" />
                Atrás
              </button>
            )}
            {preguntaActual < preguntas.length - 1 ? (
              <button
                onClick={siguientePregunta}
                className="bg-blue-600 text-white py-2 px-4 rounded-full text-sm flex items-center"
              >
                Siguiente
                <ArrowForwardIcon className="ml-2" />
              </button>
            ) : (
              <button
                onClick={finalizarSimulador}
                className="bg-blue-600 text-white py-2 px-4 rounded-full text-sm flex items-center"
              >
                Finalizar Simulador
                <CheckCircleIcon className="ml-2" />
              </button>
            )}
          </div>
        </>
      )}

      {modalVisible && (
        <div id="popup-modal" className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50">
          <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow">
              <button
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                onClick={toggleModal}
              >
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                </svg>
                <span className="sr-only">Cerrar modal</span>
              </button>
              <div className="p-4 md:p-5 text-center">
                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                <h3 className="mb-5 text-lg font-normal text-gray-500">¿Estás seguro que deseas salir del simulador?</h3>
                <button
                  onClick={salirDelSimulador}
                  className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                >
                  Sí, estoy seguro
                </button>
                <button
                  onClick={toggleModal}
                  className="py-2.5 px-5 ml-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
                >
                  No, cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Simulador;
