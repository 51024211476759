import React, { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { preguntasPorSimulador } from '../components/Cuestionario';
import LogoutIcon from '@mui/icons-material/Logout';

function PreguntasCorrectas() {
  const { type } = useParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  
  const preguntas = preguntasPorSimulador[type] || [];

  const filteredPreguntas = preguntas.filter(
    (pregunta) =>
      pregunta.pregunta.toLowerCase().includes(searchQuery.toLowerCase()) ||
      pregunta.respuesta.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const salirDelSimulador = () => {
    navigate('/'); // Redirige a la página de inicio o donde desees
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-100 to-blue-50 p-6">

      <div className="fixed top-0 left-0 right-0 bg-white shadow p-4 flex items-center justify-between z-10">
        <input
          type="text"
          placeholder="Buscar por pregunta o respuesta..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="px-4 py-2 border rounded-md w-full max-w-xs"
        />
        <button
          onClick={toggleModal}
          className="text-blue-600 ml-4"
          title="Salir del simulador"
        >
          <LogoutIcon style={{ fontSize: 30 }} />
        </button>
      </div>

      <h1 className="text-3xl font-bold text-blue-900 mb-8 text-center mt-20">
        Preguntas y Respuestas Correctas - Licencia {type}
      </h1>
      <div className="space-y-6 w-full max-w-lg">
        {filteredPreguntas.length > 0 ? (
          filteredPreguntas.map((pregunta, index) => (
            <div 
              key={index}
              className="block bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105"
            >
              <h2 className="text-xl font-semibold text-blue-800 mb-3">
                {`Pregunta ${index + 1}`}
              </h2>
              <p className="text-gray-700 mb-4">{pregunta.pregunta}</p>
              {pregunta.imagen && (
                <img
                  src={pregunta.imagen}
                  alt="Imagen relacionada"
                  className="mb-4 w-full max-h-40 object-contain rounded-lg"
                  style={{ maxHeight: '200px', width: '100%', objectFit: 'contain' }}
                />
              )}
              <div className="bg-green-100 p-4 rounded-md border-l-4 border-green-500">
                <p className="font-semibold text-green-800">Respuesta correcta:</p>
                <p className="text-green-900">{pregunta.respuesta}</p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-600 text-center">No hay preguntas disponibles.</p>
        )}
      </div>
      <Link 
        to="/" 
        className="mt-10 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
      >
        Volver al Inicio
      </Link>

      {modalVisible && (
        <div id="popup-modal" className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50">
          <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow">
              <button
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                onClick={toggleModal}
              >
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                </svg>
                <span className="sr-only">Cerrar modal</span>
              </button>
              <div className="p-4 md:p-5 text-center">
                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                <h3 className="mb-5 text-lg font-normal text-gray-500">¿Estás seguro que deseas salir del banco de preguntas?</h3>
                <button
                  onClick={salirDelSimulador}
                  className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                >
                  Sí, estoy seguro
                </button>
                <button
                  onClick={toggleModal}
                  className="py-2.5 px-5 ml-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
                >
                  No, cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PreguntasCorrectas;
