import React from 'react';
import LicenseSimulator from '../components/LicenseSimulator';

export default function Simulators() {
  const simulators = [
    { type: 'A', imageUrl: 'https://servipase.s3.amazonaws.com/images/ant/images/vehicles/moto.png' },
    { type: 'A1', imageUrl: 'https://servipase.s3.amazonaws.com/images/ant/images/vehicles/tricimoto.png' },
    { type: 'B', imageUrl: 'https://servipase.s3.amazonaws.com/images/ant/images/vehicles/car.png' },
    { type: 'C', imageUrl: 'https://servipase.s3.amazonaws.com/images/ant/images/vehicles/taxi.png' },
    { type: 'C1', imageUrl: 'https://servipase.s3.amazonaws.com/images/ant/images/vehicles/ambulance.png' },
    { type: 'D', imageUrl: 'https://servipase.s3.amazonaws.com/images/ant/images/vehicles/bus.png' },
    { type: 'E', imageUrl: 'https://servipase.s3.amazonaws.com/images/ant/images/vehicles/truck.png' },
    { type: 'F', imageUrl: 'https://servipase.s3.amazonaws.com/images/ant/images/vehicles/car.png' },
    { type: 'G', imageUrl: 'https://servipase.s3.amazonaws.com/images/ant/images/vehicles/tractor.png' },
  ];

  return (
    <div className="max-w-screen-lg mx-auto p-4 bg-blue-50 min-h-screen">
      <header className="mb-8">
        <h1 className="text-4xl font-bold text-center text-blue-900">Simuladores ANT</h1>
        <p className="text-center text-blue-700 mt-2">Prepárate para tu licencia de conducción</p>
      </header>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-1">
        {simulators.map(simulator => (
          <LicenseSimulator
            key={simulator.type}
            type={simulator.type}
            imageUrl={simulator.imageUrl}
          />
        ))}
      </div>
    </div>
  );
}
