import React from 'react';
import Modal from '@mui/material/Modal';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';

function ModalPreguntas({ open, handleClose, preguntas, respuestas }) {
    return (
        <Modal 
            open={open} 
            onClose={handleClose} 
            className="flex items-center justify-center"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflowY: 'auto' }}
        >
            <div className="bg-white p-6 rounded-2xl shadow-lg w-full max-w-md mx-4 relative" style={{ maxHeight: '85vh', overflowY: 'auto' }}>
                <button 
                    onClick={handleClose} 
                    className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
                >
                    <CloseIcon />
                </button>
                <h2 className="text-xl font-bold text-center mb-4 text-gray-800">Revisión de Preguntas</h2>
                <div className="space-y-4">
                    {preguntas.map((pregunta, index) => (
                        <div key={index} className="p-4 border rounded-xl shadow-sm bg-gray-50">
                            <h3 className="text-lg font-semibold mb-2 text-gray-700">Pregunta {index + 1}</h3>
                            <p className="mb-2 text-gray-600">{pregunta.pregunta}</p>
                            <div className="mb-2">
                                <p className="font-semibold flex items-center">
                                    {respuestas[index] === pregunta.respuesta ? (
                                        <CheckCircleOutlineIcon className="text-green-500 mr-2" />
                                    ) : respuestas[index] === null ? (
                                        <HelpOutlineOutlinedIcon className="text-yellow-500 mr-2" />
                                    ) : (
                                        <CancelOutlinedIcon className="text-red-500 mr-2" />
                                    )}
                                    Respuesta seleccionada:
                                </p>
                                <p className="ml-8 text-gray-800">
                                    {respuestas[index] !== null && respuestas[index] !== undefined ? respuestas[index] : 'Sin responder'}
                                </p>
                            </div>
                            <div className="mt-4">
                                <p className="font-semibold flex items-center text-gray-700">
                                    <CheckCircleOutlineIcon className="text-green-500 mr-2" />
                                    Respuesta correcta:
                                </p>
                                <p className="ml-8 text-gray-800">{pregunta.respuesta}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <button 
                    onClick={handleClose} 
                    className="mt-6 w-full bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-3 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-200"
                >
                    Cerrar
                </button>
            </div>
        </Modal>
    );
}

export default ModalPreguntas;
