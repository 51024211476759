import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import PreguntasCorrectas from './PreguntasCorrectas';
import { preguntasPorSimulador } from '../components/Cuestionario'; 

function LicenseSimulator({ type, imageUrl, icon }) {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  const handleLoading = (path, messages) => {
    let loadProgress = 0;
    let messageIndex = 0;

    setLoadingMessage(messages[messageIndex]);
    setLoading(true);

    const interval = setInterval(() => {
      loadProgress += 40;
      setProgress(loadProgress);

      if (messageIndex < messages.length - 1) {
        messageIndex++;
        setLoadingMessage(messages[messageIndex]);
      }

      if (loadProgress >= 100) {
        clearInterval(interval);
        setLoading(false);

        if (path === 'preguntas') {
          navigate(`/preguntas/${type}`);
        } else {
          navigate(path);
        }
      }
    }, 500);
  };

  return (
    <>
      {loading ? (
        <LoadingScreen message={loadingMessage} progress={progress} />
      ) : (
        <div className="bg-white p-2 rounded-md shadow-md transition-transform transform hover:scale-105 sm:p-3 lg:p-3.5">
          <div className="flex justify-center items-center mb-2">
            <span className="material-icons text-blue-600 text-3xl">{icon}</span>
          </div>
          <img 
            src={imageUrl} 
            alt={`Licencia ${type}`} 
            className="w-full h-16 object-contain rounded-md mb-2 sm:h-14 lg:h-12"
          />
          <h3 className="text-center text-xs font-semibold text-blue-800 mb-2 lg:mb-2">
            Licencia {type}
          </h3>
          <div className="flex flex-col items-center space-y-1 sm:space-y-1.5">
            <button 
              onClick={() => handleLoading(`/simulador/${type}`, ['Cargando Simulador...', 'Preparando preguntas...'])}
              className="bg-blue-600 text-white py-1 px-2 rounded-md w-full flex items-center justify-center text-xs font-medium transition-colors hover:bg-blue-700"
            >
              <span className="material-icons mr-1">computer</span>
              Simulador
            </button>
            <button 
              onClick={() => handleLoading('preguntas', ['Cargando Preguntas...', 'Revisando banco de datos...'])}
              className="bg-blue-100 text-blue-700 py-1 px-2 rounded-md w-full flex items-center justify-center text-xs font-medium transition-colors hover:bg-blue-200"
            >
              <span className="material-icons mr-1">help_outline</span>
              Preguntas
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default LicenseSimulator;
