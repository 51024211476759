export const preguntasPorSimulador = {
    A: [
        {
            pregunta: `El no acatar lo indicado en una señal de tránsito, ¿Es una infracción?`,
            respuesta: `VERDADERO`,
            distractores: [`DESCONOCE`, `FALSO`],

        },
        {
            pregunta: `En un semáforo con giro a la derecha o izquierda, si el conductor tiene el semáforo en verde, al igual que los peatones, ¿Quién tiene la preferencia?`,
            imagen: `/img/1.png`,
            respuesta: `Los peatones`,
            distractores: [`Los vehículos`, `Ambos`],

        },
        {
            pregunta: `Cuándo un cruce de vía está regulado por un agente de tránsito, ¿Las señales manuales de éste prevalecen sobre cualquier otra existente, incluso semáforos?`,
            respuesta: `VERDADERO`,
            distractores: [`FALSO`, `DESCONOCE`],

        },
        {
            pregunta: `¿Una línea continua pintada en la calzada de color blanco o amarillo restringe la circulación vehicular de tal manera que ningún vehículo podrá cruzar como se observa en la imagen?`,
            imagen: `/img/2.png`,
            respuesta: `VERDADERO`,
            distractores: [`FALSO`, `DESCONOCE`],

        },
        {
            pregunta: `Una línea discontinua de color amarillo o blanco pintada en la
        calzada, de acuerdo a la imagen ¿Que indica?`,
            imagen: `/img/3.png`,
            respuesta: `Permite rebasar o adelantar sobre esta línea, siempre que exista 
        seguridad para hacerlo`,
            distractores: [`El conductor no puede realizar maniobras de adelantamiento 
        cuando la línea es discontinua`, `Restringe la circulación vehicular de tal manera que ningún 
        vehículo podrá cruzar esta línea`],

        },
        {
            pregunta: `Cuando vea pintado en la calzada una línea mixta, con la línea 
        continua a la izquierda del conductor, de acuerdo a la imagen ¿Qué 
        indica esta línea?`,
            imagen: `/img/4.png`,
            respuesta: `El conductor no puede realizar maniobras de adelantamiento 
  cuando tiene la línea continua a su izquierda`,
            distractores: [`El conductor no puede realizar maniobras de adelantamiento 
  cuando tiene la línea continua a su izquierda
  `, `Puede realizar maniobras de adelantamiento cuando tiene la línea 
  discontinua a la izquierda del conductor`],

        },
        {
            pregunta: `Cuando ve pintado de color blanco o amarillo en la calzada doble línea 
        continua ¿Qué indica?`,
            imagen: `/img/5.png`,
            respuesta: `Están totalmente prohibidas las maniobras para rebasar o 
        adelantar por cualquiera de los carriles que se transite para evitar 
        choques`,
            distractores: [`Puede realizar maniobras de adelantamiento cuando tiene la línea 
  discontinua a la izquierda del conductor`, `Le permite al conductor rebasar o adelantar, siempre que exista la 
        seguridad de hacerlo`],

        },
        {
            pregunta: `De acuerdo a la imagen, ¿Qué debe hacer si compararte carril con un 
        ciclista?`,
            imagen: `/img/6.png`,
            respuesta: `Ambas`,
            distractores: [`Da prioridad a ciclista en el carril compartido`, `Reduce a velocidad y comparte la vía con el ciclista`],

        },
        {
            pregunta: `¿Esta señal se utiliza para indicar?`,
            imagen: `/img/7.png`,
            respuesta: `Obligación de los conductores circular solo en la dirección 
        indicada
        `,
            distractores: [`Un solo carril`, `Doble vía`],

        },
        {
            pregunta: `De acuerdo a la imagen esta señal indica, No exceder de 30 km/h`,
            imagen: `/img/8.png`,
            respuesta: `VERDADERO`,
            distractores: [`FALSO`, `DESCONOCE`],

        },
        {
            pregunta: `De acuerdo a la imagen, ¿esta señal indica prohibido el cambio de 
        carril?`,
            imagen: `/img/9.png`,
            respuesta: `VERDADERO`,
            distractores: [`FALSO`, `DESCONOCE`],

        },
        {
            pregunta: `¿Qué debe hacer cuando usted observa esta señal?`,
            imagen: `/img/10.png`,
            respuesta: `Detenerse antes de la línea de pare y ceder el paso a todo tránsito`,
            distractores: [`Detenerse antes de la línea de pare`, `Reducir la velocidad y estar preparado para detenerse solamente si 
  hay peligro de colisión con otro vehículo o peatón`],

        },
        {
            pregunta: `¿Qué significa está señal?`,
            imagen: `/img/11.png`,
            respuesta: `Viraje prohibido al sentido de la flecha`,
            distractores: [`Viraje permitido en el sentido de la flecha`, `Viraje seguro`],

        },
        {
            pregunta: `¿Qué significa está señal?`,
            imagen: `/img/12.png`,
            respuesta: `No girar en "U"`,
            distractores: [`No virar a la izquierda`, `Tener cuidado con el tráfico que vira`],

        },
        {
            pregunta: `¿Qué significa está señal?`,
            imagen: `/img/13.png`,
            respuesta: `Tránsito pesado debe circular por la derecha`,
            distractores: [`Estacionamiento permitido para camiones`, `Circulación solo tránsito pesado`],

        },
        {
            pregunta: `¿Qué significa está señal?`,
            imagen: `/img/14.png`,
            respuesta: `Prohibido circular vehículos pesados`,
            distractores: [`Prohibido circular vehículos pesados`, `Solo tránsito pesado`],

        },
        {
            pregunta: `¿Qué significa está señal?`,
            imagen: `/img/15.png`,
            respuesta: `Prohibido adelantar o rebasar`,
            distractores: [`Solo tránsito liviano`, `Prohibido doble columna`],

        },
        {
            pregunta: `¿Qué significa está señal?`,
            imagen: `/img/16.png`,
            respuesta: `Vehículos livianos deben mantener su derecha`,
            distractores: [`Vehículos livianos deben girar a la derecha`, `Permitido estacionarse vehículos livianos`],

        },
        {
            pregunta: `¿Qué significa está señal?`,
            imagen: `/img/17.png`,
            respuesta: `Comienza doble vía, pueden venir vehículos en sentido contrario. 
        Precaución`,
            distractores: [`Cambio de carril`, `No entre`],

        },
        {
            pregunta: `¿Qué significa está señal?`,
            imagen: `/img/18.png`,
            respuesta: `Prohibido estacionarse o detenerse`,
            distractores: [`Prohibido estacionarse`, `Permite detenerse por un minuto solamente`],

        },
        {
            pregunta: `¿Qué significa está señal?`,
            imagen: `/img/19.png`,
            respuesta: `No entre`,
            distractores: [`Ninguna de las anteriores`, `Ingreso permitido`],

        },
        {
            pregunta: `¿Qué significa está señal?`,
            imagen: `/img/20.png`,
            respuesta: `Prohibido virar en U o a la izquierda`,
            distractores: [`Prohibido virar en U o a la derecha`, `Prohibido virar a la derecha o a la izquierda`],

        },
        {
            pregunta: `¿Qué significa está señal?`,
            imagen: `/img/21.png`,
            respuesta: `Prohibido cambio de carril a la izquierda`,
            distractores: [`Prohibido virar en U o a la izquierda`, `Prohibido tomar la vía sinuosa`],

        },
        {
            pregunta: `¿Cuáles son las consecuencias de incumplimiento de esta señal 
        (prohibido estacionarse)?`,
            imagen: `/img/22.png`,
            respuesta: `Sanción con el 10% del salario básico unificado`,
            distractores: [`Pena privativa de libertad`, `Retiro de la licencia de conducir.`],

        },
        {
            pregunta: `¿Qué significa está señal?`,
            imagen: `/img/23.png`,
            respuesta: `Prohibido estacionar al lado izquierdo de la señal hasta la próxima 
        intersección`,
            distractores: [`Permitido estacionar a la izquierda hasta la próxima intersección`, `Prohibido estacionar a la derecha hasta la próxima intersección`],

        },
        {
            pregunta: `¿Qué significa está señal?`,
            imagen: `/img/24.png`,
            respuesta: `Prohibido estacionar al lado derecho de la señal hasta la próxima intersección`,
            distractores: [`Prohibición de estacionar a la izquierda hasta la próxima intersección`, `Estacionar a la derecha hasta la próxima intersección`],

        },
        {
            pregunta: `¿Qué significa está señal?`,
            imagen: `/img/25.png`,
            respuesta: `Prohibido estacionar a los dos lados de la señal hasta la próxima intersección a izquierda y derecha`,
            distractores: [`Estacionar a la derecha hasta la próxima intersección`, `Prohibición de estacionar a la izquierda`],

        },
        {
            pregunta: `¿Qué significa está señal?`,
            imagen: `/img/26.png`,
            respuesta: `Estacionamiento permitido`,
            distractores: [`Estacionamiento tarifado`, `Prohibición de estacionar`],

        },
        {
            pregunta: `¿Qué significa está señal?`,
            imagen: `/img/27.png`,
            respuesta: `Estacionamiento tarifado durante los días y horarios señalados`,
            distractores: [`Estacionamiento tarifado a excepción de los días y horarios definidos`, `Estacionamiento no tarifado`],

        },
        {
            pregunta: `¿Qué significa está señal?`,
            imagen: `/img/28.png`,
            respuesta: `Estacionamiento reservado solo para personas con discapacidad`,
            distractores: [`Estacionamiento prohibido para personas con discapacidad`, `Estacionamiento reservado para vehículos grandes`],

        },
        {
            pregunta: `¿Qué significa está señal?`,
            imagen: `/img/29.png`,
            respuesta: `Estacionamiento en reservado solo para personas con discapacidad a los lados de la señal`,
            distractores: [`Estacionamiento en paralelo reservado para vehículos grandes`, `Estacionamiento en paralelo prohibido para personas con discapacidad`],

        },
        {
            pregunta: `¿Qué debe hacer el conductor de un vehículo particular frente a esta señal?`,
            imagen: `/img/30.png`,
            respuesta: `Abstenerse de estacionar o detener el vehículo en esta zona`,
            distractores: [`Detener o estacionar el vehículo en esta zona`, `Aumentar la velocidad o rebasar al bus`],

        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/31.png`,
            respuesta: `Largo máximo de los vehículos que circulan por esa vía`,
            distractores: [`Ancho máximo de los vehículos que circulan por esa vía`, `Ancho de la vía`],

        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/32.png`,
            respuesta: `Carril exclusivo para peatones y ciclistas`,
            distractores: [`Circular con precaución sobre ese carril ya que es compartido con peatones y ciclistas`, `Circular utilizando luces de estacionamiento`],

        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/33.png`,
            respuesta: `Que el carril segregado exclusivo para ciclistas y peatones se termina`,
            distractores: [`Que el carril segregado exclusivo para ciclistas y peatones se termina e inicia en la siguiente intersección`, `Ninguna de las anteriores`],
        },
        {
            pregunta: `¿Qué debe hacer al observar la siguiente señal regulatoria?`,
            imagen: `/img/34.png`,
            respuesta: `Ceder el paso a los conductores, peatones y ciclistas`,
            distractores: [`Ceder el paso solo a los conductores`, `Ceder el paso solo a los peatones`],
        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/35.png`,
            respuesta: `Más adelante intersección en “Y”`,
            distractores: [`Más adelante vía lateral`, `Más adelante cruce de vías`],
        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/36.png`,
            respuesta: `Aproximación a redondel`,
            distractores: [`Más adelante vire en U`, `Más adelante zona de derrumbes`],
        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/37.png`,
            respuesta: `Más adelante circulación en doble vía (bidireccional)`,
            distractores: [`Más adelante circulación en una vía`, `Más adelante vire a la derecha`],
        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/38.png`,
            respuesta: `Más adelante curva abierta hacia el lado que indica la flecha`,
            distractores: [`Más adelante curva cerrada hacia la derecha`, `Más adelante curva y contra curva`],
        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/39.png`,
            respuesta: `Más adelante curva y contra curva cerrada a la derecha en la dirección señalada`,
            distractores: [`Más adelante curva pronunciada a la derecha`, `Más adelante curva abierta a la derecha`],
        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/40.png`,
            respuesta: `Más adelante curva y contra curva abierta a la izquierda en la dirección señalada`,
            distractores: [`Más adelante curva pronunciada a la izquierda`, `Más adelante curva abierta a la izquierda`],
        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/41.png`,
            respuesta: `Más adelante vía sinuosa a la izquierda`,
            distractores: [`Más adelante curva y contra curva cerradas a la izquierda`, `Más adelante curva pronunciada a la izquierda`],
        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/42.png`,
            respuesta: `Más adelante curva en "U" a la izquierda`,
            distractores: [`Más adelante curva en "U" a la izquierda`, `Más adelante curva en "U" a la izquierda`],
        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/43.png`,
            respuesta: `Más adelante bandas transversales de alerta o de retumbo`,
            distractores: [`Más adelante agua en la vía`, `Más adelante hondonada`],
        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/44.png`,
            respuesta: `Más adelante reductor de velocidad`,
            distractores: [`Más adelante calzada irregular`, `Más adelante hondonada`],
        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/45.png`,
            respuesta: `Más adelante angostamiento en la vía`,
            distractores: [`Más adelante pendiente peligrosa`, `Más adelante pendiente peligrosa`],
        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/46.png`,
            respuesta: `Disminuir la velocidad antes de ingresar a la curva`,
            distractores: [`Acelerar`, `Mantener la misma velocidad en la que se encuentra circulando`],
        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/47.png`,
            respuesta: `Aproximación a una curva cerrada en la dirección señalada`,
            distractores: [`Aproximación a una curva abierta a la derecha`, `Aproximación a una contra curva a la derecha`],
        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/48.png`,
            respuesta: `Aproximación a una vía lateral izquierda o derecha en curva`,
            distractores: [`Aproximación a un cruce de vías al virar a la izquierda`, `Aproximación a una curva cerrada a la izquierda`],
        },
        {
            pregunta: `Esta señal se utiliza para indicar:`,
            imagen: `/img/49.png`,
            respuesta: `Dirección y lugar por el cual el flujo de tránsito debe dejar la calzada normal y desviarse por otras calles`,
            distractores: [`Que el desvío se ha terminado y que el flujo del tránsito regresa por el desvío a la ruta original `, `La ubicación y el lugar por el cual el flujo de tránsito debe continuar la calzada normal`],
        },
        {
            pregunta: `¿Qué indica esta señal de tránsito?`,
            imagen: `/img/50.png`,
            respuesta: `Señal con lugar de destino y flecha de asignación de carril`,
            distractores: [`Señal con lugar de destino y mensaje de acciones en palabras`, `Señal con código de ruta, lugar de destino y mensaje de salida con distancia en kilómetros`],
        },
        {
            pregunta: `¿Qué indica esta señal de tránsito?`,
            imagen: `/img/51.png`,
            respuesta: `Zona Escolar`,
            distractores: [`La velocidad máxima en una zona donde se encuentran centros educativos`, `Ninguna de las anteriores`],
        },
        {
            pregunta: `¿Qué indica esta señal de tránsito?`,
            imagen: `/img/52.png`,
            respuesta: `Ambas`,
            distractores: [`Reducir la velocidad y conducir con precaución`, `La velocidad máxima de circulación en una zona escolar`],
        },
        {
            pregunta: `¿Qué indica esta señal de tránsito?`,
            imagen: `/img/53.png`,
            respuesta: `El inicio del paradero de una zona escolar`,
            distractores: [`Parada de vehículos pesados`, `Zona permitida para familiares de estudiantes`],
        },
        {
            pregunta: `La luz amarilla intermitente en un semáforo significa:`,
            respuesta: `Disminuir la velocidad y atravesar la intersección con mucha precaución`,
            distractores: [`Parar siempre y cuando lo pueda hacer con seguridad`, `Parar, ceda el paso a todo el tráfico que cruza antes de atravesar la intersección`],

        },
        {
            pregunta: `¿Las instrucciones del agente de tránsito prevalecen sobre las señales de tránsito aunque el semáforo se 
        encuentre en luz roja?`,
            respuesta: `VERDADERO`,
            distractores: [`FALSO`, `DESCONOCE`],

        },
        {
            pregunta: `¿En cuál de las siguientes luces de semáforo debería siempre detener su vehículo?`,
            respuesta: `Luces rojas y luces rojas intermitentes `,
            distractores: [`Luces rojas continuas, flechas rojas y luces amarillas intermitentes `, `Luces rojas continuas, luces rojas intermitentes y luces amarillas`],

        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/54.png`,
            respuesta: `Motocicletas deben mantenerse en el carril derecho`,
            distractores: [`Motocicletas deben girar a la derecha`, `Permitido estacionarse motocicletas`],

        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/55.png`,
            respuesta: `Prohibido el ingreso o circulación de vehículos motorizados`,
            distractores: [`Prohibida la circulación de buses`, `Prohibida la circulación de vehículos pesados`],

        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/56.png`,
            respuesta: `Más adelante intersección en “T”`,
            distractores: [`Más adelante redondel`, `Más adelante cruce de tren`],

        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/57.png`,
            respuesta: `Más adelante parterre termina`,
            distractores: [`Más adelante angostamiento de vía`, `Más adelante parterre comienza`],

        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/58.png`,
            respuesta: `Más adelante parterre comienza`,
            distractores: [`Más adelante parterre termina`, `Más adelante angostamiento de vía`],

        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/59.png`,
            respuesta: `Más adelante cruce de ganado`,
            distractores: [`Más adelante cruce de peatones`, `Más adelante cruce de maquinaria agrícola`],

        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/60.png`,
            respuesta: `Aproximación a túnel`,
            distractores: [`Túnel obstruido`, `Trabajos en el túnel`],

        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/61.png`,
            respuesta: `Más adelante vía sinuosa a la derecha`,
            distractores: [`Más adelante curva pronunciada a la derecha`, `Más adelante curva y contra curva abiertas a la derecha`],

        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/62.png`,
            respuesta: `Más adelante vía compartida con ciclistas`,
            distractores: [`Más adelante solo vehículos livianos`, `Más adelante solo vehículos livianos`],

        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/63.png`,
            respuesta: `Más adelante depresión en la vía`,
            distractores: [`Más adelante vía cerrada`, `Más adelante reductor de velocidad`],

        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/64.png`,
            respuesta: `Más adelante puente angosto`,
            distractores: [`Más adelante pendiente peligrosa`, `Más adelante estrechamiento en la vía`],

        },
        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/65.png`,
            respuesta: `Límite máximo de velocidad`,
            distractores: [`Velocidad mínima de Km/h`, `Usted se encuentra en el Kilómetro 30`],
        },
        {
            pregunta: `La luz roja intermitente en un semáforo significa:`,
            respuesta: `Parar siempre`,
            distractores: [`Disminuir la velocidad y atravesar la intersección con mucha precaución`, `Parar, ceda el paso a todo el tráfico que cruza antes de atravesar la intersección`],

        },
        {
            pregunta: `La luz roja intermitente en una señal de PARE en el extremo lateral izquierdo de un transporte escolar, 
        ¿Qué significa?`,
            respuesta: `Prohibido adelantar o rebasar al transporte escolar, hasta que se apague la luz intermitente y se 
        guarde la señal de PARE`,
            distractores: [`Disminuir la velocidad y adelantar con precaución al transporte escolar`, `Que se encendió las luces de estacionamiento del transporte escolar`],

        },
        {
            pregunta: `La licencia digital es válida para circular en el Ecuador:`,
            respuesta: `Si`,
            distractores: [`Solo si está impresa`, `Ninguna de las anteriores`],

        },

        {
            pregunta: `Es válido portar una copia simple de la licencia o matrícula para circular en el Ecuador:`,
            respuesta: `No, nunca`,
            distractores: [`Solo si está impresa`, `Ninguna de las anteriores`],

        },
        {
            pregunta: `Debo hacer un curso de conducción para obtener mi licencia de conductor en el Ecuador:`,
            respuesta: `Sí, siempre`,
            distractores: [`Solo si me pide el agente de tránsito`, `No`],

        },
        {
            pregunta: `El no cancelar el valor de multas no le permitirá: renovar la licencia, realizar la revisión técnica vehicular y 
        matricular su vehículo`,
            respuesta: `VERDADERO`,
            distractores: [`FALSO`, `DESCONOCE`],

        },
        {
            pregunta: `¿El conductor que NO haya sido sancionado durante el tiempo de vigencia de la licencia de conducir y 
        mantenga los 30 (puntos), se beneficiará del 30% en el pago del valor de la especie de la licencia de 
        conducir?`,
            respuesta: `FALSO`,
            distractores: [`VERDADERO`, `DESCONOCE`],

        },
        {
            pregunta: `Si conduce su vehículo con un nivel de alcohol por litro de sangre de 0,3 a 0,8; ¿Qué sanción será aplicada?`,
            respuesta: `Cinco días de privación de libertad y retención del vehículo por veinticuatro horas`,
            distractores: [`Treinta días de privación de libertad y retención del vehículo por veinticuatro horas`, `Ninguna de las anteriores`],

        },
        {
            pregunta: `El conductor que falte de obra a la autoridad o agente de tránsito, ¿Será sancionado con pena 
        privativa de libertad de tres días?`,
            respuesta: `VERDADERO`,
            distractores: [`FALSO`, `DESCONOCE`],

        },

        {
            pregunta: `Si se queda sin combustible y por esa razón obstaculiza el tránsito vehicular, ¿Está cometiendo una 
        contravención?`,
            respuesta: `VERDADERO`,
            distractores: [`FALSO`, `DESCONOCE`],

        },
        {
            pregunta: `¿Es una contravención de tránsito, la o el conductor que conduzca con licencia caducada?`,
            respuesta: `VERDADERO`,
            distractores: [`FALSO`, `DESCONOCE`],

        },
        {
            pregunta: `La o el conductor y pasajeros que no hagan uso del cinturón de seguridad, ¿Corresponde a una 
        contravención de transito?`,
            respuesta: `VERDADERO`,
            distractores: [`FALSO`, `DESCONOCE`],

        },
        {
            pregunta: `La o el conductor que invada con su vehículo circulando o estacionándose, en las vías asignadas para uso 
        exclusivo de los ciclistas, ¿Corresponde a una contravención de tránsito?`,
            respuesta: `VERDADERO`,
            distractores: [`FALSO`, `DESCONOCE`],

        },
        {
            pregunta: `Los conductores que conduzcan en estado de embriaguez o bajo los efectos de sustancias estupefacientes, 
        psicotrópicas o preparados que las contengan y del cual provoquen un siniestro del cual resulte la muerte, 
        ¿Qué sanción corresponde?`,
            respuesta: `Revocatoria definitiva de la licencia para conducir`,
            distractores: [`Pena privativa de la libertad de doce a veinte años`, `Ninguna de las anteriores`],

        },
        {
            pregunta: `El conducir un vehículo cuyas llantas se encuentren lisas o en mal estado, ¿Será sancionado con pena 
        privativa de libertad de cinco a quince días?`,
            respuesta: `VERDADERO`,
            distractores: [`FALSO`, `DESCONOCE`],

        },
        {
            pregunta: `El conducir un vehículo con las luces no encendidas en horas de la noche o conduzca en sitios oscuros 
        como túneles con la luces apagadas, ¿Corresponde a una contravención de tránsito?`,
            respuesta: `VERDADERO`,
            distractores: [`FALSO`, `DESCONOCE`],

        },
        {
            pregunta: `¿Cuál de los siguientes enunciados corresponde a una contravención de tránsito?`,
            respuesta: `La o el conductor que conduce sin mantener una distancia prudente de seguimiento respecto al 
        vehículo y ciclista de adelante `,
            distractores: [`La o el conductor que conduce con una distancia prudente de seguimiento respecto al vehículo de 
        adelante`, `La o el conductor que cede el paso y permite el adelantamiento de otros vehículos.`],

        },
        {
            pregunta: `La o el conductor de un vehículo automotor que circule con personas en los estribos o pisaderas, baldes de 
        camionetas, parachoques o colgados de las carrocerías de los vehículos , ¿Es una contravención de 
        transito?`,
            respuesta: `VERDADERO`,
            distractores: [`FALSO`, `DESCONOCE`],

        },
        {
            pregunta: `La o el conductor de un vehículo particular que tenga vidrios con películas antisolares oscuras, polarizados 
        o cualquier tipo de adhesivo y que no tenga la debida autorización, ¿Corresponde a una contravención de 
        tránsito?`,
            respuesta: `VERDADERO`,
            distractores: [`FALSO`, `DESCONOCE`],

        },

        {
            pregunta: `¿Qué indican las líneas blancas que se observan en la imagen?`,
            imagen: `/img/66.png`,
            respuesta: `Líneas de borde`,
            distractores: [`Línea de prohibición de estacionamiento`, `Línea de prohibición de estacionamiento`],

        },
        {
            pregunta: `¿Qué indican las líneas blancas que se observan en la imagen?`,
            imagen: `/img/67.png`,
            respuesta: `Indican a los conductores, especialmente en caso de poca 
        visibilidad, dónde se encuentra el borde de la calzada`,
            distractores: [`Indican a los conductores que es permitido el parqueo vehículos`, `Indican a los conductores que es permitido el parqueo vehículos`],

        },
        {
            pregunta: `¿Qué significan las líneas segmentadas pintadas de blanco en una 
        intersección, que se observan en la imagen ?`,
            imagen: `/img/68.png`,
            respuesta: `Cruce de ciclovías`,
            distractores: [`Cruce peatonal`, `Cruce de mascotas`],

        },

        {
            pregunta: `De acuerdo a la imagen, ¿Las señales reglamentarias indican la 
        dirección obligatoria en el carril que deben seguir los vehículos en la 
        próxima intersección?`,
            imagen: `/img/69.png`,
            respuesta: `VERDADERO`,
            distractores: [`FALSO`, `DESCONOCE`],

        },


        {
            pregunta: `¿Qué debe hacer el conductor cuando en la vía observe un cruce 
        peatonal demarcado tipo cebra como indica la imagen?`,
            imagen: `/img/70.png`,
            respuesta: `Debe ceder el paso a los peatones que se encuentran cruzando la vía`,
            distractores: [`Debe pitar para alertar al peatón que no baje de la vereda`, `Debe acelerar para pasar más rápido`],

        },

        {
            pregunta: `¿Qué debe hacer el conductor cuando se encuentre con una señal 
        horizontal pintada en la vía como se observa en la imagen?`,
            imagen: `/img/71.png`,
            respuesta: `Velocidad máxima de 40 km/h`,
            distractores: [`Velocidad mínima de 40 km/h`, `Velocidad mínima de 40 km/h`],

        },
        {
            pregunta: `De acuerdo a la imagen , ¿Qué significa la siguiente señal horizontal 
        pintada en la vía?`,
            imagen: `/img/72.png`,
            respuesta: `Prohibido estacionar`,
            distractores: [`Estacionar temporalmente cuando no exista un agente de tránsito`, `Estacionar temporalmente cuando no exista un agente de tránsito`],

        },

        {
            pregunta: `¿Qué significa esta señalización horizontal?`,
            imagen: `/img/73.png`,
            respuesta: `Estacionamiento tarifado`,
            distractores: [`No estacionar`, `Estacionamiento gratuito`],

        },

        {
            pregunta: `El conductor cuando se encuentre en una intersección, ¿Debe detener 
        su vehículo, bicicleta y peatones completamente y dar el paso a 
        cualquier vehículo que se encuentre cruzando la intersección?`,
            imagen: `/img/74.png`,
            respuesta: `VERDADERO`,
            distractores: [`FALSO`, `DESCONOCE`],

        },

        {
            pregunta: `Los vehículos A y B llegan al mismo tiempo a una intersección en cruz 
        por diferentes lados ¿Cuál tiene derecho de vía?`,
            imagen: `/img/75.png`,
            respuesta: `El vehículo que se aproxima a la derecha`,
            distractores: [`Cualquiera de los dos`, `El vehículo que se aproxima a la izquierda`],

        },

        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/76.png`,
            respuesta: `Giro a izquierda`,
            distractores: [`Saludo`, `Disminuir la velocidad`],

        },

        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/77.png`,
            respuesta: `Giro a derecha`,
            distractores: [`Saludo`, `Parar`],

        },

        {
            pregunta: `¿Qué significa esta señal?`,
            imagen: `/img/78.png`,
            respuesta: `Estacionarse o detenerse`,
            distractores: [`Solicita ayuda`, `Giro a izquierda`],

        },

        {
            pregunta: `¿Qué significa el espacio de la berma?`,
            imagen: `/img/79.png`,
            respuesta: `Espacio exclusivo para circulación de peatones, ciclistas y 
        movilidad personal`,
            distractores: [`Estacionamiento`, `Carril para circulación de motos`],

        },
        {
            pregunta: `¿Qué es la movilidad activa?`,
            respuesta: `Es aquella que depende del movimiento físico de las personas, incluye la caminata el uso de la 
        bicicleta y movilidad personal`,
            distractores: [`Es aquella que depende del movimiento motorizado, incluye el transporte público`, `Ninguna de las anteriores`],

        },

        {
            pregunta: `¿Cuáles son derechos de los biciusuarios? `,
            respuesta: `Todas las anteriores`,
            distractores: [`Derecho preferente de vía o circulación en los desvíos de avenidas y carreteras, cruce de caminos 
        intersecciones no señalizadas y ciclovías`, `Disponer vías de circulación privilegiada dentro de las ciudades y en carreteras, como ciclovías y 
        espacios similares`],

        },
        {
            pregunta: `De acuerdo a la normativa, ¿Dónde tienen derecho a circular los ciclistas?`,
            respuesta: `Transitar por todas las vías del país con respeto y seguridad.`,
            distractores: [`En las aceras, con cuidado respectivo a los peatones`, `Únicamente en las sendas especiales destinadas al uso de bicicletas`],

        },
        {
            pregunta: `¿En qué momento los conductores son responsables de su seguridad, de los pasajeros y el resto de 
        usuarios viales?`,
            respuesta: `En todo momento`,
            distractores: [`Los conductores no son responsables de lo que el pasajero haga o deje de hacer en cuanto a su 
        seguridad`, `Solo cuando esté encendido el motor del vehículo `],
        },
        {
            pregunta: `¿Qué se entiende por vía pública?`,
            respuesta: `Espacio público destinado al libre tránsito vehicular, bicicletas y peatonal`,
            distractores: [`El lugar por donde circulan los vehículos`, `El lugar por donde circulan los peatones`],
        },
        {
            pregunta: `¿Cuáles son las obligaciones de los peatones en la vía pública?`,
            respuesta: `Cruzar por las esquinas o cruces peatonales en las vías primarías y vías secundarias con más 
        de dos carriles efectivos de circulación; en vías secundarias que cuenten con un máximo de dos 
        carriles efectivos de circulación podrán cruzar en cualquier punto; y siempre y cuando le sea posible 
        hacerlo de manera segura`,
            distractores: [`Solicitar transporte o pedir ayuda a los automovilistas en lugares apropiados o prohibidos `, `Cruzar la calle por la parte anterior y posterior de los automotores que se hayan detenido momentáneamente`],
        },
        {
            pregunta: `¿Cuáles son derechos de los peatones?`,
            respuesta: `Todas las anteriores`,
            distractores: [`Tener libre circulación sobre las aceras y zonas peatonales exclusivas, tener preferencia de vía sobre 
        todas las intersecciones, inclusive en las que tienen semáforo cuando la luz verde peatonal esté 
        encendida, todo el tiempo en los pasos cebra y en las esquinas no reguladas por semáforos, procurando 
        su propia seguridad y la de los demás`, `Disponer de las vías públicas libres de obstáculos y no invadidas, y contar con infraestructura y 
        señalización vial adecuadas que les brinden seguridad, en especial para las personas pertenecientes a los 
        grupos de atención prioritaria`],
        },
        {
            pregunta: `Uno de los objetivos de la Ley de Transporte Terrestre Tránsito y Seguridad Vial, es:`,
            respuesta: `El establecimiento de programas de capacitación y difusión para conductores, peatones, pasajeros y 
        autoridades, en materia de seguridad vial, para la creación de una cultura y conciencia vial 
        responsable y solidaria`,
            distractores: [`Definir sus principios, garantizar el derecho a la educación superior de calidad que propenda a la 
        excelencia`, `Contribuir al desarrollo del País a través de la formulación de políticas, regulaciones, planes, programas 
        y proyectos, que garanticen el buen vivir`],
        },
        {
            pregunta: `¿Cómo se sanciona a los responsables de un siniestro de tránsito que no sean conductores (pasajeros, 
          controladores, peatones, etc.)?`,
            respuesta: `Con las mismas sanciones que un conductor, exceptuando la pérdida de puntos`,
            distractores: [`Con las mismas penas que a un conductor responsable del siniestro`, `Con la mitad de las penas establecidas para un conductor responsable de un siniestro `],
        },
        {
            pregunta: `¿Qué implica conducir con prudencia a pesar de las acciones incorrectas de otros conductores y de las 
        condiciones adversas del clima y las vías, practicando normas de seguridad para no provocar un siniestro 
        de tránsito?`,
            respuesta: `Conducción con precaución`,
            distractores: [`Conducir con rapidez`, `Cumplir con las normas de tránsito`],
        },
        {
            pregunta: `¿Qué implica la prevención de siniestros?`,
            respuesta: `Todas las anteriores`,
            distractores: [`Evitar las conductas riesgosas : exceso de velocidad, consumo de alcohol, no uso de cinturón, casco o 
        sistemas de retención infantil, no respetar las señales de tránsito, manejo distraído`, `Desarrollar un sistema seguro de movilidad, lo cual implica infraestructura para la seguridad vial, control 
        y capacitación de los actores viales`],
        },
        {
            pregunta: `¿Cuáles son los objetivos de la educación vial?`,
            respuesta: `Todas las anteriores`,
            distractores: [`Reducir de forma sistemática los siniestros de tránsito`, `Formar y capacitar a las personas en general para el uso correcto de todos los medios de transporte
        terrestre`],
        },
        {
            pregunta: `Los niños menores de 12 años podrán viajar en:`,
            respuesta: `En los asientos posteriores utilizando los sistemas de retención infantil de acuerdo a la edad y altura`,
            distractores: [`En el asiento delantero con cinturón de seguridad`, `En el asiento delantero sin cinturón de seguridad`],
        },
        {
            pregunta: `¿Para qué sirven los seguros a prueba de niños en los vehículos?`,
            respuesta: `Impiden que las puertas puedan abrirse desde el interior`,
            distractores: [`Bloquean las ventanas traseras manteniéndolas cerradas`, `Aseguran las hebillas de los cinturones de seguridad`],
        },
        {
            pregunta: `El dispositivo de seguridad pasiva que reduce el desplazamiento de la cabeza hacia atrás para evitar el 
        efecto latigazo cervical, se conoce como:`,
            respuesta: `Apoya cabeza`,
            distractores: [`Cinturón de seguridad`, `Airbag`],
        },
        {
            pregunta: `¿Cómo se conoce al dispositivo de seguridad pasiva incluida en algunos vehículos el cual se acciona en 
        milisegundos al producirse un impacto?`,
            respuesta: `Airbag o bolsa de aire`,
            distractores: [`Apoya cabeza`, `Cinturón de seguridad`],
        },
        {
            pregunta: `¿Cuál es la distancia mínima que debe mantener entre vehículo y ciclista al conducir?`,
            respuesta: `1,5 metros`,
            distractores: [`1,0 metros`, `Todas las anteriores`],
        },
        {
            pregunta: `¿Cuándo debo llevar el kit de emergencia o carretero en mi vehículo?`,
            respuesta: `Siempre`,
            distractores: [`Solo Triángulos`, `Solo en caso de emergencia`],
        },
        {
            pregunta: `En un paso cebra no regulado, ¿Quién tiene la preferencia de paso?`,
            respuesta: `Los Peatones`,
            distractores: [`Los vehículos en circulación`, `El transporte público`],
        },
        {
            pregunta: `En caso de avería mecánica en la vía, ¿Debo poner los triángulos o conos de seguridad?`,
            respuesta: `Si, siempre`,
            distractores: [`No es necesario`, `Solo en caso de haber agentes de tránsito`],
        },
        {
            pregunta: `¿A qué distancia mínima antes de una maniobra es obligatorio poner la respectiva luz intermitente?`,
            respuesta: `Mínimo 30 metros antes`,
            distractores: [`Cuando ejecuto la maniobra`, `Mínimo 10 metros antes`],
        },
        {
            pregunta: `En un siniestro de tránsito las autoridades encargadas del control del tránsito, ¿Pueden detener a las 
        personas involucradas?`,
            respuesta: `Pueden detenerlos y ponerlos a las órdenes del fiscal, en el caso donde resultaron personas lesionadas 
        o fallecidas`,
            distractores: [`No pueden detener a nadie hasta que tengan una orden 
        judicial`, `Solamente pueden detener a los vehículos`],
        },
        {
            pregunta: `Al momento de rebasar a un ciclista en circulación, ¿Qué distancia mínima debe tomar el vehículo?`,
            respuesta: `1,5 metros`,
            distractores: [`1 metro`, `50 centímetros`],
        },
        {
            pregunta: `¿Cuál es la distancia mínima de seguimiento que debe mantener entre vehículo y ciclista al conducir?`,
            respuesta: `3 metros`,
            distractores: [`1 metro`, `2 metros`],
        },
        {
            pregunta: `¿Qué debe realizar un conductor cuando ve que un bus escolar e institucional se detiene en una parada?`,
            respuesta: `Esperar y dar el paso a todos los peatones que se bajan del bus`,
            distractores: [`Acelerar la marcha para pasar al bus`, `Ninguna de las anteriores`],
        },
        {
            pregunta: `En las carreteras, ¿Los conductores cuando circulan deben dar preferencia?`,
            respuesta: `Peatones, ciclistas, motociclistas y similares.`,
            distractores: [`Otros conductores`, `Otros conductores`],
        },
        {
            pregunta: `¿Qué se presume si un conductor se niega a realizarse pruebas para la detección de alcohol o de la 
        presencia de sustancias estupefacientes?`,
            respuesta: `Será considerada como presunción de estar en el máximo grado de intoxicación`,
            distractores: [`Solo en el día`, `No`],
        },
        {
            pregunta: `Cuando se conduzca un vehículo a motor y se requiera cruzar una acera para entrar o salir de un 
        estacionamiento, ¿quién tiene preferencia de paso?`,
            respuesta: `Peatones, ciclistas y vehículos`,
            distractores: [`El conductor que está saliendo`, `Solo los vehículos`],
        },
        {
            pregunta: `¿A qué velocidad se estima que un atropellamiento de un niño/a sería mortal?`,
            respuesta: `25km/h`,
            distractores: [`15km/h`, `10km/h`],
        },
        {
            pregunta: `¿A qué velocidad se estima que el atropellamiento a un adulto sería mortal?`,
            respuesta: `60km/h`,
            distractores: [`40km/h`, `20km/h`],
        },
        {
            pregunta: `En caso de que no exista señalización horizontal y/o vertical, ¿Quién tendría derecho de paso en la vía en
        caso de que los usuarios presentes incluyan peatones, ciclistas, vehículos livianos, carga pesada?`,
            respuesta: `Peatones`,
            distractores: [`Biciusuarios`, `Transporte público de pasajeros`],
        },
        {
            pregunta: `Si un peatón va a cruzar una intersección con luz verde del semáforo vehicular o una intersección no 
        regulada y un vehículo va girar, ¿Quién tiene la preferencia de paso?`,
            respuesta: `El peatón `,
            distractores: [`El auto`, `Los dos`],
        },
        {
            pregunta: `¿Qué son las zonas 30?`,
            respuesta: `Zona en donde la velocidad máxima es 30 km/h`,
            distractores: [`Es una zona peatonal`, `Zona en donde la velocidad mínima es 30km/h`],
        },
        {
            pregunta: `¿Por qué la movilidad activa es importante?`,
            respuesta: `Genera menor impacto ambiental, social y económico`,
            distractores: [`Ninguna de las anteriores`, `Está de moda`],
        },
        {
            pregunta: `¿Un pasajero de transporte público puede denunciar ante la autoridad de tránsito, cuando el conductor 
        esté en estado de ebriedad?`,
            respuesta: `Es su obligación`,
            distractores: [`Talvez`, `No`],
        },
        {
            pregunta: `¿En qué porcentaje disminuyen las probabilidades de muerte para un motociclista o ciclista durante un 
        siniestro si utiliza casco homologado?`,
            respuesta: `39%`,
            distractores: [`No disminuyen, aumentan`, `1%`],
        },

        //*PREGUNTAS ESPECIFICAS AQUI*//


        {
            pregunta: `Al viajar en una motocicleta: ¿Qué persona debe utilizar el casco homologado de seguridad?`,
            respuesta: `Conductor y pasajero`,
            distractores: [`Conductor`, `Pasajero`],

        },
        {
            pregunta: `¿Cuál de los siguientes enunciados es una prohibición para los motociclistas?`,
            respuesta: `Transitar en forma paralela, adelantar a otros vehículos por la derecha o entre vehículos que transiten por sus respectivos carriles`,
            distractores: [`Utilizar siempre el carril libre a la izquierda del vehículo al sobrepasar`, `Ninguna de las anteriores`],

        },

        {
            pregunta: `¿Cuál de los siguientes enunciados es un deber de los motociclistas?`,
            respuesta: `Transitar por la derecha de las vías a distancia no mayor de un (1) metro de la acera u orilla y nunca utilizar las vías exclusivas para servicio público`,
            distractores: [`Sujetarse a cualquier otro vehículo que transite por la vía pública o viajar cerca de otro carruaje de mayor tamaño que lo oculte de la vista de los conductores que transiten en sentido contrario`, `Todas las anteriores`],

        },
        {
            pregunta: `Si al conducir una motocicleta, la visibilidad se ve afectada por la niebla o la lluvia, ¿Usted qué debe hacer?`,
            respuesta: `Reducir la velocidad y observar las líneas de demarcación del pavimento, ya que serán una buena referencia`,
            distractores: [`Retirar el casco para mejorar la visibilidad`, `Acelerar y disminuir la distancia al vehículo de adelante para contar con una guía`],

        },

        {
            pregunta: `¿Qué medida deben tomar los conductores de motocicletas para ser vistos por los demás conductores en la vía?`,
            respuesta: `Vestir chalecos o chaquetas con cintas retroreflectivas`,
            distractores: [`Encender las luces altas de la motocicleta para advertir su presencia`, `Conducir entre carriles`],

        },

        {
            pregunta: `Cuando un motociclista sufre un accidente : ¿Qué es lo que nunca debe hacer?`,
            respuesta: `Quitarse el casco`,
            distractores: [`Utilizar el celular para llamar a un familiar`, `No soltar la motocicleta`],

        },

        {
            pregunta: `En una vía urbana, ¿Cuál es el límite máximo de velocidad en el que debe circular una motocicleta?`,
            respuesta: `50 km/h`,
            distractores: [`40 km/h`, `60 km/h`],

        },


        {
            pregunta: `En una vía perimetral, ¿Cuál es el límite máximo de velocidad en el que debe circular una motocicleta?`,
            respuesta: `90 km/h`,
            distractores: [`95 Km/h`, `100 km/h`],

        },


        {
            pregunta: `En una vía recta, ¿Cuál es el límite máximo de velocidad en el que debe circular una motocicleta?`,
            respuesta: `90 km/h`,
            distractores: [`100 km/h`, `110 km/h`],

        },


        {
            pregunta: `¿Qué tipo de licencia no profesional es aquella destinada para conducción de vehículos motorizados como: ciclomotores, motocicletas, tricar y cuadrones?`,
            respuesta: `Tipo A`,
            distractores: [`Tipo B`, `Tipo F`],

        },


        {
            pregunta: `¿Cúal de los siguientes enunciados corresponden a una contravención de tránsito?`,
            respuesta: `La persona que conduzca sin haber obtenido licencia de conducir`,
            distractores: [`La persona que conduzca con la licencia de conducir vigente`, `Ninguna de las anteriores`],

        },

        {
            pregunta: `Al viajar en la motocicleta, la ubicación correcta del pasajero/a es:`,
            respuesta: `Detrás del conductor y en ningún momento entre el conductor y el manubrio`,
            distractores: [`Entre el conductor y el manubrio`, `Ninguna de las anteriores`],

        },

        {
            pregunta: `¿Una motocicleta debe tener los dos espejos retrovisores?`,
            respuesta: `Siempre`,
            distractores: [`Sólo cuando se transita en la zona urbana`, `Sólo cuando se transita en avenidas o autopistas`],

        },
        {
            pregunta: `¿Qué debe hacer si al conducir su motocicleta ésta comienza a tambalearse?`,
            respuesta: `Sujetar el manubrio con firmeza y desacelerar gradualmente`,
            distractores: [`Acelerar para eliminar el tambaleo`, `Bajar marchas`],

        },
        {
            pregunta: `¿Cuál es la protección que brinda el casco al conductor y pasajero de la motocicleta?`,
            respuesta: `Reducen las lesiones en el cuello porque absorben el impacto inicial en un siniestro`,
            distractores: [`Reducen el ruido provocado por los vehículos que circulan alrededor`, `Ninguna de las anteriores`],

        },
        {
            pregunta: `Cuándo otro vehículo le está adelantando, ¿Qué acción debe tomar?`,
            respuesta: `Mantener su velocidad y permanecer en la parte central de su carril`,
            distractores: [`Reducir la velocidad y moverse al lado izquierdo del carril`, `Reducir la velocidad y moverse al lado derecho del carril`],
        },
        {
            pregunta: `Los conductores de motocicletas y similares deberán abstenerse de:`,
            respuesta: `Todas las anteriores`,
            distractores: [`Adelantar a otros vehículos por el lado derecho del carril`, `Transitar junto a vehículos, sobre la línea divisoria de carriles`],

        },
        {
            pregunta: `Si se carga mucho peso en la parte posterior de la motocicleta, ¿Qué efecto causaría?`,
            respuesta: `Elevación en la rueda delantera, por lo que se perderá adherencia y direccionalidad`,
            distractores: [`Elevación en la rueda posterior, por lo que se perderá adherencia y direccionalidad`, `Elevación en la rueda delantera, por lo que se mejorará adherencia y direccionalidad`],

        },
        {
            pregunta: `¿Las ráfagas de viento producidas por vehículos de gran tamaño que circulan en la vía junto a la motocicleta, pueden afectar el control de la misma?`,
            respuesta: `Si, siempre`,
            distractores: [`Si, solo cuando se circula al límite máximo de velocidad`, `No, nunca`],

        },
        // Más preguntas para Simulador A...
    ],
    A1: [
        {
          pregunta: `El no acatar lo indicado en una señal de tránsito, ¿Es una infracción?`,
          respuesta: `VERDADERO`,
          distractores: [`DESCONOCE`, `FALSO`],
      
        },
        {
          pregunta: `En un semáforo con giro a la derecha o izquierda, si el conductor tiene el semáforo en verde, al igual que los peatones, ¿Quién tiene la preferencia?`,
          imagen: `/img/1.png`,
          respuesta: `Los peatones`,
          distractores: [`Los vehículos`, `Ambos`],
      
        },
        {
          pregunta: `Cuándo un cruce de vía está regulado por un agente de tránsito, ¿Las señales manuales de éste prevalecen sobre cualquier otra existente, incluso semáforos?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Una línea continua pintada en la calzada de color blanco o amarillo restringe la circulación vehicular de tal manera que ningún vehículo podrá cruzar como se observa en la imagen?`,
          imagen: `/img/2.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Una línea discontinua de color amarillo o blanco pintada en la
          calzada, de acuerdo a la imagen ¿Que indica?`,
          imagen: `/img/3.png`,
          respuesta: `Permite rebasar o adelantar sobre esta línea, siempre que exista 
          seguridad para hacerlo`,
          distractores: [`El conductor no puede realizar maniobras de adelantamiento 
          cuando la línea es discontinua`, `Restringe la circulación vehicular de tal manera que ningún 
          vehículo podrá cruzar esta línea`],
      
        },
        {
          pregunta: `Cuando vea pintado en la calzada una línea mixta, con la línea 
          continua a la izquierda del conductor, de acuerdo a la imagen ¿Qué 
          indica esta línea?`,
          imagen: `/img/4.png`,
          respuesta: `El conductor no puede realizar maniobras de adelantamiento 
    cuando tiene la línea continua a su izquierda`,
          distractores: [`El conductor no puede realizar maniobras de adelantamiento 
    cuando tiene la línea continua a su izquierda
    `, `Puede realizar maniobras de adelantamiento cuando tiene la línea 
    discontinua a la izquierda del conductor`],
      
        },
        {
          pregunta: `Cuando ve pintado de color blanco o amarillo en la calzada doble línea 
          continua ¿Qué indica?`,
          imagen: `/img/5.png`,
          respuesta: `Están totalmente prohibidas las maniobras para rebasar o 
          adelantar por cualquiera de los carriles que se transite para evitar 
          choques`,
          distractores: [`Puede realizar maniobras de adelantamiento cuando tiene la línea 
    discontinua a la izquierda del conductor`, `Le permite al conductor rebasar o adelantar, siempre que exista la 
          seguridad de hacerlo`],
      
        },
        {
          pregunta: `De acuerdo a la imagen, ¿Qué debe hacer si compararte carril con un 
          ciclista?`,
          imagen: `/img/6.png`,
          respuesta: `Ambas`,
          distractores: [`Da prioridad a ciclista en el carril compartido`, `Reduce a velocidad y comparte la vía con el ciclista`],
      
        },
        {
          pregunta: `¿Esta señal se utiliza para indicar?`,
          imagen: `/img/7.png`,
          respuesta: `Obligación de los conductores circular solo en la dirección 
          indicada
          `,
          distractores: [`Un solo carril`, `Doble vía`],
      
        },
        {
          pregunta: `De acuerdo a la imagen esta señal indica, No exceder de 30 km/h`,
          imagen: `/img/8.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `De acuerdo a la imagen, ¿esta señal indica prohibido el cambio de 
          carril?`,
          imagen: `/img/9.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Qué debe hacer cuando usted observa esta señal?`,
          imagen: `/img/10.png`,
          respuesta: `Detenerse antes de la línea de pare y ceder el paso a todo tránsito`,
          distractores: [`Detenerse antes de la línea de pare`, `Reducir la velocidad y estar preparado para detenerse solamente si 
    hay peligro de colisión con otro vehículo o peatón`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/11.png`,
          respuesta: `Viraje prohibido al sentido de la flecha`,
          distractores: [`Viraje permitido en el sentido de la flecha`, `Viraje seguro`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/12.png`,
          respuesta: `No girar en "U"`,
          distractores: [`No virar a la izquierda`, `Tener cuidado con el tráfico que vira`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/13.png`,
          respuesta: `Tránsito pesado debe circular por la derecha`,
          distractores: [`Estacionamiento permitido para camiones`, `Circulación solo tránsito pesado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/14.png`,
          respuesta: `Prohibido circular vehículos pesados`,
          distractores: [`Prohibido circular vehículos pesados`, `Solo tránsito pesado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/15.png`,
          respuesta: `Prohibido adelantar o rebasar`,
          distractores: [`Solo tránsito liviano`, `Prohibido doble columna`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/16.png`,
          respuesta: `Vehículos livianos deben mantener su derecha`,
          distractores: [`Vehículos livianos deben girar a la derecha`, `Permitido estacionarse vehículos livianos`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/17.png`,
          respuesta: `Comienza doble vía, pueden venir vehículos en sentido contrario. 
          Precaución`,
          distractores: [`Cambio de carril`, `No entre`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/18.png`,
          respuesta: `Prohibido estacionarse o detenerse`,
          distractores: [`Prohibido estacionarse`, `Permite detenerse por un minuto solamente`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/19.png`,
          respuesta: `No entre`,
          distractores: [`Ninguna de las anteriores`, `Ingreso permitido`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/20.png`,
          respuesta: `Prohibido virar en U o a la izquierda`,
          distractores: [`Prohibido virar en U o a la derecha`, `Prohibido virar a la derecha o a la izquierda`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/21.png`,
          respuesta: `Prohibido cambio de carril a la izquierda`,
          distractores: [`Prohibido virar en U o a la izquierda`, `Prohibido tomar la vía sinuosa`],
      
        },
        {
          pregunta: `¿Cuáles son las consecuencias de incumplimiento de esta señal 
          (prohibido estacionarse)?`,
          imagen: `/img/22.png`,
          respuesta: `Sanción con el 10% del salario básico unificado`,
          distractores: [`Pena privativa de libertad`, `Retiro de la licencia de conducir.`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/23.png`,
          respuesta: `Prohibido estacionar al lado izquierdo de la señal hasta la próxima 
          intersección`,
          distractores: [`Permitido estacionar a la izquierda hasta la próxima intersección`, `Prohibido estacionar a la derecha hasta la próxima intersección`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/24.png`,
          respuesta: `Prohibido estacionar al lado derecho de la señal hasta la próxima intersección`,
          distractores: [`Prohibición de estacionar a la izquierda hasta la próxima intersección`, `Estacionar a la derecha hasta la próxima intersección`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/25.png`,
          respuesta: `Prohibido estacionar a los dos lados de la señal hasta la próxima intersección a izquierda y derecha`,
          distractores: [`Estacionar a la derecha hasta la próxima intersección`, `Prohibición de estacionar a la izquierda`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/26.png`,
          respuesta: `Estacionamiento permitido`,
          distractores: [`Estacionamiento tarifado`, `Prohibición de estacionar`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/27.png`,
          respuesta: `Estacionamiento tarifado durante los días y horarios señalados`,
          distractores: [`Estacionamiento tarifado a excepción de los días y horarios definidos`, `Estacionamiento no tarifado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/28.png`,
          respuesta: `Estacionamiento reservado solo para personas con discapacidad`,
          distractores: [`Estacionamiento prohibido para personas con discapacidad`, `Estacionamiento reservado para vehículos grandes`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/29.png`,
          respuesta: `Estacionamiento en reservado solo para personas con discapacidad a los lados de la señal`,
          distractores: [`Estacionamiento en paralelo reservado para vehículos grandes`, `Estacionamiento en paralelo prohibido para personas con discapacidad`],
      
        },
        {
          pregunta: `¿Qué debe hacer el conductor de un vehículo particular frente a esta señal?`,
          imagen: `/img/30.png`,
          respuesta: `Abstenerse de estacionar o detener el vehículo en esta zona`,
          distractores: [`Detener o estacionar el vehículo en esta zona`, `Aumentar la velocidad o rebasar al bus`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/31.png`,
          respuesta: `Largo máximo de los vehículos que circulan por esa vía`,
          distractores: [`Ancho máximo de los vehículos que circulan por esa vía`, `Ancho de la vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/32.png`,
          respuesta: `Carril exclusivo para peatones y ciclistas`,
          distractores: [`Circular con precaución sobre ese carril ya que es compartido con peatones y ciclistas`, `Circular utilizando luces de estacionamiento`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/33.png`,
          respuesta: `Que el carril segregado exclusivo para ciclistas y peatones se termina`,
          distractores: [`Que el carril segregado exclusivo para ciclistas y peatones se termina e inicia en la siguiente intersección`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `¿Qué debe hacer al observar la siguiente señal regulatoria?`,
          imagen: `/img/34.png`,
          respuesta: `Ceder el paso a los conductores, peatones y ciclistas`,
          distractores: [`Ceder el paso solo a los conductores`, `Ceder el paso solo a los peatones`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/35.png`,
          respuesta: `Más adelante intersección en “Y”`,
          distractores: [`Más adelante vía lateral`, `Más adelante cruce de vías`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/36.png`,
          respuesta: `Aproximación a redondel`,
          distractores: [`Más adelante vire en U`, `Más adelante zona de derrumbes`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/37.png`,
          respuesta: `Más adelante circulación en doble vía (bidireccional)`,
          distractores: [`Más adelante circulación en una vía`, `Más adelante vire a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/38.png`,
          respuesta: `Más adelante curva abierta hacia el lado que indica la flecha`,
          distractores: [`Más adelante curva cerrada hacia la derecha`, `Más adelante curva y contra curva`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/39.png`,
          respuesta: `Más adelante curva y contra curva cerrada a la derecha en la dirección señalada`,
          distractores: [`Más adelante curva pronunciada a la derecha`, `Más adelante curva abierta a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/40.png`,
          respuesta: `Más adelante curva y contra curva abierta a la izquierda en la dirección señalada`,
          distractores: [`Más adelante curva pronunciada a la izquierda`, `Más adelante curva abierta a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/41.png`,
          respuesta: `Más adelante vía sinuosa a la izquierda`,
          distractores: [`Más adelante curva y contra curva cerradas a la izquierda`, `Más adelante curva pronunciada a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/42.png`,
          respuesta: `Más adelante curva en "U" a la izquierda`,
          distractores: [`Más adelante curva en "U" a la izquierda`, `Más adelante curva en "U" a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/43.png`,
          respuesta: `Más adelante bandas transversales de alerta o de retumbo`,
          distractores: [`Más adelante agua en la vía`, `Más adelante hondonada`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/44.png`,
          respuesta: `Más adelante reductor de velocidad`,
          distractores: [`Más adelante calzada irregular`, `Más adelante hondonada`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/45.png`,
          respuesta: `Más adelante angostamiento en la vía`,
          distractores: [`Más adelante pendiente peligrosa`, `Más adelante pendiente peligrosa`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/46.png`,
          respuesta: `Disminuir la velocidad antes de ingresar a la curva`,
          distractores: [`Acelerar`, `Mantener la misma velocidad en la que se encuentra circulando`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/47.png`,
          respuesta: `Aproximación a una curva cerrada en la dirección señalada`,
          distractores: [`Aproximación a una curva abierta a la derecha`, `Aproximación a una contra curva a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/48.png`,
          respuesta: `Aproximación a una vía lateral izquierda o derecha en curva`,
          distractores: [`Aproximación a un cruce de vías al virar a la izquierda`, `Aproximación a una curva cerrada a la izquierda`],
        },
        {
          pregunta: `Esta señal se utiliza para indicar:`,
          imagen: `/img/49.png`,
          respuesta: `Dirección y lugar por el cual el flujo de tránsito debe dejar la calzada normal y desviarse por otras calles`,
          distractores: [`Que el desvío se ha terminado y que el flujo del tránsito regresa por el desvío a la ruta original `, `La ubicación y el lugar por el cual el flujo de tránsito debe continuar la calzada normal`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/50.png`,
          respuesta: `Señal con lugar de destino y flecha de asignación de carril`,
          distractores: [`Señal con lugar de destino y mensaje de acciones en palabras`, `Señal con código de ruta, lugar de destino y mensaje de salida con distancia en kilómetros`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/51.png`,
          respuesta: `Zona Escolar`,
          distractores: [`La velocidad máxima en una zona donde se encuentran centros educativos`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/52.png`,
          respuesta: `Ambas`,
          distractores: [`Reducir la velocidad y conducir con precaución`, `La velocidad máxima de circulación en una zona escolar`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/53.png`,
          respuesta: `El inicio del paradero de una zona escolar`,
          distractores: [`Parada de vehículos pesados`, `Zona permitida para familiares de estudiantes`],
        },
        {
          pregunta: `La luz amarilla intermitente en un semáforo significa:`,
          respuesta: `Disminuir la velocidad y atravesar la intersección con mucha precaución`,
          distractores: [`Parar siempre y cuando lo pueda hacer con seguridad`, `Parar, ceda el paso a todo el tráfico que cruza antes de atravesar la intersección`],
      
        },
        {
          pregunta: `¿Las instrucciones del agente de tránsito prevalecen sobre las señales de tránsito aunque el semáforo se 
          encuentre en luz roja?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿En cuál de las siguientes luces de semáforo debería siempre detener su vehículo?`,
          respuesta: `Luces rojas y luces rojas intermitentes `,
          distractores: [`Luces rojas continuas, flechas rojas y luces amarillas intermitentes `, `Luces rojas continuas, luces rojas intermitentes y luces amarillas`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/54.png`,
          respuesta: `Motocicletas deben mantenerse en el carril derecho`,
          distractores: [`Motocicletas deben girar a la derecha`, `Permitido estacionarse motocicletas`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/55.png`,
          respuesta: `Prohibido el ingreso o circulación de vehículos motorizados`,
          distractores: [`Prohibida la circulación de buses`, `Prohibida la circulación de vehículos pesados`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/56.png`,
          respuesta: `Más adelante intersección en “T”`,
          distractores: [`Más adelante redondel`, `Más adelante cruce de tren`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/57.png`,
          respuesta: `Más adelante parterre termina`,
          distractores: [`Más adelante angostamiento de vía`, `Más adelante parterre comienza`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/58.png`,
          respuesta: `Más adelante parterre comienza`,
          distractores: [`Más adelante parterre termina`, `Más adelante angostamiento de vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/59.png`,
          respuesta: `Más adelante cruce de ganado`,
          distractores: [`Más adelante cruce de peatones`, `Más adelante cruce de maquinaria agrícola`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/60.png`,
          respuesta: `Aproximación a túnel`,
          distractores: [`Túnel obstruido`, `Trabajos en el túnel`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/61.png`,
          respuesta: `Más adelante vía sinuosa a la derecha`,
          distractores: [`Más adelante curva pronunciada a la derecha`, `Más adelante curva y contra curva abiertas a la derecha`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/62.png`,
          respuesta: `Más adelante vía compartida con ciclistas`,
          distractores: [`Más adelante solo vehículos livianos`, `Más adelante solo vehículos livianos`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/63.png`,
          respuesta: `Más adelante depresión en la vía`,
          distractores: [`Más adelante vía cerrada`, `Más adelante reductor de velocidad`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/64.png`,
          respuesta: `Más adelante puente angosto`,
          distractores: [`Más adelante pendiente peligrosa`, `Más adelante estrechamiento en la vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/65.png`,
          respuesta: `Límite máximo de velocidad`,
          distractores: [`Velocidad mínima de Km/h`, `Usted se encuentra en el Kilómetro 30`],
        },
        {
          pregunta: `La luz roja intermitente en un semáforo significa:`,
          respuesta: `Parar siempre`,
          distractores: [`Disminuir la velocidad y atravesar la intersección con mucha precaución`, `Parar, ceda el paso a todo el tráfico que cruza antes de atravesar la intersección`],
      
        },
        {
          pregunta: `La luz roja intermitente en una señal de PARE en el extremo lateral izquierdo de un transporte escolar, 
          ¿Qué significa?`,
          respuesta: `Prohibido adelantar o rebasar al transporte escolar, hasta que se apague la luz intermitente y se 
          guarde la señal de PARE`,
          distractores: [`Disminuir la velocidad y adelantar con precaución al transporte escolar`, `Que se encendió las luces de estacionamiento del transporte escolar`],
      
        },
        {
          pregunta: `La licencia digital es válida para circular en el Ecuador:`,
          respuesta: `Si`,
          distractores: [`Solo si está impresa`, `Ninguna de las anteriores`],
      
        },
      
        {
          pregunta: `Es válido portar una copia simple de la licencia o matrícula para circular en el Ecuador:`,
          respuesta: `No, nunca`,
          distractores: [`Solo si está impresa`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `Debo hacer un curso de conducción para obtener mi licencia de conductor en el Ecuador:`,
          respuesta: `Sí, siempre`,
          distractores: [`Solo si me pide el agente de tránsito`, `No`],
      
        },
        {
          pregunta: `El no cancelar el valor de multas no le permitirá: renovar la licencia, realizar la revisión técnica vehicular y 
          matricular su vehículo`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿El conductor que NO haya sido sancionado durante el tiempo de vigencia de la licencia de conducir y 
          mantenga los 30 (puntos), se beneficiará del 30% en el pago del valor de la especie de la licencia de 
          conducir?`,
          respuesta: `FALSO`,
          distractores: [`VERDADERO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Si conduce su vehículo con un nivel de alcohol por litro de sangre de 0,3 a 0,8; ¿Qué sanción será aplicada?`,
          respuesta: `Cinco días de privación de libertad y retención del vehículo por veinticuatro horas`,
          distractores: [`Treinta días de privación de libertad y retención del vehículo por veinticuatro horas`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `El conductor que falte de obra a la autoridad o agente de tránsito, ¿Será sancionado con pena 
          privativa de libertad de tres días?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
      
        {
          pregunta: `Si se queda sin combustible y por esa razón obstaculiza el tránsito vehicular, ¿Está cometiendo una 
          contravención?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Es una contravención de tránsito, la o el conductor que conduzca con licencia caducada?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor y pasajeros que no hagan uso del cinturón de seguridad, ¿Corresponde a una 
          contravención de transito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor que invada con su vehículo circulando o estacionándose, en las vías asignadas para uso 
          exclusivo de los ciclistas, ¿Corresponde a una contravención de tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Los conductores que conduzcan en estado de embriaguez o bajo los efectos de sustancias estupefacientes, 
          psicotrópicas o preparados que las contengan y del cual provoquen un siniestro del cual resulte la muerte, 
          ¿Qué sanción corresponde?`,
          respuesta: `Revocatoria definitiva de la licencia para conducir`,
          distractores: [`Pena privativa de la libertad de doce a veinte años`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `El conducir un vehículo cuyas llantas se encuentren lisas o en mal estado, ¿Será sancionado con pena 
          privativa de libertad de cinco a quince días?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `El conducir un vehículo con las luces no encendidas en horas de la noche o conduzca en sitios oscuros 
          como túneles con la luces apagadas, ¿Corresponde a una contravención de tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Cuál de los siguientes enunciados corresponde a una contravención de tránsito?`,
          respuesta: `La o el conductor que conduce sin mantener una distancia prudente de seguimiento respecto al 
          vehículo y ciclista de adelante `,
          distractores: [`La o el conductor que conduce con una distancia prudente de seguimiento respecto al vehículo de 
          adelante`, `La o el conductor que cede el paso y permite el adelantamiento de otros vehículos.`],
      
        },
        {
          pregunta: `La o el conductor de un vehículo automotor que circule con personas en los estribos o pisaderas, baldes de 
          camionetas, parachoques o colgados de las carrocerías de los vehículos , ¿Es una contravención de 
          transito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor de un vehículo particular que tenga vidrios con películas antisolares oscuras, polarizados 
          o cualquier tipo de adhesivo y que no tenga la debida autorización, ¿Corresponde a una contravención de 
          tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        
        {
          pregunta: `¿Qué indican las líneas blancas que se observan en la imagen?`,
          imagen: `/img/66.png`,
          respuesta: `Líneas de borde`,
          distractores: [`Línea de prohibición de estacionamiento`, `Línea de prohibición de estacionamiento`],
      
        },
        {
          pregunta: `¿Qué indican las líneas blancas que se observan en la imagen?`,
          imagen: `/img/67.png`,
          respuesta: `Indican a los conductores, especialmente en caso de poca 
          visibilidad, dónde se encuentra el borde de la calzada`,
          distractores: [`Indican a los conductores que es permitido el parqueo vehículos`, `Indican a los conductores que es permitido el parqueo vehículos`],
      
        },
        {
          pregunta: `¿Qué significan las líneas segmentadas pintadas de blanco en una 
          intersección, que se observan en la imagen ?`,
          imagen: `/img/68.png`,
          respuesta: `Cruce de ciclovías`,
          distractores: [`Cruce peatonal`, `Cruce de mascotas`],
      
        },
      
        {
          pregunta: `De acuerdo a la imagen, ¿Las señales reglamentarias indican la 
          dirección obligatoria en el carril que deben seguir los vehículos en la 
          próxima intersección?`,
          imagen: `/img/69.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`,`DESCONOCE`],
      
        },
      
      
        {
          pregunta: `¿Qué debe hacer el conductor cuando en la vía observe un cruce 
          peatonal demarcado tipo cebra como indica la imagen?`,
          imagen: `/img/70.png`,
          respuesta: `Debe ceder el paso a los peatones que se encuentran cruzando la vía`,
          distractores: [`Debe pitar para alertar al peatón que no baje de la vereda`, `Debe acelerar para pasar más rápido`],
      
        },
      
        {
          pregunta: `¿Qué debe hacer el conductor cuando se encuentre con una señal 
          horizontal pintada en la vía como se observa en la imagen?`,
          imagen: `/img/71.png`,
          respuesta: `Velocidad máxima de 40 km/h`,
          distractores: [`Velocidad mínima de 40 km/h`, `Velocidad mínima de 40 km/h`],
      
        },
        {
          pregunta: `De acuerdo a la imagen , ¿Qué significa la siguiente señal horizontal 
          pintada en la vía?`,
          imagen: `/img/72.png`,
          respuesta: `Prohibido estacionar`,
          distractores: [`Estacionar temporalmente cuando no exista un agente de tránsito`, `Estacionar temporalmente cuando no exista un agente de tránsito`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señalización horizontal?`,
          imagen: `/img/73.png`,
          respuesta: `Estacionamiento tarifado`,
          distractores: [`No estacionar`, `Estacionamiento gratuito`],
      
        },
      
        {
          pregunta: `El conductor cuando se encuentre en una intersección, ¿Debe detener 
          su vehículo, bicicleta y peatones completamente y dar el paso a 
          cualquier vehículo que se encuentre cruzando la intersección?`,
          imagen: `/img/74.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
      
        {
          pregunta: `Los vehículos A y B llegan al mismo tiempo a una intersección en cruz 
          por diferentes lados ¿Cuál tiene derecho de vía?`,
          imagen: `/img/75.png`,
          respuesta: `El vehículo que se aproxima a la derecha`,
          distractores: [`Cualquiera de los dos`, `El vehículo que se aproxima a la izquierda`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/76.png`,
          respuesta: `Giro a izquierda`,
          distractores: [`Saludo`, `Disminuir la velocidad`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/77.png`,
          respuesta: `Giro a derecha`,
          distractores: [`Saludo`, `Parar`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/78.png`,
          respuesta: `Estacionarse o detenerse`,
          distractores: [`Solicita ayuda`, `Giro a izquierda`],
      
        },
      
        {
          pregunta: `¿Qué significa el espacio de la berma?`,
          imagen: `/img/79.png`,
          respuesta: `Espacio exclusivo para circulación de peatones, ciclistas y 
          movilidad personal`,
          distractores: [`Estacionamiento`, `Carril para circulación de motos`],
      
        },
        {
          pregunta: `¿Qué es la movilidad activa?`,
          respuesta: `Es aquella que depende del movimiento físico de las personas, incluye la caminata el uso de la 
          bicicleta y movilidad personal`,
          distractores: [`Es aquella que depende del movimiento motorizado, incluye el transporte público`, `Ninguna de las anteriores`],
      
        },
      
        {
          pregunta: `¿Cuáles son derechos de los biciusuarios? `,
          respuesta: `Todas las anteriores`,
          distractores: [`Derecho preferente de vía o circulación en los desvíos de avenidas y carreteras, cruce de caminos 
          intersecciones no señalizadas y ciclovías`, `Disponer vías de circulación privilegiada dentro de las ciudades y en carreteras, como ciclovías y 
          espacios similares`],
      
        },
        {
          pregunta: `De acuerdo a la normativa, ¿Dónde tienen derecho a circular los ciclistas?`,
          respuesta: `Transitar por todas las vías del país con respeto y seguridad.`,
          distractores: [`En las aceras, con cuidado respectivo a los peatones`, `Únicamente en las sendas especiales destinadas al uso de bicicletas`],
      
        },
        {
          pregunta: `¿En qué momento los conductores son responsables de su seguridad, de los pasajeros y el resto de 
          usuarios viales?`,
          respuesta: `En todo momento`,
          distractores: [`Los conductores no son responsables de lo que el pasajero haga o deje de hacer en cuanto a su 
          seguridad`, `Solo cuando esté encendido el motor del vehículo `],
        },
        {
          pregunta: `¿Qué se entiende por vía pública?`,
          respuesta: `Espacio público destinado al libre tránsito vehicular, bicicletas y peatonal`,
          distractores: [`El lugar por donde circulan los vehículos`, `El lugar por donde circulan los peatones`],
        },
        {
          pregunta: `¿Cuáles son las obligaciones de los peatones en la vía pública?`,
          respuesta: `Cruzar por las esquinas o cruces peatonales en las vías primarías y vías secundarias con más 
          de dos carriles efectivos de circulación; en vías secundarias que cuenten con un máximo de dos 
          carriles efectivos de circulación podrán cruzar en cualquier punto; y siempre y cuando le sea posible 
          hacerlo de manera segura`,
          distractores: [`Solicitar transporte o pedir ayuda a los automovilistas en lugares apropiados o prohibidos `, `Cruzar la calle por la parte anterior y posterior de los automotores que se hayan detenido momentáneamente`],
        },
        {
          pregunta: `¿Cuáles son derechos de los peatones?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Tener libre circulación sobre las aceras y zonas peatonales exclusivas, tener preferencia de vía sobre 
          todas las intersecciones, inclusive en las que tienen semáforo cuando la luz verde peatonal esté 
          encendida, todo el tiempo en los pasos cebra y en las esquinas no reguladas por semáforos, procurando 
          su propia seguridad y la de los demás`, `Disponer de las vías públicas libres de obstáculos y no invadidas, y contar con infraestructura y 
          señalización vial adecuadas que les brinden seguridad, en especial para las personas pertenecientes a los 
          grupos de atención prioritaria`],
        },
        {
          pregunta: `Uno de los objetivos de la Ley de Transporte Terrestre Tránsito y Seguridad Vial, es:`,
          respuesta: `El establecimiento de programas de capacitación y difusión para conductores, peatones, pasajeros y 
          autoridades, en materia de seguridad vial, para la creación de una cultura y conciencia vial 
          responsable y solidaria`,
          distractores: [`Definir sus principios, garantizar el derecho a la educación superior de calidad que propenda a la 
          excelencia`, `Contribuir al desarrollo del País a través de la formulación de políticas, regulaciones, planes, programas 
          y proyectos, que garanticen el buen vivir`],
        },
        {
          pregunta: `¿Cómo se sanciona a los responsables de un siniestro de tránsito que no sean conductores (pasajeros, 
            controladores, peatones, etc.)?`,
          respuesta: `Con las mismas sanciones que un conductor, exceptuando la pérdida de puntos`,
          distractores: [`Con las mismas penas que a un conductor responsable del siniestro`, `Con la mitad de las penas establecidas para un conductor responsable de un siniestro `],
        },
        {
          pregunta: `¿Qué implica conducir con prudencia a pesar de las acciones incorrectas de otros conductores y de las 
          condiciones adversas del clima y las vías, practicando normas de seguridad para no provocar un siniestro 
          de tránsito?`,
          respuesta: `Conducción con precaución`,
          distractores: [`Conducir con rapidez`, `Cumplir con las normas de tránsito`],
        },
        {
          pregunta: `¿Qué implica la prevención de siniestros?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Evitar las conductas riesgosas : exceso de velocidad, consumo de alcohol, no uso de cinturón, casco o 
          sistemas de retención infantil, no respetar las señales de tránsito, manejo distraído`, `Desarrollar un sistema seguro de movilidad, lo cual implica infraestructura para la seguridad vial, control 
          y capacitación de los actores viales`],
        },
        {
          pregunta: `¿Cuáles son los objetivos de la educación vial?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Reducir de forma sistemática los siniestros de tránsito`, `Formar y capacitar a las personas en general para el uso correcto de todos los medios de transporte
          terrestre`],
        },
        {
          pregunta: `Los niños menores de 12 años podrán viajar en:`,
          respuesta: `En los asientos posteriores utilizando los sistemas de retención infantil de acuerdo a la edad y altura`,
          distractores: [`En el asiento delantero con cinturón de seguridad`, `En el asiento delantero sin cinturón de seguridad`],
        },
        {
          pregunta: `¿Para qué sirven los seguros a prueba de niños en los vehículos?`,
          respuesta: `Impiden que las puertas puedan abrirse desde el interior`,
          distractores: [`Bloquean las ventanas traseras manteniéndolas cerradas`, `Aseguran las hebillas de los cinturones de seguridad`],
        },
        {
          pregunta: `El dispositivo de seguridad pasiva que reduce el desplazamiento de la cabeza hacia atrás para evitar el 
          efecto latigazo cervical, se conoce como:`,
          respuesta: `Apoya cabeza`,
          distractores: [`Cinturón de seguridad`, `Airbag`],
        },
        {
          pregunta: `¿Cómo se conoce al dispositivo de seguridad pasiva incluida en algunos vehículos el cual se acciona en 
          milisegundos al producirse un impacto?`,
          respuesta: `Airbag o bolsa de aire`,
          distractores: [`Apoya cabeza`, `Cinturón de seguridad`],
        },
        {
          pregunta: `¿Cuál es la distancia mínima que debe mantener entre vehículo y ciclista al conducir?`,
          respuesta: `1,5 metros`,
          distractores: [`1,0 metros`, `Todas las anteriores`],
        },
        {
          pregunta: `¿Cuándo debo llevar el kit de emergencia o carretero en mi vehículo?`,
          respuesta: `Siempre`,
          distractores: [`Solo Triángulos`, `Solo en caso de emergencia`],
        },
        {
          pregunta: `En un paso cebra no regulado, ¿Quién tiene la preferencia de paso?`,
          respuesta: `Los Peatones`,
          distractores: [`Los vehículos en circulación`, `El transporte público`],
        },
        {
          pregunta: `En caso de avería mecánica en la vía, ¿Debo poner los triángulos o conos de seguridad?`,
          respuesta: `Si, siempre`,
          distractores: [`No es necesario`, `Solo en caso de haber agentes de tránsito`],
        },
        {
        pregunta: `¿A qué distancia mínima antes de una maniobra es obligatorio poner la respectiva luz intermitente?`,
        respuesta: `Mínimo 30 metros antes`,
        distractores: [`Cuando ejecuto la maniobra`, `Mínimo 10 metros antes`],
         },
        {
          pregunta: `En un siniestro de tránsito las autoridades encargadas del control del tránsito, ¿Pueden detener a las 
          personas involucradas?`,
          respuesta: `Pueden detenerlos y ponerlos a las órdenes del fiscal, en el caso donde resultaron personas lesionadas 
          o fallecidas`,
          distractores: [`No pueden detener a nadie hasta que tengan una orden 
          judicial`, `Solamente pueden detener a los vehículos`],
        },
        {
          pregunta: `Al momento de rebasar a un ciclista en circulación, ¿Qué distancia mínima debe tomar el vehículo?`,
          respuesta: `1,5 metros`,
          distractores: [`1 metro`, `50 centímetros`],
        },
        {
          pregunta: `¿Cuál es la distancia mínima de seguimiento que debe mantener entre vehículo y ciclista al conducir?`,
          respuesta: `3 metros`,
          distractores: [`1 metro`, `2 metros`],
        },
        {
          pregunta: `¿Qué debe realizar un conductor cuando ve que un bus escolar e institucional se detiene en una parada?`,
          respuesta: `Esperar y dar el paso a todos los peatones que se bajan del bus`,
          distractores: [`Acelerar la marcha para pasar al bus`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `En las carreteras, ¿Los conductores cuando circulan deben dar preferencia?`,
          respuesta: `Peatones, ciclistas, motociclistas y similares.`,
          distractores: [`Otros conductores`, `Otros conductores`],
        },
        {
          pregunta: `¿Qué se presume si un conductor se niega a realizarse pruebas para la detección de alcohol o de la 
          presencia de sustancias estupefacientes?`,
          respuesta: `Será considerada como presunción de estar en el máximo grado de intoxicación`,
          distractores: [`Solo en el día`, `No`],
        },
        {
          pregunta: `Cuando se conduzca un vehículo a motor y se requiera cruzar una acera para entrar o salir de un 
          estacionamiento, ¿quién tiene preferencia de paso?`,
          respuesta: `Peatones, ciclistas y vehículos`,
          distractores: [`El conductor que está saliendo`, `Solo los vehículos`],
        },
        {
          pregunta: `¿A qué velocidad se estima que un atropellamiento de un niño/a sería mortal?`,
          respuesta: `25km/h`,
          distractores: [`15km/h`, `10km/h`],
        },
        {
          pregunta: `¿A qué velocidad se estima que el atropellamiento a un adulto sería mortal?`,
          respuesta: `60km/h`,
          distractores: [`40km/h`, `20km/h`],
        },
        {
          pregunta: `En caso de que no exista señalización horizontal y/o vertical, ¿Quién tendría derecho de paso en la vía en
          caso de que los usuarios presentes incluyan peatones, ciclistas, vehículos livianos, carga pesada?`,
          respuesta: `Peatones`,
          distractores: [`Biciusuarios`, `Transporte público de pasajeros`],
        },
        {
          pregunta: `Si un peatón va a cruzar una intersección con luz verde del semáforo vehicular o una intersección no 
          regulada y un vehículo va girar, ¿Quién tiene la preferencia de paso?`,
          respuesta: `El peatón `,
          distractores: [`El auto`, `Los dos`],
        },
        {
          pregunta: `¿Qué son las zonas 30?`,
          respuesta: `Zona en donde la velocidad máxima es 30 km/h`,
          distractores: [`Es una zona peatonal`, `Zona en donde la velocidad mínima es 30km/h`],
        },
        {
          pregunta: `¿Por qué la movilidad activa es importante?`,
          respuesta: `Genera menor impacto ambiental, social y económico`,
          distractores: [`Ninguna de las anteriores`, `Está de moda`],
        },
        {
          pregunta: `¿Un pasajero de transporte público puede denunciar ante la autoridad de tránsito, cuando el conductor 
          esté en estado de ebriedad?`,
          respuesta: `Es su obligación`,
          distractores: [`Talvez`, `No`],
        },
        {
          pregunta: `¿En qué porcentaje disminuyen las probabilidades de muerte para un motociclista o ciclista durante un 
          siniestro si utiliza casco homologado?`,
          respuesta: `39%`,
          distractores: [`No disminuyen, aumentan`, `1%`],
        },
        
      
    
      //*PREGUNTAS ESPECIFICAS AQUI*//
    
      
        {
          pregunta: `En una tricimoto ¿Qué persona debe utilizar el casco homologado de seguridad?`,
          respuesta: `Conductor`,
          distractores: [`Pasajero`, `Conductor y pasajero`],
      
        },
        {
          pregunta: `¿Cuál de los siguientes enunciados es una prohibición para las tricimotos?`,
          respuesta: `Transitar en forma paralela, adelantar a otros vehículos por la derecha o entre vehículos
      que transiten por sus respectivos carriles`,
          distractores: [`Utilizar siempre el carril libre a la izquierda del vehículo al sobrepasar`, `Ninguna de las anteriores`],
      
        },
      
        {
          pregunta: `En una tricimoto ¿Hasta cuántos pasajeros es permitido transportar?`,
          respuesta: `Cuatro`,
          distractores: [`Tres`, `Cinco`],
      
        },
      
        {
          pregunta: `Los conductores de tricimotos deberán abstenerse de:`,
          respuesta: `Todas las anteriores`,
          distractores: [`Adelantar a otros vehículos por el lado derecho del carril
      `, `Transitar junto a vehículos, sobre la línea divisoria de carriles`],
      
        },
      
        {
          pregunta: `Si el casco homologado sufre un daño: ¿Debe cambiarlo por uno nuevo?`,
          respuesta: `Sí, siempre`,
          distractores: [`No, solo debe revisarlo`, `No, dependiendo del daño se lo puede reparar`],
      
        },
      
        {
          pregunta: `Si al conducir una tricimoto, la visibilidad se ve afectada por la niebla o la lluvia, ¿Usted
      qué debe hacer?`,
          respuesta: `Reducir la velocidad y observar las líneas de demarcación del pavimento, ya que serán
      una buena referencia`,
          distractores: [`Retirar el casco para mejorar la visibilidad`, `Acelerar y disminuir la distancia al vehículo de adelante para contar con una guía`],
      
        },
      
        {
          pregunta: `Cuándo otro vehículo le está adelantando, ¿Qué acción debe tomar?`,
          respuesta: `Mantener su velocidad y permanecer en la parte central de su carril`,
          distractores: [`Reducir la velocidad y moverse al lado izquierdo del carri`, `Reducir la velocidad y moverse al lado derecho del carril`],
      
        },
        {
          pregunta: `Cuando el conductor de una tricimoto sufre un accidente: ¿Qué es lo que nunca debe
      hacer?`,
          respuesta: `Quitarse el casco`,
          distractores: [`Utilizar el celular para llamar a un familia`, `De manera inminente no soltar la tricimoto`],
      
        },
      
        {
          pregunta: `En una vía urbana, ¿Cuál es el límite máximo de velocidad en el que debe circular una
      tricimoto?`,
          respuesta: `50 km/h`,
          distractores: [`40 km/h`, `60 km/h`],
      
        },
        {
          pregunta: `En una vía perimetral, ¿Cuál es el límite máximo de velocidad en el que debe circular una tricimoto?`,
          respuesta: `90 Km/h`,
          distractores: [`95 Km/h`, `100 Km/h`],
      
        },
      
        {
          pregunta: `En una vía recta, ¿Cuál es el límite máximo de velocidad en el que debe circular una
      tricimoto?`,
          respuesta: `90 km/h`,
          distractores: [`100 km/h`, `110 km/h`],
      
        },
        
        {
          pregunta: `¿Qué debe hacer si al conducir su tricimoto ésta comienza a tambalearse?`,
          respuesta: `Sujetar el manubrio con firmeza y desacelerar gradualmente`,
          distractores: [`Acelerar para eliminar el tambaleo`, `Bajar marchas`],
      
        },
    
        {
          pregunta: `¿Cuál de los siguientes enunciados corresponde a una contravención de tránsito, para conductores de 
          tricimotos?`,
          respuesta: `La persona que conduzca sin haber obtenido licencia de conducir`,
          distractores: [`La persona que utilice el casco homologado`, `La persona que conduzca con licencia tipo A`],
      
        },
        {
          pregunta: `¿Es correcto conducir escuchando música con auriculares (audífonos)?`,
          respuesta: `No, porque dificultan una correcta audición del entorno`,
          distractores: [`Sí, cuando se circula por carreteras`, `Si, solo cuando se circula por áreas urbanas`],
      
        },
        {
          pregunta: `Para el otorgamiento de la licencia de conducir tipo A 1 por primera vez es necesario:`,
          respuesta: `Todas las anteriores`,
          distractores: [`Haber aprobado el curso a cargo de las escuelas de conducción`, `Haber aprobado el examen Psicosensométrico`],
      
        },
        {
          pregunta: `Previo a iniciar la conducción de una tricimoto debe asegurarse de:`,
          respuesta: `Ajustar los espejos para tener un adecuado ángulo de visión de parte del carril de su costado y detrás de 
          usted`,
          distractores: [`Ninguna de las anteriores`, `Verificar el nivel de combustible de su tricimoto`],
      
        },
    
        {
          pregunta: `¿Cuál de los siguientes enunciados corresponde a una contravención de tránsito, para
      conductores de tricimotos?`,
          respuesta: `La persona que conduzca sin haber obtenido licencia de conducir`,
          distractores: [`La persona que utilice el casco homologado`, `La persona que conduzca con licencia tipo A`],
      
        },
        
        {
          pregunta: `¿Es correcto conducir escuchando música con auriculares (audífonos)?`,
          respuesta: `No, porque dificultan una correcta audición del entorno`,
          distractores: [`Sí, cuando se circula por carreteras`, `Si, solo cuando se circula por áreas urbanas`],
      
        },
        
        {
          pregunta: `¿Cuál es la principal causa de muerte en los conductores de tricimotos?`,
          respuesta: `Lesiones en la cabeza`,
          distractores: [`Lesiones en las piernas`, `Lesiones en el abdomen`],
      
        },
        
        {
          pregunta: `Para el otorgamiento de la licencia de conducir tipo A 1 por primera vez es necesario:`,
          respuesta: `Todas las anteriores`,
          distractores: [`Haber aprobado el curso a cargo de las escuelas de conducción `, `Haber aprobado el examen Psicosensométrico`],
      
        },
        
        {
          pregunta: `Previo a iniciar la conducción de una tricimoto debe asegurarse de:`,
          respuesta: `Ajustar los espejos para tener un adecuado ángulo de visión de parte del carril de su
      costado y detrás de usted`,
          distractores: [`Verificar el nivel de combustible de su tricimoto`, `Ninguna de las anteriores`],
      
        },
        
        {
          pregunta: `¿Una tricimoto debe tener los dos espejos retrovisores?`,
          respuesta: `Siempre`,
          distractores: [`Sólo cuando se transita en la zona urbana`, `Sólo cuando se transita en avenidas o autopistas`],
      
        },
        
        {
          pregunta: `¿Dónde es permitida la circulación de tricimotos?`,
          respuesta: `En lugares donde sea segura y posible su prestación`,
          distractores: [`En lugares donde sea más conveniente la explotación del servicio`, `En autopistas y vías de alta velocidad`],
      
        }
      
      
      ],
      B: [
        {
          pregunta: `El no acatar lo indicado en una señal de tránsito, ¿Es una infracción?`,
          respuesta: `VERDADERO`,
          distractores: [`DESCONOCE`, `FALSO`],
      
        },
        {
          pregunta: `En un semáforo con giro a la derecha o izquierda, si el conductor tiene el semáforo en verde, al igual que los peatones, ¿Quién tiene la preferencia?`,
          imagen: `/img/1.png`,
          respuesta: `Los peatones`,
          distractores: [`Los vehículos`, `Ambos`],
      
        },
        {
          pregunta: `Cuándo un cruce de vía está regulado por un agente de tránsito, ¿Las señales manuales de éste prevalecen sobre cualquier otra existente, incluso semáforos?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Una línea continua pintada en la calzada de color blanco o amarillo restringe la circulación vehicular de tal manera que ningún vehículo podrá cruzar como se observa en la imagen?`,
          imagen: `/img/2.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Una línea discontinua de color amarillo o blanco pintada en la
          calzada, de acuerdo a la imagen ¿Que indica?`,
          imagen: `/img/3.png`,
          respuesta: `Permite rebasar o adelantar sobre esta línea, siempre que exista 
          seguridad para hacerlo`,
          distractores: [`El conductor no puede realizar maniobras de adelantamiento 
          cuando la línea es discontinua`, `Restringe la circulación vehicular de tal manera que ningún 
          vehículo podrá cruzar esta línea`],
      
        },
        {
          pregunta: `Cuando vea pintado en la calzada una línea mixta, con la línea 
          continua a la izquierda del conductor, de acuerdo a la imagen ¿Qué 
          indica esta línea?`,
          imagen: `/img/4.png`,
          respuesta: `El conductor no puede realizar maniobras de adelantamiento 
    cuando tiene la línea continua a su izquierda`,
          distractores: [`El conductor no puede realizar maniobras de adelantamiento 
    cuando tiene la línea continua a su izquierda
    `, `Puede realizar maniobras de adelantamiento cuando tiene la línea 
    discontinua a la izquierda del conductor`],
      
        },
        {
          pregunta: `Cuando ve pintado de color blanco o amarillo en la calzada doble línea 
          continua ¿Qué indica?`,
          imagen: `/img/5.png`,
          respuesta: `Están totalmente prohibidas las maniobras para rebasar o 
          adelantar por cualquiera de los carriles que se transite para evitar 
          choques`,
          distractores: [`Puede realizar maniobras de adelantamiento cuando tiene la línea 
    discontinua a la izquierda del conductor`, `Le permite al conductor rebasar o adelantar, siempre que exista la 
          seguridad de hacerlo`],
      
        },
        {
          pregunta: `De acuerdo a la imagen, ¿Qué debe hacer si compararte carril con un 
          ciclista?`,
          imagen: `/img/6.png`,
          respuesta: `Ambas`,
          distractores: [`Da prioridad a ciclista en el carril compartido`, `Reduce a velocidad y comparte la vía con el ciclista`],
      
        },
        {
          pregunta: `¿Esta señal se utiliza para indicar?`,
          imagen: `/img/7.png`,
          respuesta: `Obligación de los conductores circular solo en la dirección 
          indicada
          `,
          distractores: [`Un solo carril`, `Doble vía`],
      
        },
        {
          pregunta: `De acuerdo a la imagen esta señal indica, No exceder de 30 km/h`,
          imagen: `/img/8.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `De acuerdo a la imagen, ¿esta señal indica prohibido el cambio de 
          carril?`,
          imagen: `/img/9.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Qué debe hacer cuando usted observa esta señal?`,
          imagen: `/img/10.png`,
          respuesta: `Detenerse antes de la línea de pare y ceder el paso a todo tránsito`,
          distractores: [`Detenerse antes de la línea de pare`, `Reducir la velocidad y estar preparado para detenerse solamente si 
    hay peligro de colisión con otro vehículo o peatón`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/11.png`,
          respuesta: `Viraje prohibido al sentido de la flecha`,
          distractores: [`Viraje permitido en el sentido de la flecha`, `Viraje seguro`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/12.png`,
          respuesta: `No girar en "U"`,
          distractores: [`No virar a la izquierda`, `Tener cuidado con el tráfico que vira`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/13.png`,
          respuesta: `Tránsito pesado debe circular por la derecha`,
          distractores: [`Estacionamiento permitido para camiones`, `Circulación solo tránsito pesado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/14.png`,
          respuesta: `Prohibido circular vehículos pesados`,
          distractores: [`Prohibido circular vehículos pesados`, `Solo tránsito pesado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/15.png`,
          respuesta: `Prohibido adelantar o rebasar`,
          distractores: [`Solo tránsito liviano`, `Prohibido doble columna`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/16.png`,
          respuesta: `Vehículos livianos deben mantener su derecha`,
          distractores: [`Vehículos livianos deben girar a la derecha`, `Permitido estacionarse vehículos livianos`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/17.png`,
          respuesta: `Comienza doble vía, pueden venir vehículos en sentido contrario. 
          Precaución`,
          distractores: [`Cambio de carril`, `No entre`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/18.png`,
          respuesta: `Prohibido estacionarse o detenerse`,
          distractores: [`Prohibido estacionarse`, `Permite detenerse por un minuto solamente`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/19.png`,
          respuesta: `No entre`,
          distractores: [`Ninguna de las anteriores`, `Ingreso permitido`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/20.png`,
          respuesta: `Prohibido virar en U o a la izquierda`,
          distractores: [`Prohibido virar en U o a la derecha`, `Prohibido virar a la derecha o a la izquierda`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/21.png`,
          respuesta: `Prohibido cambio de carril a la izquierda`,
          distractores: [`Prohibido virar en U o a la izquierda`, `Prohibido tomar la vía sinuosa`],
      
        },
        {
          pregunta: `¿Cuáles son las consecuencias de incumplimiento de esta señal 
          (prohibido estacionarse)?`,
          imagen: `/img/22.png`,
          respuesta: `Sanción con el 10% del salario básico unificado`,
          distractores: [`Pena privativa de libertad`, `Retiro de la licencia de conducir.`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/23.png`,
          respuesta: `Prohibido estacionar al lado izquierdo de la señal hasta la próxima 
          intersección`,
          distractores: [`Permitido estacionar a la izquierda hasta la próxima intersección`, `Prohibido estacionar a la derecha hasta la próxima intersección`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/24.png`,
          respuesta: `Prohibido estacionar al lado derecho de la señal hasta la próxima intersección`,
          distractores: [`Prohibición de estacionar a la izquierda hasta la próxima intersección`, `Estacionar a la derecha hasta la próxima intersección`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/25.png`,
          respuesta: `Prohibido estacionar a los dos lados de la señal hasta la próxima intersección a izquierda y derecha`,
          distractores: [`Estacionar a la derecha hasta la próxima intersección`, `Prohibición de estacionar a la izquierda`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/26.png`,
          respuesta: `Estacionamiento permitido`,
          distractores: [`Estacionamiento tarifado`, `Prohibición de estacionar`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/27.png`,
          respuesta: `Estacionamiento tarifado durante los días y horarios señalados`,
          distractores: [`Estacionamiento tarifado a excepción de los días y horarios definidos`, `Estacionamiento no tarifado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/28.png`,
          respuesta: `Estacionamiento reservado solo para personas con discapacidad`,
          distractores: [`Estacionamiento prohibido para personas con discapacidad`, `Estacionamiento reservado para vehículos grandes`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/29.png`,
          respuesta: `Estacionamiento en reservado solo para personas con discapacidad a los lados de la señal`,
          distractores: [`Estacionamiento en paralelo reservado para vehículos grandes`, `Estacionamiento en paralelo prohibido para personas con discapacidad`],
      
        },
        {
          pregunta: `¿Qué debe hacer el conductor de un vehículo particular frente a esta señal?`,
          imagen: `/img/30.png`,
          respuesta: `Abstenerse de estacionar o detener el vehículo en esta zona`,
          distractores: [`Detener o estacionar el vehículo en esta zona`, `Aumentar la velocidad o rebasar al bus`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/31.png`,
          respuesta: `Largo máximo de los vehículos que circulan por esa vía`,
          distractores: [`Ancho máximo de los vehículos que circulan por esa vía`, `Ancho de la vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/32.png`,
          respuesta: `Carril exclusivo para peatones y ciclistas`,
          distractores: [`Circular con precaución sobre ese carril ya que es compartido con peatones y ciclistas`, `Circular utilizando luces de estacionamiento`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/33.png`,
          respuesta: `Que el carril segregado exclusivo para ciclistas y peatones se termina`,
          distractores: [`Que el carril segregado exclusivo para ciclistas y peatones se termina e inicia en la siguiente intersección`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `¿Qué debe hacer al observar la siguiente señal regulatoria?`,
          imagen: `/img/34.png`,
          respuesta: `Ceder el paso a los conductores, peatones y ciclistas`,
          distractores: [`Ceder el paso solo a los conductores`, `Ceder el paso solo a los peatones`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/35.png`,
          respuesta: `Más adelante intersección en “Y”`,
          distractores: [`Más adelante vía lateral`, `Más adelante cruce de vías`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/36.png`,
          respuesta: `Aproximación a redondel`,
          distractores: [`Más adelante vire en U`, `Más adelante zona de derrumbes`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/37.png`,
          respuesta: `Más adelante circulación en doble vía (bidireccional)`,
          distractores: [`Más adelante circulación en una vía`, `Más adelante vire a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/38.png`,
          respuesta: `Más adelante curva abierta hacia el lado que indica la flecha`,
          distractores: [`Más adelante curva cerrada hacia la derecha`, `Más adelante curva y contra curva`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/39.png`,
          respuesta: `Más adelante curva y contra curva cerrada a la derecha en la dirección señalada`,
          distractores: [`Más adelante curva pronunciada a la derecha`, `Más adelante curva abierta a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/40.png`,
          respuesta: `Más adelante curva y contra curva abierta a la izquierda en la dirección señalada`,
          distractores: [`Más adelante curva pronunciada a la izquierda`, `Más adelante curva abierta a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/41.png`,
          respuesta: `Más adelante vía sinuosa a la izquierda`,
          distractores: [`Más adelante curva y contra curva cerradas a la izquierda`, `Más adelante curva pronunciada a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/42.png`,
          respuesta: `Más adelante curva en "U" a la izquierda`,
          distractores: [`Más adelante curva en "U" a la izquierda`, `Más adelante curva en "U" a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/43.png`,
          respuesta: `Más adelante bandas transversales de alerta o de retumbo`,
          distractores: [`Más adelante agua en la vía`, `Más adelante hondonada`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/44.png`,
          respuesta: `Más adelante reductor de velocidad`,
          distractores: [`Más adelante calzada irregular`, `Más adelante hondonada`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/45.png`,
          respuesta: `Más adelante angostamiento en la vía`,
          distractores: [`Más adelante pendiente peligrosa`, `Más adelante pendiente peligrosa`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/46.png`,
          respuesta: `Disminuir la velocidad antes de ingresar a la curva`,
          distractores: [`Acelerar`, `Mantener la misma velocidad en la que se encuentra circulando`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/47.png`,
          respuesta: `Aproximación a una curva cerrada en la dirección señalada`,
          distractores: [`Aproximación a una curva abierta a la derecha`, `Aproximación a una contra curva a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/48.png`,
          respuesta: `Aproximación a una vía lateral izquierda o derecha en curva`,
          distractores: [`Aproximación a un cruce de vías al virar a la izquierda`, `Aproximación a una curva cerrada a la izquierda`],
        },
        {
          pregunta: `Esta señal se utiliza para indicar:`,
          imagen: `/img/49.png`,
          respuesta: `Dirección y lugar por el cual el flujo de tránsito debe dejar la calzada normal y desviarse por otras calles`,
          distractores: [`Que el desvío se ha terminado y que el flujo del tránsito regresa por el desvío a la ruta original `, `La ubicación y el lugar por el cual el flujo de tránsito debe continuar la calzada normal`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/50.png`,
          respuesta: `Señal con lugar de destino y flecha de asignación de carril`,
          distractores: [`Señal con lugar de destino y mensaje de acciones en palabras`, `Señal con código de ruta, lugar de destino y mensaje de salida con distancia en kilómetros`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/51.png`,
          respuesta: `Zona Escolar`,
          distractores: [`La velocidad máxima en una zona donde se encuentran centros educativos`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/52.png`,
          respuesta: `Ambas`,
          distractores: [`Reducir la velocidad y conducir con precaución`, `La velocidad máxima de circulación en una zona escolar`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/53.png`,
          respuesta: `El inicio del paradero de una zona escolar`,
          distractores: [`Parada de vehículos pesados`, `Zona permitida para familiares de estudiantes`],
        },
        {
          pregunta: `La luz amarilla intermitente en un semáforo significa:`,
          respuesta: `Disminuir la velocidad y atravesar la intersección con mucha precaución`,
          distractores: [`Parar siempre y cuando lo pueda hacer con seguridad`, `Parar, ceda el paso a todo el tráfico que cruza antes de atravesar la intersección`],
      
        },
        {
          pregunta: `¿Las instrucciones del agente de tránsito prevalecen sobre las señales de tránsito aunque el semáforo se 
          encuentre en luz roja?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿En cuál de las siguientes luces de semáforo debería siempre detener su vehículo?`,
          respuesta: `Luces rojas y luces rojas intermitentes `,
          distractores: [`Luces rojas continuas, flechas rojas y luces amarillas intermitentes `, `Luces rojas continuas, luces rojas intermitentes y luces amarillas`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/54.png`,
          respuesta: `Motocicletas deben mantenerse en el carril derecho`,
          distractores: [`Motocicletas deben girar a la derecha`, `Permitido estacionarse motocicletas`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/55.png`,
          respuesta: `Prohibido el ingreso o circulación de vehículos motorizados`,
          distractores: [`Prohibida la circulación de buses`, `Prohibida la circulación de vehículos pesados`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/56.png`,
          respuesta: `Más adelante intersección en “T”`,
          distractores: [`Más adelante redondel`, `Más adelante cruce de tren`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/57.png`,
          respuesta: `Más adelante parterre termina`,
          distractores: [`Más adelante angostamiento de vía`, `Más adelante parterre comienza`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/58.png`,
          respuesta: `Más adelante parterre comienza`,
          distractores: [`Más adelante parterre termina`, `Más adelante angostamiento de vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/59.png`,
          respuesta: `Más adelante cruce de ganado`,
          distractores: [`Más adelante cruce de peatones`, `Más adelante cruce de maquinaria agrícola`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/60.png`,
          respuesta: `Aproximación a túnel`,
          distractores: [`Túnel obstruido`, `Trabajos en el túnel`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/61.png`,
          respuesta: `Más adelante vía sinuosa a la derecha`,
          distractores: [`Más adelante curva pronunciada a la derecha`, `Más adelante curva y contra curva abiertas a la derecha`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/62.png`,
          respuesta: `Más adelante vía compartida con ciclistas`,
          distractores: [`Más adelante solo vehículos livianos`, `Más adelante solo vehículos livianos`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/63.png`,
          respuesta: `Más adelante depresión en la vía`,
          distractores: [`Más adelante vía cerrada`, `Más adelante reductor de velocidad`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/64.png`,
          respuesta: `Más adelante puente angosto`,
          distractores: [`Más adelante pendiente peligrosa`, `Más adelante estrechamiento en la vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/65.png`,
          respuesta: `Límite máximo de velocidad`,
          distractores: [`Velocidad mínima de Km/h`, `Usted se encuentra en el Kilómetro 30`],
        },
        {
          pregunta: `La luz roja intermitente en un semáforo significa:`,
          respuesta: `Parar siempre`,
          distractores: [`Disminuir la velocidad y atravesar la intersección con mucha precaución`, `Parar, ceda el paso a todo el tráfico que cruza antes de atravesar la intersección`],
      
        },
        {
          pregunta: `La luz roja intermitente en una señal de PARE en el extremo lateral izquierdo de un transporte escolar, 
          ¿Qué significa?`,
          respuesta: `Prohibido adelantar o rebasar al transporte escolar, hasta que se apague la luz intermitente y se 
          guarde la señal de PARE`,
          distractores: [`Disminuir la velocidad y adelantar con precaución al transporte escolar`, `Que se encendió las luces de estacionamiento del transporte escolar`],
      
        },
        {
          pregunta: `La licencia digital es válida para circular en el Ecuador:`,
          respuesta: `Si`,
          distractores: [`Solo si está impresa`, `Ninguna de las anteriores`],
      
        },
      
        {
          pregunta: `Es válido portar una copia simple de la licencia o matrícula para circular en el Ecuador:`,
          respuesta: `No, nunca`,
          distractores: [`Solo si está impresa`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `Debo hacer un curso de conducción para obtener mi licencia de conductor en el Ecuador:`,
          respuesta: `Sí, siempre`,
          distractores: [`Solo si me pide el agente de tránsito`, `No`],
      
        },
        {
          pregunta: `El no cancelar el valor de multas no le permitirá: renovar la licencia, realizar la revisión técnica vehicular y 
          matricular su vehículo`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿El conductor que NO haya sido sancionado durante el tiempo de vigencia de la licencia de conducir y 
          mantenga los 30 (puntos), se beneficiará del 30% en el pago del valor de la especie de la licencia de 
          conducir?`,
          respuesta: `FALSO`,
          distractores: [`VERDADERO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Si conduce su vehículo con un nivel de alcohol por litro de sangre de 0,3 a 0,8; ¿Qué sanción será aplicada?`,
          respuesta: `Cinco días de privación de libertad y retención del vehículo por veinticuatro horas`,
          distractores: [`Treinta días de privación de libertad y retención del vehículo por veinticuatro horas`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `El conductor que falte de obra a la autoridad o agente de tránsito, ¿Será sancionado con pena 
          privativa de libertad de tres días?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
      
        {
          pregunta: `Si se queda sin combustible y por esa razón obstaculiza el tránsito vehicular, ¿Está cometiendo una 
          contravención?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Es una contravención de tránsito, la o el conductor que conduzca con licencia caducada?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor y pasajeros que no hagan uso del cinturón de seguridad, ¿Corresponde a una 
          contravención de transito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor que invada con su vehículo circulando o estacionándose, en las vías asignadas para uso 
          exclusivo de los ciclistas, ¿Corresponde a una contravención de tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Los conductores que conduzcan en estado de embriaguez o bajo los efectos de sustancias estupefacientes, 
          psicotrópicas o preparados que las contengan y del cual provoquen un siniestro del cual resulte la muerte, 
          ¿Qué sanción corresponde?`,
          respuesta: `Revocatoria definitiva de la licencia para conducir`,
          distractores: [`Pena privativa de la libertad de doce a veinte años`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `El conducir un vehículo cuyas llantas se encuentren lisas o en mal estado, ¿Será sancionado con pena 
          privativa de libertad de cinco a quince días?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `El conducir un vehículo con las luces no encendidas en horas de la noche o conduzca en sitios oscuros 
          como túneles con la luces apagadas, ¿Corresponde a una contravención de tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Cuál de los siguientes enunciados corresponde a una contravención de tránsito?`,
          respuesta: `La o el conductor que conduce sin mantener una distancia prudente de seguimiento respecto al 
          vehículo y ciclista de adelante `,
          distractores: [`La o el conductor que conduce con una distancia prudente de seguimiento respecto al vehículo de 
          adelante`, `La o el conductor que cede el paso y permite el adelantamiento de otros vehículos.`],
      
        },
        {
          pregunta: `La o el conductor de un vehículo automotor que circule con personas en los estribos o pisaderas, baldes de 
          camionetas, parachoques o colgados de las carrocerías de los vehículos , ¿Es una contravención de 
          transito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor de un vehículo particular que tenga vidrios con películas antisolares oscuras, polarizados 
          o cualquier tipo de adhesivo y que no tenga la debida autorización, ¿Corresponde a una contravención de 
          tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        
        {
          pregunta: `¿Qué indican las líneas blancas que se observan en la imagen?`,
          imagen: `/img/66.png`,
          respuesta: `Líneas de borde`,
          distractores: [`Línea de prohibición de estacionamiento`, `Línea de prohibición de estacionamiento`],
      
        },
        {
          pregunta: `¿Qué indican las líneas blancas que se observan en la imagen?`,
          imagen: `/img/67.png`,
          respuesta: `Indican a los conductores, especialmente en caso de poca 
          visibilidad, dónde se encuentra el borde de la calzada`,
          distractores: [`Indican a los conductores que es permitido el parqueo vehículos`, `Indican a los conductores que es permitido el parqueo vehículos`],
      
        },
        {
          pregunta: `¿Qué significan las líneas segmentadas pintadas de blanco en una 
          intersección, que se observan en la imagen ?`,
          imagen: `/img/68.png`,
          respuesta: `Cruce de ciclovías`,
          distractores: [`Cruce peatonal`, `Cruce de mascotas`],
      
        },
      
        {
          pregunta: `De acuerdo a la imagen, ¿Las señales reglamentarias indican la 
          dirección obligatoria en el carril que deben seguir los vehículos en la 
          próxima intersección?`,
          imagen: `/img/69.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`,`DESCONOCE`],
      
        },
      
      
        {
          pregunta: `¿Qué debe hacer el conductor cuando en la vía observe un cruce 
          peatonal demarcado tipo cebra como indica la imagen?`,
          imagen: `/img/70.png`,
          respuesta: `Debe ceder el paso a los peatones que se encuentran cruzando la vía`,
          distractores: [`Debe pitar para alertar al peatón que no baje de la vereda`, `Debe acelerar para pasar más rápido`],
      
        },
      
        {
          pregunta: `¿Qué debe hacer el conductor cuando se encuentre con una señal 
          horizontal pintada en la vía como se observa en la imagen?`,
          imagen: `/img/71.png`,
          respuesta: `Velocidad máxima de 40 km/h`,
          distractores: [`Velocidad mínima de 40 km/h`, `Velocidad mínima de 40 km/h`],
      
        },
        {
          pregunta: `De acuerdo a la imagen , ¿Qué significa la siguiente señal horizontal 
          pintada en la vía?`,
          imagen: `/img/72.png`,
          respuesta: `Prohibido estacionar`,
          distractores: [`Estacionar temporalmente cuando no exista un agente de tránsito`, `Estacionar temporalmente cuando no exista un agente de tránsito`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señalización horizontal?`,
          imagen: `/img/73.png`,
          respuesta: `Estacionamiento tarifado`,
          distractores: [`No estacionar`, `Estacionamiento gratuito`],
      
        },
      
        {
          pregunta: `El conductor cuando se encuentre en una intersección, ¿Debe detener 
          su vehículo, bicicleta y peatones completamente y dar el paso a 
          cualquier vehículo que se encuentre cruzando la intersección?`,
          imagen: `/img/74.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
      
        {
          pregunta: `Los vehículos A y B llegan al mismo tiempo a una intersección en cruz 
          por diferentes lados ¿Cuál tiene derecho de vía?`,
          imagen: `/img/75.png`,
          respuesta: `El vehículo que se aproxima a la derecha`,
          distractores: [`Cualquiera de los dos`, `El vehículo que se aproxima a la izquierda`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/76.png`,
          respuesta: `Giro a izquierda`,
          distractores: [`Saludo`, `Disminuir la velocidad`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/77.png`,
          respuesta: `Giro a derecha`,
          distractores: [`Saludo`, `Parar`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/78.png`,
          respuesta: `Estacionarse o detenerse`,
          distractores: [`Solicita ayuda`, `Giro a izquierda`],
      
        },
      
        {
          pregunta: `¿Qué significa el espacio de la berma?`,
          imagen: `/img/79.png`,
          respuesta: `Espacio exclusivo para circulación de peatones, ciclistas y 
          movilidad personal`,
          distractores: [`Estacionamiento`, `Carril para circulación de motos`],
      
        },
        {
          pregunta: `¿Qué es la movilidad activa?`,
          respuesta: `Es aquella que depende del movimiento físico de las personas, incluye la caminata el uso de la 
          bicicleta y movilidad personal`,
          distractores: [`Es aquella que depende del movimiento motorizado, incluye el transporte público`, `Ninguna de las anteriores`],
      
        },
      
        {
          pregunta: `¿Cuáles son derechos de los biciusuarios? `,
          respuesta: `Todas las anteriores`,
          distractores: [`Derecho preferente de vía o circulación en los desvíos de avenidas y carreteras, cruce de caminos 
          intersecciones no señalizadas y ciclovías`, `Disponer vías de circulación privilegiada dentro de las ciudades y en carreteras, como ciclovías y 
          espacios similares`],
      
        },
        {
          pregunta: `De acuerdo a la normativa, ¿Dónde tienen derecho a circular los ciclistas?`,
          respuesta: `Transitar por todas las vías del país con respeto y seguridad.`,
          distractores: [`En las aceras, con cuidado respectivo a los peatones`, `Únicamente en las sendas especiales destinadas al uso de bicicletas`],
      
        },
        {
          pregunta: `¿En qué momento los conductores son responsables de su seguridad, de los pasajeros y el resto de 
          usuarios viales?`,
          respuesta: `En todo momento`,
          distractores: [`Los conductores no son responsables de lo que el pasajero haga o deje de hacer en cuanto a su 
          seguridad`, `Solo cuando esté encendido el motor del vehículo `],
        },
        {
          pregunta: `¿Qué se entiende por vía pública?`,
          respuesta: `Espacio público destinado al libre tránsito vehicular, bicicletas y peatonal`,
          distractores: [`El lugar por donde circulan los vehículos`, `El lugar por donde circulan los peatones`],
        },
        {
          pregunta: `¿Cuáles son las obligaciones de los peatones en la vía pública?`,
          respuesta: `Cruzar por las esquinas o cruces peatonales en las vías primarías y vías secundarias con más 
          de dos carriles efectivos de circulación; en vías secundarias que cuenten con un máximo de dos 
          carriles efectivos de circulación podrán cruzar en cualquier punto; y siempre y cuando le sea posible 
          hacerlo de manera segura`,
          distractores: [`Solicitar transporte o pedir ayuda a los automovilistas en lugares apropiados o prohibidos `, `Cruzar la calle por la parte anterior y posterior de los automotores que se hayan detenido momentáneamente`],
        },
        {
          pregunta: `¿Cuáles son derechos de los peatones?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Tener libre circulación sobre las aceras y zonas peatonales exclusivas, tener preferencia de vía sobre 
          todas las intersecciones, inclusive en las que tienen semáforo cuando la luz verde peatonal esté 
          encendida, todo el tiempo en los pasos cebra y en las esquinas no reguladas por semáforos, procurando 
          su propia seguridad y la de los demás`, `Disponer de las vías públicas libres de obstáculos y no invadidas, y contar con infraestructura y 
          señalización vial adecuadas que les brinden seguridad, en especial para las personas pertenecientes a los 
          grupos de atención prioritaria`],
        },
        {
          pregunta: `Uno de los objetivos de la Ley de Transporte Terrestre Tránsito y Seguridad Vial, es:`,
          respuesta: `El establecimiento de programas de capacitación y difusión para conductores, peatones, pasajeros y 
          autoridades, en materia de seguridad vial, para la creación de una cultura y conciencia vial 
          responsable y solidaria`,
          distractores: [`Definir sus principios, garantizar el derecho a la educación superior de calidad que propenda a la 
          excelencia`, `Contribuir al desarrollo del País a través de la formulación de políticas, regulaciones, planes, programas 
          y proyectos, que garanticen el buen vivir`],
        },
        {
          pregunta: `¿Cómo se sanciona a los responsables de un siniestro de tránsito que no sean conductores (pasajeros, 
            controladores, peatones, etc.)?`,
          respuesta: `Con las mismas sanciones que un conductor, exceptuando la pérdida de puntos`,
          distractores: [`Con las mismas penas que a un conductor responsable del siniestro`, `Con la mitad de las penas establecidas para un conductor responsable de un siniestro `],
        },
        {
          pregunta: `¿Qué implica conducir con prudencia a pesar de las acciones incorrectas de otros conductores y de las 
          condiciones adversas del clima y las vías, practicando normas de seguridad para no provocar un siniestro 
          de tránsito?`,
          respuesta: `Conducción con precaución`,
          distractores: [`Conducir con rapidez`, `Cumplir con las normas de tránsito`],
        },
        {
          pregunta: `¿Qué implica la prevención de siniestros?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Evitar las conductas riesgosas : exceso de velocidad, consumo de alcohol, no uso de cinturón, casco o 
          sistemas de retención infantil, no respetar las señales de tránsito, manejo distraído`, `Desarrollar un sistema seguro de movilidad, lo cual implica infraestructura para la seguridad vial, control 
          y capacitación de los actores viales`],
        },
        {
          pregunta: `¿Cuáles son los objetivos de la educación vial?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Reducir de forma sistemática los siniestros de tránsito`, `Formar y capacitar a las personas en general para el uso correcto de todos los medios de transporte
          terrestre`],
        },
        {
          pregunta: `Los niños menores de 12 años podrán viajar en:`,
          respuesta: `En los asientos posteriores utilizando los sistemas de retención infantil de acuerdo a la edad y altura`,
          distractores: [`En el asiento delantero con cinturón de seguridad`, `En el asiento delantero sin cinturón de seguridad`],
        },
        {
          pregunta: `¿Para qué sirven los seguros a prueba de niños en los vehículos?`,
          respuesta: `Impiden que las puertas puedan abrirse desde el interior`,
          distractores: [`Bloquean las ventanas traseras manteniéndolas cerradas`, `Aseguran las hebillas de los cinturones de seguridad`],
        },
        {
          pregunta: `El dispositivo de seguridad pasiva que reduce el desplazamiento de la cabeza hacia atrás para evitar el 
          efecto latigazo cervical, se conoce como:`,
          respuesta: `Apoya cabeza`,
          distractores: [`Cinturón de seguridad`, `Airbag`],
        },
        {
          pregunta: `¿Cómo se conoce al dispositivo de seguridad pasiva incluida en algunos vehículos el cual se acciona en 
          milisegundos al producirse un impacto?`,
          respuesta: `Airbag o bolsa de aire`,
          distractores: [`Apoya cabeza`, `Cinturón de seguridad`],
        },
        {
          pregunta: `¿Cuál es la distancia mínima que debe mantener entre vehículo y ciclista al conducir?`,
          respuesta: `1,5 metros`,
          distractores: [`1,0 metros`, `Todas las anteriores`],
        },
        {
          pregunta: `¿Cuándo debo llevar el kit de emergencia o carretero en mi vehículo?`,
          respuesta: `Siempre`,
          distractores: [`Solo Triángulos`, `Solo en caso de emergencia`],
        },
        {
          pregunta: `En un paso cebra no regulado, ¿Quién tiene la preferencia de paso?`,
          respuesta: `Los Peatones`,
          distractores: [`Los vehículos en circulación`, `El transporte público`],
        },
        {
          pregunta: `En caso de avería mecánica en la vía, ¿Debo poner los triángulos o conos de seguridad?`,
          respuesta: `Si, siempre`,
          distractores: [`No es necesario`, `Solo en caso de haber agentes de tránsito`],
        },
        {
        pregunta: `¿A qué distancia mínima antes de una maniobra es obligatorio poner la respectiva luz intermitente?`,
        respuesta: `Mínimo 30 metros antes`,
        distractores: [`Cuando ejecuto la maniobra`, `Mínimo 10 metros antes`],
         },
        {
          pregunta: `En un siniestro de tránsito las autoridades encargadas del control del tránsito, ¿Pueden detener a las 
          personas involucradas?`,
          respuesta: `Pueden detenerlos y ponerlos a las órdenes del fiscal, en el caso donde resultaron personas lesionadas 
          o fallecidas`,
          distractores: [`No pueden detener a nadie hasta que tengan una orden 
          judicial`, `Solamente pueden detener a los vehículos`],
        },
        {
          pregunta: `Al momento de rebasar a un ciclista en circulación, ¿Qué distancia mínima debe tomar el vehículo?`,
          respuesta: `1,5 metros`,
          distractores: [`1 metro`, `50 centímetros`],
        },
        {
          pregunta: `¿Cuál es la distancia mínima de seguimiento que debe mantener entre vehículo y ciclista al conducir?`,
          respuesta: `3 metros`,
          distractores: [`1 metro`, `2 metros`],
        },
        {
          pregunta: `¿Qué debe realizar un conductor cuando ve que un bus escolar e institucional se detiene en una parada?`,
          respuesta: `Esperar y dar el paso a todos los peatones que se bajan del bus`,
          distractores: [`Acelerar la marcha para pasar al bus`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `En las carreteras, ¿Los conductores cuando circulan deben dar preferencia?`,
          respuesta: `Peatones, ciclistas, motociclistas y similares.`,
          distractores: [`Otros conductores`, `Otros conductores`],
        },
        {
          pregunta: `¿Qué se presume si un conductor se niega a realizarse pruebas para la detección de alcohol o de la 
          presencia de sustancias estupefacientes?`,
          respuesta: `Será considerada como presunción de estar en el máximo grado de intoxicación`,
          distractores: [`Solo en el día`, `No`],
        },
        {
          pregunta: `Cuando se conduzca un vehículo a motor y se requiera cruzar una acera para entrar o salir de un 
          estacionamiento, ¿quién tiene preferencia de paso?`,
          respuesta: `Peatones, ciclistas y vehículos`,
          distractores: [`El conductor que está saliendo`, `Solo los vehículos`],
        },
        {
          pregunta: `¿A qué velocidad se estima que un atropellamiento de un niño/a sería mortal?`,
          respuesta: `25km/h`,
          distractores: [`15km/h`, `10km/h`],
        },
        {
          pregunta: `¿A qué velocidad se estima que el atropellamiento a un adulto sería mortal?`,
          respuesta: `60km/h`,
          distractores: [`40km/h`, `20km/h`],
        },
        {
          pregunta: `En caso de que no exista señalización horizontal y/o vertical, ¿Quién tendría derecho de paso en la vía en
          caso de que los usuarios presentes incluyan peatones, ciclistas, vehículos livianos, carga pesada?`,
          respuesta: `Peatones`,
          distractores: [`Biciusuarios`, `Transporte público de pasajeros`],
        },
        {
          pregunta: `Si un peatón va a cruzar una intersección con luz verde del semáforo vehicular o una intersección no 
          regulada y un vehículo va girar, ¿Quién tiene la preferencia de paso?`,
          respuesta: `El peatón `,
          distractores: [`El auto`, `Los dos`],
        },
        {
          pregunta: `¿Qué son las zonas 30?`,
          respuesta: `Zona en donde la velocidad máxima es 30 km/h`,
          distractores: [`Es una zona peatonal`, `Zona en donde la velocidad mínima es 30km/h`],
        },
        {
          pregunta: `¿Por qué la movilidad activa es importante?`,
          respuesta: `Genera menor impacto ambiental, social y económico`,
          distractores: [`Ninguna de las anteriores`, `Está de moda`],
        },
        {
          pregunta: `¿Un pasajero de transporte público puede denunciar ante la autoridad de tránsito, cuando el conductor 
          esté en estado de ebriedad?`,
          respuesta: `Es su obligación`,
          distractores: [`Talvez`, `No`],
        },
        {
          pregunta: `¿En qué porcentaje disminuyen las probabilidades de muerte para un motociclista o ciclista durante un 
          siniestro si utiliza casco homologado?`,
          respuesta: `39%`,
          distractores: [`No disminuyen, aumentan`, `1%`],
        },
        
      
      
      //*PREGUNTAS ESPECIFICAS AQUI*//
      
    
        {
          pregunta: `¿A quién se otorga la licencia tipo F?`,
          respuesta: `A las personas mayores de edad con discapacidad`,
          distractores: [`A las personas mayores de edad que conducen vehículos comerciales`, `A las personas de 16 años que no cumplen la mayoría de edad`],
      
        },
      
        {
          pregunta: `¿Cuál es la manera más segura para llevar a un niño en el vehículo?`,
          respuesta: `En el asiento trasero, en una silla de seguridad`,
          distractores: [`En la falda de un adulto que va con su cinturón de seguridad`, `En cualquier asiento, siempre que lleve puesto el cinturón de seguridad para adultos`],
      
        },
        {
          pregunta: `En una vía urbana, ¿cuál es el límite máximo de velocidad en el que debe circular un
          vehículo considerado liviano?`,
          respuesta: `50 Km/h`,
          distractores: [`40 Km/h`, `60 Km/h`],
      
        },
        {
          pregunta: `¿Cuál es la razón principal por la que la distancia mínima entre el conductor y el volante
          debe ser de 25 cm?`,
          respuesta: `Porque es la distancia de acción del airbag al explotar`,
          distractores: [`Ninguna de las anteriores`, `Porque al sentarse a mayor distancia no tendría suficiente visibilidad`],
      
        },
      
        {
          pregunta: `En una vía perimetral, ¿cuál es el límite máximo de velocidad en el que debe circular un
          vehículo considerado liviano?`,
          respuesta: `90 Km/h`,
          distractores: [`95 Km/h`, `80 Km/h`],
      
        },
        {
          pregunta: `¿Para quién es obligatorio el uso del cinturón de seguridad?`,
          respuesta: `Para todos los ocupantes del vehículo`,
          distractores: [`Solamente para el conductor`, `Solamente para los pasajeros`],
      
        },
      
        {
          pregunta: `En vías rectas, ¿cuál es el límite máximo de velocidad en el que debe circular un vehículo
          considerado liviano?`,
          respuesta: `90 Km/h`,
          distractores: [`80 Km/h`, `100 Km/h`],
      
        },
      
        {
          pregunta: `El freno de mano actúa sobre las ruedas:`,
          respuesta: `Posteriores`,
          distractores: [`Delanteras`, `Todas las anteriores`],
      
        },
      
        {
          pregunta: `¿Qué tiempo de vigencia tienen las licencias no profesional tipo B?`,
          respuesta: `5 años`,
          distractores: [`6 años`, `4 años`],
      
        },
      
        {
          pregunta: `¿Cuándo una Licencia de conducir puede ser anulada?`,
          respuesta: `Cuando se detecte que éstas han sido otorgadas mediante un acto viciado por defectos
          de forma o falta de requisitos de fondo.`,
          distractores: [`Cuando los exámenes correspondientes determinen la incapacidad física mental y psicológica de
          su titular.`, `Cuando esté caducada.`],
      
        },
        {
          pregunta: `¿Qué tipo de vehículos puede conducir una persona que posee licencia tipo B?`,
          respuesta: `Automóviles y camionetas con acoplados de hasta 1,75 toneladas de carga útil o casas
          rodantes`,
          distractores: [`Automóviles y camionetas con acoplados de hasta 1,55 toneladas de carga útil o casas rodantes A`, `Automóviles y camionetas con acoplados de hasta 1,65 toneladas de carga útil o casas rodantes`],
      
        },
        {
          pregunta: `El ajuste del asiento debe:`,
          respuesta: `Facilitar el acceso a los mandos y disponer de buena visibilidad`,
          distractores: [`Ser lo más alto posible para ver mejor hacia delante`, `Estar lo más adelante posible para poder acelerar mejor`],
      
        },
        
      ],
      C: [
        {
          pregunta: `El no acatar lo indicado en una señal de tránsito, ¿Es una infracción?`,
          respuesta: `VERDADERO`,
          distractores: [`DESCONOCE`, `FALSO`],
      
        },
        {
          pregunta: `En un semáforo con giro a la derecha o izquierda, si el conductor tiene el semáforo en verde, al igual que los peatones, ¿Quién tiene la preferencia?`,
          imagen: `/img/1.png`,
          respuesta: `Los peatones`,
          distractores: [`Los vehículos`, `Ambos`],
      
        },
        {
          pregunta: `Cuándo un cruce de vía está regulado por un agente de tránsito, ¿Las señales manuales de éste prevalecen sobre cualquier otra existente, incluso semáforos?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Una línea continua pintada en la calzada de color blanco o amarillo restringe la circulación vehicular de tal manera que ningún vehículo podrá cruzar como se observa en la imagen?`,
          imagen: `/img/2.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Una línea discontinua de color amarillo o blanco pintada en la
          calzada, de acuerdo a la imagen ¿Que indica?`,
          imagen: `/img/3.png`,
          respuesta: `Permite rebasar o adelantar sobre esta línea, siempre que exista 
          seguridad para hacerlo`,
          distractores: [`El conductor no puede realizar maniobras de adelantamiento 
          cuando la línea es discontinua`, `Restringe la circulación vehicular de tal manera que ningún 
          vehículo podrá cruzar esta línea`],
      
        },
        {
          pregunta: `Cuando vea pintado en la calzada una línea mixta, con la línea 
          continua a la izquierda del conductor, de acuerdo a la imagen ¿Qué 
          indica esta línea?`,
          imagen: `/img/4.png`,
          respuesta: `El conductor no puede realizar maniobras de adelantamiento 
    cuando tiene la línea continua a su izquierda`,
          distractores: [`El conductor no puede realizar maniobras de adelantamiento 
    cuando tiene la línea continua a su izquierda
    `, `Puede realizar maniobras de adelantamiento cuando tiene la línea 
    discontinua a la izquierda del conductor`],
      
        },
        {
          pregunta: `Cuando ve pintado de color blanco o amarillo en la calzada doble línea 
          continua ¿Qué indica?`,
          imagen: `/img/5.png`,
          respuesta: `Están totalmente prohibidas las maniobras para rebasar o 
          adelantar por cualquiera de los carriles que se transite para evitar 
          choques`,
          distractores: [`Puede realizar maniobras de adelantamiento cuando tiene la línea 
    discontinua a la izquierda del conductor`, `Le permite al conductor rebasar o adelantar, siempre que exista la 
          seguridad de hacerlo`],
      
        },
        {
          pregunta: `De acuerdo a la imagen, ¿Qué debe hacer si compararte carril con un 
          ciclista?`,
          imagen: `/img/6.png`,
          respuesta: `Ambas`,
          distractores: [`Da prioridad a ciclista en el carril compartido`, `Reduce a velocidad y comparte la vía con el ciclista`],
      
        },
        {
          pregunta: `¿Esta señal se utiliza para indicar?`,
          imagen: `/img/7.png`,
          respuesta: `Obligación de los conductores circular solo en la dirección 
          indicada
          `,
          distractores: [`Un solo carril`, `Doble vía`],
      
        },
        {
          pregunta: `De acuerdo a la imagen esta señal indica, No exceder de 30 km/h`,
          imagen: `/img/8.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `De acuerdo a la imagen, ¿esta señal indica prohibido el cambio de 
          carril?`,
          imagen: `/img/9.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Qué debe hacer cuando usted observa esta señal?`,
          imagen: `/img/10.png`,
          respuesta: `Detenerse antes de la línea de pare y ceder el paso a todo tránsito`,
          distractores: [`Detenerse antes de la línea de pare`, `Reducir la velocidad y estar preparado para detenerse solamente si 
    hay peligro de colisión con otro vehículo o peatón`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/11.png`,
          respuesta: `Viraje prohibido al sentido de la flecha`,
          distractores: [`Viraje permitido en el sentido de la flecha`, `Viraje seguro`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/12.png`,
          respuesta: `No girar en "U"`,
          distractores: [`No virar a la izquierda`, `Tener cuidado con el tráfico que vira`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/13.png`,
          respuesta: `Tránsito pesado debe circular por la derecha`,
          distractores: [`Estacionamiento permitido para camiones`, `Circulación solo tránsito pesado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/14.png`,
          respuesta: `Prohibido circular vehículos pesados`,
          distractores: [`Prohibido circular vehículos pesados`, `Solo tránsito pesado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/15.png`,
          respuesta: `Prohibido adelantar o rebasar`,
          distractores: [`Solo tránsito liviano`, `Prohibido doble columna`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/16.png`,
          respuesta: `Vehículos livianos deben mantener su derecha`,
          distractores: [`Vehículos livianos deben girar a la derecha`, `Permitido estacionarse vehículos livianos`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/17.png`,
          respuesta: `Comienza doble vía, pueden venir vehículos en sentido contrario. 
          Precaución`,
          distractores: [`Cambio de carril`, `No entre`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/18.png`,
          respuesta: `Prohibido estacionarse o detenerse`,
          distractores: [`Prohibido estacionarse`, `Permite detenerse por un minuto solamente`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/19.png`,
          respuesta: `No entre`,
          distractores: [`Ninguna de las anteriores`, `Ingreso permitido`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/20.png`,
          respuesta: `Prohibido virar en U o a la izquierda`,
          distractores: [`Prohibido virar en U o a la derecha`, `Prohibido virar a la derecha o a la izquierda`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/21.png`,
          respuesta: `Prohibido cambio de carril a la izquierda`,
          distractores: [`Prohibido virar en U o a la izquierda`, `Prohibido tomar la vía sinuosa`],
      
        },
        {
          pregunta: `¿Cuáles son las consecuencias de incumplimiento de esta señal 
          (prohibido estacionarse)?`,
          imagen: `/img/22.png`,
          respuesta: `Sanción con el 10% del salario básico unificado`,
          distractores: [`Pena privativa de libertad`, `Retiro de la licencia de conducir.`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/23.png`,
          respuesta: `Prohibido estacionar al lado izquierdo de la señal hasta la próxima 
          intersección`,
          distractores: [`Permitido estacionar a la izquierda hasta la próxima intersección`, `Prohibido estacionar a la derecha hasta la próxima intersección`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/24.png`,
          respuesta: `Prohibido estacionar al lado derecho de la señal hasta la próxima intersección`,
          distractores: [`Prohibición de estacionar a la izquierda hasta la próxima intersección`, `Estacionar a la derecha hasta la próxima intersección`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/25.png`,
          respuesta: `Prohibido estacionar a los dos lados de la señal hasta la próxima intersección a izquierda y derecha`,
          distractores: [`Estacionar a la derecha hasta la próxima intersección`, `Prohibición de estacionar a la izquierda`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/26.png`,
          respuesta: `Estacionamiento permitido`,
          distractores: [`Estacionamiento tarifado`, `Prohibición de estacionar`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/27.png`,
          respuesta: `Estacionamiento tarifado durante los días y horarios señalados`,
          distractores: [`Estacionamiento tarifado a excepción de los días y horarios definidos`, `Estacionamiento no tarifado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/28.png`,
          respuesta: `Estacionamiento reservado solo para personas con discapacidad`,
          distractores: [`Estacionamiento prohibido para personas con discapacidad`, `Estacionamiento reservado para vehículos grandes`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/29.png`,
          respuesta: `Estacionamiento en reservado solo para personas con discapacidad a los lados de la señal`,
          distractores: [`Estacionamiento en paralelo reservado para vehículos grandes`, `Estacionamiento en paralelo prohibido para personas con discapacidad`],
      
        },
        {
          pregunta: `¿Qué debe hacer el conductor de un vehículo particular frente a esta señal?`,
          imagen: `/img/30.png`,
          respuesta: `Abstenerse de estacionar o detener el vehículo en esta zona`,
          distractores: [`Detener o estacionar el vehículo en esta zona`, `Aumentar la velocidad o rebasar al bus`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/31.png`,
          respuesta: `Largo máximo de los vehículos que circulan por esa vía`,
          distractores: [`Ancho máximo de los vehículos que circulan por esa vía`, `Ancho de la vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/32.png`,
          respuesta: `Carril exclusivo para peatones y ciclistas`,
          distractores: [`Circular con precaución sobre ese carril ya que es compartido con peatones y ciclistas`, `Circular utilizando luces de estacionamiento`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/33.png`,
          respuesta: `Que el carril segregado exclusivo para ciclistas y peatones se termina`,
          distractores: [`Que el carril segregado exclusivo para ciclistas y peatones se termina e inicia en la siguiente intersección`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `¿Qué debe hacer al observar la siguiente señal regulatoria?`,
          imagen: `/img/34.png`,
          respuesta: `Ceder el paso a los conductores, peatones y ciclistas`,
          distractores: [`Ceder el paso solo a los conductores`, `Ceder el paso solo a los peatones`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/35.png`,
          respuesta: `Más adelante intersección en “Y”`,
          distractores: [`Más adelante vía lateral`, `Más adelante cruce de vías`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/36.png`,
          respuesta: `Aproximación a redondel`,
          distractores: [`Más adelante vire en U`, `Más adelante zona de derrumbes`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/37.png`,
          respuesta: `Más adelante circulación en doble vía (bidireccional)`,
          distractores: [`Más adelante circulación en una vía`, `Más adelante vire a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/38.png`,
          respuesta: `Más adelante curva abierta hacia el lado que indica la flecha`,
          distractores: [`Más adelante curva cerrada hacia la derecha`, `Más adelante curva y contra curva`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/39.png`,
          respuesta: `Más adelante curva y contra curva cerrada a la derecha en la dirección señalada`,
          distractores: [`Más adelante curva pronunciada a la derecha`, `Más adelante curva abierta a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/40.png`,
          respuesta: `Más adelante curva y contra curva abierta a la izquierda en la dirección señalada`,
          distractores: [`Más adelante curva pronunciada a la izquierda`, `Más adelante curva abierta a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/41.png`,
          respuesta: `Más adelante vía sinuosa a la izquierda`,
          distractores: [`Más adelante curva y contra curva cerradas a la izquierda`, `Más adelante curva pronunciada a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/42.png`,
          respuesta: `Más adelante curva en "U" a la izquierda`,
          distractores: [`Más adelante curva en "U" a la izquierda`, `Más adelante curva en "U" a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/43.png`,
          respuesta: `Más adelante bandas transversales de alerta o de retumbo`,
          distractores: [`Más adelante agua en la vía`, `Más adelante hondonada`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/44.png`,
          respuesta: `Más adelante reductor de velocidad`,
          distractores: [`Más adelante calzada irregular`, `Más adelante hondonada`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/45.png`,
          respuesta: `Más adelante angostamiento en la vía`,
          distractores: [`Más adelante pendiente peligrosa`, `Más adelante pendiente peligrosa`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/46.png`,
          respuesta: `Disminuir la velocidad antes de ingresar a la curva`,
          distractores: [`Acelerar`, `Mantener la misma velocidad en la que se encuentra circulando`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/47.png`,
          respuesta: `Aproximación a una curva cerrada en la dirección señalada`,
          distractores: [`Aproximación a una curva abierta a la derecha`, `Aproximación a una contra curva a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/48.png`,
          respuesta: `Aproximación a una vía lateral izquierda o derecha en curva`,
          distractores: [`Aproximación a un cruce de vías al virar a la izquierda`, `Aproximación a una curva cerrada a la izquierda`],
        },
        {
          pregunta: `Esta señal se utiliza para indicar:`,
          imagen: `/img/49.png`,
          respuesta: `Dirección y lugar por el cual el flujo de tránsito debe dejar la calzada normal y desviarse por otras calles`,
          distractores: [`Que el desvío se ha terminado y que el flujo del tránsito regresa por el desvío a la ruta original `, `La ubicación y el lugar por el cual el flujo de tránsito debe continuar la calzada normal`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/50.png`,
          respuesta: `Señal con lugar de destino y flecha de asignación de carril`,
          distractores: [`Señal con lugar de destino y mensaje de acciones en palabras`, `Señal con código de ruta, lugar de destino y mensaje de salida con distancia en kilómetros`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/51.png`,
          respuesta: `Zona Escolar`,
          distractores: [`La velocidad máxima en una zona donde se encuentran centros educativos`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/52.png`,
          respuesta: `Ambas`,
          distractores: [`Reducir la velocidad y conducir con precaución`, `La velocidad máxima de circulación en una zona escolar`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/53.png`,
          respuesta: `El inicio del paradero de una zona escolar`,
          distractores: [`Parada de vehículos pesados`, `Zona permitida para familiares de estudiantes`],
        },
        {
          pregunta: `La luz amarilla intermitente en un semáforo significa:`,
          respuesta: `Disminuir la velocidad y atravesar la intersección con mucha precaución`,
          distractores: [`Parar siempre y cuando lo pueda hacer con seguridad`, `Parar, ceda el paso a todo el tráfico que cruza antes de atravesar la intersección`],
      
        },
        {
          pregunta: `¿Las instrucciones del agente de tránsito prevalecen sobre las señales de tránsito aunque el semáforo se 
          encuentre en luz roja?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿En cuál de las siguientes luces de semáforo debería siempre detener su vehículo?`,
          respuesta: `Luces rojas y luces rojas intermitentes `,
          distractores: [`Luces rojas continuas, flechas rojas y luces amarillas intermitentes `, `Luces rojas continuas, luces rojas intermitentes y luces amarillas`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/54.png`,
          respuesta: `Motocicletas deben mantenerse en el carril derecho`,
          distractores: [`Motocicletas deben girar a la derecha`, `Permitido estacionarse motocicletas`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/55.png`,
          respuesta: `Prohibido el ingreso o circulación de vehículos motorizados`,
          distractores: [`Prohibida la circulación de buses`, `Prohibida la circulación de vehículos pesados`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/56.png`,
          respuesta: `Más adelante intersección en “T”`,
          distractores: [`Más adelante redondel`, `Más adelante cruce de tren`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/57.png`,
          respuesta: `Más adelante parterre termina`,
          distractores: [`Más adelante angostamiento de vía`, `Más adelante parterre comienza`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/58.png`,
          respuesta: `Más adelante parterre comienza`,
          distractores: [`Más adelante parterre termina`, `Más adelante angostamiento de vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/59.png`,
          respuesta: `Más adelante cruce de ganado`,
          distractores: [`Más adelante cruce de peatones`, `Más adelante cruce de maquinaria agrícola`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/60.png`,
          respuesta: `Aproximación a túnel`,
          distractores: [`Túnel obstruido`, `Trabajos en el túnel`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/61.png`,
          respuesta: `Más adelante vía sinuosa a la derecha`,
          distractores: [`Más adelante curva pronunciada a la derecha`, `Más adelante curva y contra curva abiertas a la derecha`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/62.png`,
          respuesta: `Más adelante vía compartida con ciclistas`,
          distractores: [`Más adelante solo vehículos livianos`, `Más adelante solo vehículos livianos`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/63.png`,
          respuesta: `Más adelante depresión en la vía`,
          distractores: [`Más adelante vía cerrada`, `Más adelante reductor de velocidad`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/64.png`,
          respuesta: `Más adelante puente angosto`,
          distractores: [`Más adelante pendiente peligrosa`, `Más adelante estrechamiento en la vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/65.png`,
          respuesta: `Límite máximo de velocidad`,
          distractores: [`Velocidad mínima de Km/h`, `Usted se encuentra en el Kilómetro 30`],
        },
        {
          pregunta: `La luz roja intermitente en un semáforo significa:`,
          respuesta: `Parar siempre`,
          distractores: [`Disminuir la velocidad y atravesar la intersección con mucha precaución`, `Parar, ceda el paso a todo el tráfico que cruza antes de atravesar la intersección`],
      
        },
        {
          pregunta: `La luz roja intermitente en una señal de PARE en el extremo lateral izquierdo de un transporte escolar, 
          ¿Qué significa?`,
          respuesta: `Prohibido adelantar o rebasar al transporte escolar, hasta que se apague la luz intermitente y se 
          guarde la señal de PARE`,
          distractores: [`Disminuir la velocidad y adelantar con precaución al transporte escolar`, `Que se encendió las luces de estacionamiento del transporte escolar`],
      
        },
        {
          pregunta: `La licencia digital es válida para circular en el Ecuador:`,
          respuesta: `Si`,
          distractores: [`Solo si está impresa`, `Ninguna de las anteriores`],
      
        },
      
        {
          pregunta: `Es válido portar una copia simple de la licencia o matrícula para circular en el Ecuador:`,
          respuesta: `No, nunca`,
          distractores: [`Solo si está impresa`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `Debo hacer un curso de conducción para obtener mi licencia de conductor en el Ecuador:`,
          respuesta: `Sí, siempre`,
          distractores: [`Solo si me pide el agente de tránsito`, `No`],
      
        },
        {
          pregunta: `El no cancelar el valor de multas no le permitirá: renovar la licencia, realizar la revisión técnica vehicular y 
          matricular su vehículo`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿El conductor que NO haya sido sancionado durante el tiempo de vigencia de la licencia de conducir y 
          mantenga los 30 (puntos), se beneficiará del 30% en el pago del valor de la especie de la licencia de 
          conducir?`,
          respuesta: `FALSO`,
          distractores: [`VERDADERO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Si conduce su vehículo con un nivel de alcohol por litro de sangre de 0,3 a 0,8; ¿Qué sanción será aplicada?`,
          respuesta: `Cinco días de privación de libertad y retención del vehículo por veinticuatro horas`,
          distractores: [`Treinta días de privación de libertad y retención del vehículo por veinticuatro horas`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `El conductor que falte de obra a la autoridad o agente de tránsito, ¿Será sancionado con pena 
          privativa de libertad de tres días?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
      
        {
          pregunta: `Si se queda sin combustible y por esa razón obstaculiza el tránsito vehicular, ¿Está cometiendo una 
          contravención?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Es una contravención de tránsito, la o el conductor que conduzca con licencia caducada?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor y pasajeros que no hagan uso del cinturón de seguridad, ¿Corresponde a una 
          contravención de transito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor que invada con su vehículo circulando o estacionándose, en las vías asignadas para uso 
          exclusivo de los ciclistas, ¿Corresponde a una contravención de tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Los conductores que conduzcan en estado de embriaguez o bajo los efectos de sustancias estupefacientes, 
          psicotrópicas o preparados que las contengan y del cual provoquen un siniestro del cual resulte la muerte, 
          ¿Qué sanción corresponde?`,
          respuesta: `Revocatoria definitiva de la licencia para conducir`,
          distractores: [`Pena privativa de la libertad de doce a veinte años`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `El conducir un vehículo cuyas llantas se encuentren lisas o en mal estado, ¿Será sancionado con pena 
          privativa de libertad de cinco a quince días?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `El conducir un vehículo con las luces no encendidas en horas de la noche o conduzca en sitios oscuros 
          como túneles con la luces apagadas, ¿Corresponde a una contravención de tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Cuál de los siguientes enunciados corresponde a una contravención de tránsito?`,
          respuesta: `La o el conductor que conduce sin mantener una distancia prudente de seguimiento respecto al 
          vehículo y ciclista de adelante `,
          distractores: [`La o el conductor que conduce con una distancia prudente de seguimiento respecto al vehículo de 
          adelante`, `La o el conductor que cede el paso y permite el adelantamiento de otros vehículos.`],
      
        },
        {
          pregunta: `La o el conductor de un vehículo automotor que circule con personas en los estribos o pisaderas, baldes de 
          camionetas, parachoques o colgados de las carrocerías de los vehículos , ¿Es una contravención de 
          transito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor de un vehículo particular que tenga vidrios con películas antisolares oscuras, polarizados 
          o cualquier tipo de adhesivo y que no tenga la debida autorización, ¿Corresponde a una contravención de 
          tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        
        {
          pregunta: `¿Qué indican las líneas blancas que se observan en la imagen?`,
          imagen: `/img/66.png`,
          respuesta: `Líneas de borde`,
          distractores: [`Línea de prohibición de estacionamiento`, `Línea de prohibición de estacionamiento`],
      
        },
        {
          pregunta: `¿Qué indican las líneas blancas que se observan en la imagen?`,
          imagen: `/img/67.png`,
          respuesta: `Indican a los conductores, especialmente en caso de poca 
          visibilidad, dónde se encuentra el borde de la calzada`,
          distractores: [`Indican a los conductores que es permitido el parqueo vehículos`, `Indican a los conductores que es permitido el parqueo vehículos`],
      
        },
        {
          pregunta: `¿Qué significan las líneas segmentadas pintadas de blanco en una 
          intersección, que se observan en la imagen ?`,
          imagen: `/img/68.png`,
          respuesta: `Cruce de ciclovías`,
          distractores: [`Cruce peatonal`, `Cruce de mascotas`],
      
        },
      
        {
          pregunta: `De acuerdo a la imagen, ¿Las señales reglamentarias indican la 
          dirección obligatoria en el carril que deben seguir los vehículos en la 
          próxima intersección?`,
          imagen: `/img/69.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`,`DESCONOCE`],
      
        },
      
      
        {
          pregunta: `¿Qué debe hacer el conductor cuando en la vía observe un cruce 
          peatonal demarcado tipo cebra como indica la imagen?`,
          imagen: `/img/70.png`,
          respuesta: `Debe ceder el paso a los peatones que se encuentran cruzando la vía`,
          distractores: [`Debe pitar para alertar al peatón que no baje de la vereda`, `Debe acelerar para pasar más rápido`],
      
        },
      
        {
          pregunta: `¿Qué debe hacer el conductor cuando se encuentre con una señal 
          horizontal pintada en la vía como se observa en la imagen?`,
          imagen: `/img/71.png`,
          respuesta: `Velocidad máxima de 40 km/h`,
          distractores: [`Velocidad mínima de 40 km/h`, `Velocidad mínima de 40 km/h`],
      
        },
        {
          pregunta: `De acuerdo a la imagen , ¿Qué significa la siguiente señal horizontal 
          pintada en la vía?`,
          imagen: `/img/72.png`,
          respuesta: `Prohibido estacionar`,
          distractores: [`Estacionar temporalmente cuando no exista un agente de tránsito`, `Estacionar temporalmente cuando no exista un agente de tránsito`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señalización horizontal?`,
          imagen: `/img/73.png`,
          respuesta: `Estacionamiento tarifado`,
          distractores: [`No estacionar`, `Estacionamiento gratuito`],
      
        },
      
        {
          pregunta: `El conductor cuando se encuentre en una intersección, ¿Debe detener 
          su vehículo, bicicleta y peatones completamente y dar el paso a 
          cualquier vehículo que se encuentre cruzando la intersección?`,
          imagen: `/img/74.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
      
        {
          pregunta: `Los vehículos A y B llegan al mismo tiempo a una intersección en cruz 
          por diferentes lados ¿Cuál tiene derecho de vía?`,
          imagen: `/img/75.png`,
          respuesta: `El vehículo que se aproxima a la derecha`,
          distractores: [`Cualquiera de los dos`, `El vehículo que se aproxima a la izquierda`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/76.png`,
          respuesta: `Giro a izquierda`,
          distractores: [`Saludo`, `Disminuir la velocidad`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/77.png`,
          respuesta: `Giro a derecha`,
          distractores: [`Saludo`, `Parar`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/78.png`,
          respuesta: `Estacionarse o detenerse`,
          distractores: [`Solicita ayuda`, `Giro a izquierda`],
      
        },
      
        {
          pregunta: `¿Qué significa el espacio de la berma?`,
          imagen: `/img/79.png`,
          respuesta: `Espacio exclusivo para circulación de peatones, ciclistas y 
          movilidad personal`,
          distractores: [`Estacionamiento`, `Carril para circulación de motos`],
      
        },
        {
          pregunta: `¿Qué es la movilidad activa?`,
          respuesta: `Es aquella que depende del movimiento físico de las personas, incluye la caminata el uso de la 
          bicicleta y movilidad personal`,
          distractores: [`Es aquella que depende del movimiento motorizado, incluye el transporte público`, `Ninguna de las anteriores`],
      
        },
      
        {
          pregunta: `¿Cuáles son derechos de los biciusuarios? `,
          respuesta: `Todas las anteriores`,
          distractores: [`Derecho preferente de vía o circulación en los desvíos de avenidas y carreteras, cruce de caminos 
          intersecciones no señalizadas y ciclovías`, `Disponer vías de circulación privilegiada dentro de las ciudades y en carreteras, como ciclovías y 
          espacios similares`],
      
        },
        {
          pregunta: `De acuerdo a la normativa, ¿Dónde tienen derecho a circular los ciclistas?`,
          respuesta: `Transitar por todas las vías del país con respeto y seguridad.`,
          distractores: [`En las aceras, con cuidado respectivo a los peatones`, `Únicamente en las sendas especiales destinadas al uso de bicicletas`],
      
        },
        {
          pregunta: `¿En qué momento los conductores son responsables de su seguridad, de los pasajeros y el resto de 
          usuarios viales?`,
          respuesta: `En todo momento`,
          distractores: [`Los conductores no son responsables de lo que el pasajero haga o deje de hacer en cuanto a su 
          seguridad`, `Solo cuando esté encendido el motor del vehículo `],
        },
        {
          pregunta: `¿Qué se entiende por vía pública?`,
          respuesta: `Espacio público destinado al libre tránsito vehicular, bicicletas y peatonal`,
          distractores: [`El lugar por donde circulan los vehículos`, `El lugar por donde circulan los peatones`],
        },
        {
          pregunta: `¿Cuáles son las obligaciones de los peatones en la vía pública?`,
          respuesta: `Cruzar por las esquinas o cruces peatonales en las vías primarías y vías secundarias con más 
          de dos carriles efectivos de circulación; en vías secundarias que cuenten con un máximo de dos 
          carriles efectivos de circulación podrán cruzar en cualquier punto; y siempre y cuando le sea posible 
          hacerlo de manera segura`,
          distractores: [`Solicitar transporte o pedir ayuda a los automovilistas en lugares apropiados o prohibidos `, `Cruzar la calle por la parte anterior y posterior de los automotores que se hayan detenido momentáneamente`],
        },
        {
          pregunta: `¿Cuáles son derechos de los peatones?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Tener libre circulación sobre las aceras y zonas peatonales exclusivas, tener preferencia de vía sobre 
          todas las intersecciones, inclusive en las que tienen semáforo cuando la luz verde peatonal esté 
          encendida, todo el tiempo en los pasos cebra y en las esquinas no reguladas por semáforos, procurando 
          su propia seguridad y la de los demás`, `Disponer de las vías públicas libres de obstáculos y no invadidas, y contar con infraestructura y 
          señalización vial adecuadas que les brinden seguridad, en especial para las personas pertenecientes a los 
          grupos de atención prioritaria`],
        },
        {
          pregunta: `Uno de los objetivos de la Ley de Transporte Terrestre Tránsito y Seguridad Vial, es:`,
          respuesta: `El establecimiento de programas de capacitación y difusión para conductores, peatones, pasajeros y 
          autoridades, en materia de seguridad vial, para la creación de una cultura y conciencia vial 
          responsable y solidaria`,
          distractores: [`Definir sus principios, garantizar el derecho a la educación superior de calidad que propenda a la 
          excelencia`, `Contribuir al desarrollo del País a través de la formulación de políticas, regulaciones, planes, programas 
          y proyectos, que garanticen el buen vivir`],
        },
        {
          pregunta: `¿Cómo se sanciona a los responsables de un siniestro de tránsito que no sean conductores (pasajeros, 
            controladores, peatones, etc.)?`,
          respuesta: `Con las mismas sanciones que un conductor, exceptuando la pérdida de puntos`,
          distractores: [`Con las mismas penas que a un conductor responsable del siniestro`, `Con la mitad de las penas establecidas para un conductor responsable de un siniestro `],
        },
        {
          pregunta: `¿Qué implica conducir con prudencia a pesar de las acciones incorrectas de otros conductores y de las 
          condiciones adversas del clima y las vías, practicando normas de seguridad para no provocar un siniestro 
          de tránsito?`,
          respuesta: `Conducción con precaución`,
          distractores: [`Conducir con rapidez`, `Cumplir con las normas de tránsito`],
        },
        {
          pregunta: `¿Qué implica la prevención de siniestros?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Evitar las conductas riesgosas : exceso de velocidad, consumo de alcohol, no uso de cinturón, casco o 
          sistemas de retención infantil, no respetar las señales de tránsito, manejo distraído`, `Desarrollar un sistema seguro de movilidad, lo cual implica infraestructura para la seguridad vial, control 
          y capacitación de los actores viales`],
        },
        {
          pregunta: `¿Cuáles son los objetivos de la educación vial?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Reducir de forma sistemática los siniestros de tránsito`, `Formar y capacitar a las personas en general para el uso correcto de todos los medios de transporte
          terrestre`],
        },
        {
          pregunta: `Los niños menores de 12 años podrán viajar en:`,
          respuesta: `En los asientos posteriores utilizando los sistemas de retención infantil de acuerdo a la edad y altura`,
          distractores: [`En el asiento delantero con cinturón de seguridad`, `En el asiento delantero sin cinturón de seguridad`],
        },
        {
          pregunta: `¿Para qué sirven los seguros a prueba de niños en los vehículos?`,
          respuesta: `Impiden que las puertas puedan abrirse desde el interior`,
          distractores: [`Bloquean las ventanas traseras manteniéndolas cerradas`, `Aseguran las hebillas de los cinturones de seguridad`],
        },
        {
          pregunta: `El dispositivo de seguridad pasiva que reduce el desplazamiento de la cabeza hacia atrás para evitar el 
          efecto latigazo cervical, se conoce como:`,
          respuesta: `Apoya cabeza`,
          distractores: [`Cinturón de seguridad`, `Airbag`],
        },
        {
          pregunta: `¿Cómo se conoce al dispositivo de seguridad pasiva incluida en algunos vehículos el cual se acciona en 
          milisegundos al producirse un impacto?`,
          respuesta: `Airbag o bolsa de aire`,
          distractores: [`Apoya cabeza`, `Cinturón de seguridad`],
        },
        {
          pregunta: `¿Cuál es la distancia mínima que debe mantener entre vehículo y ciclista al conducir?`,
          respuesta: `1,5 metros`,
          distractores: [`1,0 metros`, `Todas las anteriores`],
        },
        {
          pregunta: `¿Cuándo debo llevar el kit de emergencia o carretero en mi vehículo?`,
          respuesta: `Siempre`,
          distractores: [`Solo Triángulos`, `Solo en caso de emergencia`],
        },
        {
          pregunta: `En un paso cebra no regulado, ¿Quién tiene la preferencia de paso?`,
          respuesta: `Los Peatones`,
          distractores: [`Los vehículos en circulación`, `El transporte público`],
        },
        {
          pregunta: `En caso de avería mecánica en la vía, ¿Debo poner los triángulos o conos de seguridad?`,
          respuesta: `Si, siempre`,
          distractores: [`No es necesario`, `Solo en caso de haber agentes de tránsito`],
        },
        {
        pregunta: `¿A qué distancia mínima antes de una maniobra es obligatorio poner la respectiva luz intermitente?`,
        respuesta: `Mínimo 30 metros antes`,
        distractores: [`Cuando ejecuto la maniobra`, `Mínimo 10 metros antes`],
         },
        {
          pregunta: `En un siniestro de tránsito las autoridades encargadas del control del tránsito, ¿Pueden detener a las 
          personas involucradas?`,
          respuesta: `Pueden detenerlos y ponerlos a las órdenes del fiscal, en el caso donde resultaron personas lesionadas 
          o fallecidas`,
          distractores: [`No pueden detener a nadie hasta que tengan una orden 
          judicial`, `Solamente pueden detener a los vehículos`],
        },
        {
          pregunta: `Al momento de rebasar a un ciclista en circulación, ¿Qué distancia mínima debe tomar el vehículo?`,
          respuesta: `1,5 metros`,
          distractores: [`1 metro`, `50 centímetros`],
        },
        {
          pregunta: `¿Cuál es la distancia mínima de seguimiento que debe mantener entre vehículo y ciclista al conducir?`,
          respuesta: `3 metros`,
          distractores: [`1 metro`, `2 metros`],
        },
        {
          pregunta: `¿Qué debe realizar un conductor cuando ve que un bus escolar e institucional se detiene en una parada?`,
          respuesta: `Esperar y dar el paso a todos los peatones que se bajan del bus`,
          distractores: [`Acelerar la marcha para pasar al bus`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `En las carreteras, ¿Los conductores cuando circulan deben dar preferencia?`,
          respuesta: `Peatones, ciclistas, motociclistas y similares.`,
          distractores: [`Otros conductores`, `Otros conductores`],
        },
        {
          pregunta: `¿Qué se presume si un conductor se niega a realizarse pruebas para la detección de alcohol o de la 
          presencia de sustancias estupefacientes?`,
          respuesta: `Será considerada como presunción de estar en el máximo grado de intoxicación`,
          distractores: [`Solo en el día`, `No`],
        },
        {
          pregunta: `Cuando se conduzca un vehículo a motor y se requiera cruzar una acera para entrar o salir de un 
          estacionamiento, ¿quién tiene preferencia de paso?`,
          respuesta: `Peatones, ciclistas y vehículos`,
          distractores: [`El conductor que está saliendo`, `Solo los vehículos`],
        },
        {
          pregunta: `¿A qué velocidad se estima que un atropellamiento de un niño/a sería mortal?`,
          respuesta: `25km/h`,
          distractores: [`15km/h`, `10km/h`],
        },
        {
          pregunta: `¿A qué velocidad se estima que el atropellamiento a un adulto sería mortal?`,
          respuesta: `60km/h`,
          distractores: [`40km/h`, `20km/h`],
        },
        {
          pregunta: `En caso de que no exista señalización horizontal y/o vertical, ¿Quién tendría derecho de paso en la vía en
          caso de que los usuarios presentes incluyan peatones, ciclistas, vehículos livianos, carga pesada?`,
          respuesta: `Peatones`,
          distractores: [`Biciusuarios`, `Transporte público de pasajeros`],
        },
        {
          pregunta: `Si un peatón va a cruzar una intersección con luz verde del semáforo vehicular o una intersección no 
          regulada y un vehículo va girar, ¿Quién tiene la preferencia de paso?`,
          respuesta: `El peatón `,
          distractores: [`El auto`, `Los dos`],
        },
        {
          pregunta: `¿Qué son las zonas 30?`,
          respuesta: `Zona en donde la velocidad máxima es 30 km/h`,
          distractores: [`Es una zona peatonal`, `Zona en donde la velocidad mínima es 30km/h`],
        },
        {
          pregunta: `¿Por qué la movilidad activa es importante?`,
          respuesta: `Genera menor impacto ambiental, social y económico`,
          distractores: [`Ninguna de las anteriores`, `Está de moda`],
        },
        {
          pregunta: `¿Un pasajero de transporte público puede denunciar ante la autoridad de tránsito, cuando el conductor 
          esté en estado de ebriedad?`,
          respuesta: `Es su obligación`,
          distractores: [`Talvez`, `No`],
        },
        {
          pregunta: `¿En qué porcentaje disminuyen las probabilidades de muerte para un motociclista o ciclista durante un 
          siniestro si utiliza casco homologado?`,
          respuesta: `39%`,
          distractores: [`No disminuyen, aumentan`, `1%`],
        },
      
      
    
    
    //*PREGUNTAS ESPECIFICAS AQUI*//
    
    
    
      {
        pregunta: `En una vía urbana, ¿cuál es el límite máximo de velocidad en el que debe circular un
    vehículo liviano?`,
        respuesta: `50 Km/h`,
        distractores: [`40 Km/h`, `60 Km/h`],
    
      },
    
      {
        pregunta: `En una vía perimetral, ¿cuál es el límite máximo de velocidad en el que debe circular
    un vehículo liviano?`,
        respuesta: `90 Km/h`,
        distractores: [`80 Km/h`, `95 Km/h`],
    
      },
      
      {
        pregunta: `En vías rectas, ¿cuál es el límite máximo de velocidad en el que debe circular un
    vehículo liviano?`,
        respuesta: `90 Km/h`,
        distractores: [`80 Km/h`, `100 Km/h`],
    
      },
    
      {
        pregunta: `¿De los siguientes enunciados cual corresponde a una Contravención de Tránsito?`,
        respuesta: `La o el conductor de un taxi, que no utilice el taxímetro las veinticuatro horas, altere
    su funcionamiento o no lo ubique en un lugar visible al usuario`,
        distractores: [`La o el conductor de un taxi, que utilice el taxímetro debidamente homologado`, `La o el conductor de un taxi, que ubique el taxímetro en el lugar especificado por los Organismos
    de Control de Tránsito`],
    
      },
    
      {
        pregunta: `¿Cuál es la capacidad de un taxi convencional?`,
        respuesta: `Cinco pasajeros, incluido el conductor`,
        distractores: [`Cinco pasajeros sin incluir al conductor`, `Cuatro pasajeros incluido el conductor`],
    
      },
    
      {
        pregunta: `Los vehículos que brindan el servicio de transporte en taxi ejecutivo tienen prohibido:`,
        respuesta: `Recoger pasajeros en las vías y que no respondan a llamadas telefónicas requiriendo
    el servicio`,
        distractores: [`Recoger pasajeros en las vías y que respondan a llamadas telefónicas requiriendo el servicio`, `No recoger pasajeros en las vías`],
    
      },
    
      {
        pregunta: `¿Se puede brindar el servicio de transporte en taxi con vehículos no autorizados?`,
        respuesta: `No, bajo ninguna circunstancia`,
        distractores: [`Si, si el vehículo autorizado se encuentra en reparación`, `Si, si la directiva de la operadora le autoriza mediante un documento el cual debe portar`],
    
      },
    
      {
        pregunta: `¿En qué momento los conductores de los servicios de taxis deben utilizar el taxímetro?`,
        respuesta: `En todo momento y en correcto funcionamiento durante el día y la noche`,
        distractores: [`En todo momento y en correcto funcionamiento hasta las 20h00`, `En todo momento y en correcto funcionamiento hasta las 22h00`],
    
      },
      {
        pregunta: `Se denomina servicio de transporte comercial al que: `,
        respuesta: `Se presta a terceras personas a cambio de una contraprestación económica, siempre que no sea 
        servicio de transporte colectivo o masivo`,
        distractores: [`Satisface necesidades de movilización de personas o bienes`, `Se lo realiza sólo dentro los límites establecidos para la zona de integración fronteriza respectiva`],
    
      },
      {
        pregunta: `¿De los siguientes enunciados cual corresponde a una Contravención de Tránsito?`,
        respuesta: `La o el conductor que ocasione un accidente de tránsito del que resulten solamente
    daños materiales, cuyos costos sean inferiores a dos salarios básicos unificados del
    trabajador en general`,
        distractores: [`La o el conductor que con un vehículo automotor, no exceda los límites de velocidad,
    establecidos en el reglamento correspondiente`, `La o el conductor que ocasione un accidente de tránsito del que resulten solamente daños
    materiales, cuyos costos sean inferiores a un salario básico unificado del trabajador en general`],
    
      },
    
      {
        pregunta: `Cuando uno o más pasajeros abordan un vehículo, quienes deben colocarse el cinturón
    de seguridad?:`,
        respuesta: `Todos los ocupantes del vehículo`,
        distractores: [`Solo el conductor`, `Solo el conductor y el pasajero que viaja en el asiento delantero`],
    
      },
    
      {
        pregunta: `¿De los siguientes enunciados cual corresponde a una Contravención de Tránsito?`,
        respuesta: `La o el conductor que utilice el teléfono celular mientras conduce y no haga uso del
    dispositivo homologado de manos libres`,
        distractores: [`La o el conductor que utilice el teléfono celular mientras conduce y utilice dispositivos instalados
    en su vehículo mediante bluetooth`, `La o el conductor que se detenga en lugares permitidos para hacer uso de su teléfono celular`],
    
      },
      {
        pregunta: `Con que periodicidad los conductores profesionales se someterán a exámenes
    médicos, psicológicos, psicosensométricos, y teóricos:`,
        respuesta: `Anualmente`,
        distractores: [`Cada dos años`, `Semestralmente`],
    
      },
    
      {
        pregunta: `Para el caso de conductores profesionales que deseen acceder a una licencia de categoría superior, 
        deberán acreditar:`,
        respuesta: `Todas las anteriores`,
        distractores: [`Haber mantenido mínimo por el lapso de dos años, una licencia profesional de categoría inferior 
        que se pretende obtener`, `Las escuelas de formación deberán comprobar la experiencia adquirida dentro del lapso de dos 
        años`],
    
      },
      {
        pregunta: `¿Qué tipo de licencia es necesaria para que una persona pueda conducir vehículos diseñados para el 
        transporte comercial con una capacidad de carga de hasta 3,5 toneladas? `,
        respuesta: `C`,
        distractores: [`B`, `D`],
    
      }
      
      
    ],
    C1: [
        {
          pregunta: `El no acatar lo indicado en una señal de tránsito, ¿Es una infracción?`,
          respuesta: `VERDADERO`,
          distractores: [`DESCONOCE`, `FALSO`],
      
        },
        {
          pregunta: `En un semáforo con giro a la derecha o izquierda, si el conductor tiene el semáforo en verde, al igual que los peatones, ¿Quién tiene la preferencia?`,
          imagen: `/img/1.png`,
          respuesta: `Los peatones`,
          distractores: [`Los vehículos`, `Ambos`],
      
        },
        {
          pregunta: `Cuándo un cruce de vía está regulado por un agente de tránsito, ¿Las señales manuales de éste prevalecen sobre cualquier otra existente, incluso semáforos?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Una línea continua pintada en la calzada de color blanco o amarillo restringe la circulación vehicular de tal manera que ningún vehículo podrá cruzar como se observa en la imagen?`,
          imagen: `/img/2.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Una línea discontinua de color amarillo o blanco pintada en la
          calzada, de acuerdo a la imagen ¿Que indica?`,
          imagen: `/img/3.png`,
          respuesta: `Permite rebasar o adelantar sobre esta línea, siempre que exista 
          seguridad para hacerlo`,
          distractores: [`El conductor no puede realizar maniobras de adelantamiento 
          cuando la línea es discontinua`, `Restringe la circulación vehicular de tal manera que ningún 
          vehículo podrá cruzar esta línea`],
      
        },
        {
          pregunta: `Cuando vea pintado en la calzada una línea mixta, con la línea 
          continua a la izquierda del conductor, de acuerdo a la imagen ¿Qué 
          indica esta línea?`,
          imagen: `/img/4.png`,
          respuesta: `El conductor no puede realizar maniobras de adelantamiento 
    cuando tiene la línea continua a su izquierda`,
          distractores: [`El conductor no puede realizar maniobras de adelantamiento 
    cuando tiene la línea continua a su izquierda
    `, `Puede realizar maniobras de adelantamiento cuando tiene la línea 
    discontinua a la izquierda del conductor`],
      
        },
        {
          pregunta: `Cuando ve pintado de color blanco o amarillo en la calzada doble línea 
          continua ¿Qué indica?`,
          imagen: `/img/5.png`,
          respuesta: `Están totalmente prohibidas las maniobras para rebasar o 
          adelantar por cualquiera de los carriles que se transite para evitar 
          choques`,
          distractores: [`Puede realizar maniobras de adelantamiento cuando tiene la línea 
    discontinua a la izquierda del conductor`, `Le permite al conductor rebasar o adelantar, siempre que exista la 
          seguridad de hacerlo`],
      
        },
        {
          pregunta: `De acuerdo a la imagen, ¿Qué debe hacer si compararte carril con un 
          ciclista?`,
          imagen: `/img/6.png`,
          respuesta: `Ambas`,
          distractores: [`Da prioridad a ciclista en el carril compartido`, `Reduce a velocidad y comparte la vía con el ciclista`],
      
        },
        {
          pregunta: `¿Esta señal se utiliza para indicar?`,
          imagen: `/img/7.png`,
          respuesta: `Obligación de los conductores circular solo en la dirección 
          indicada
          `,
          distractores: [`Un solo carril`, `Doble vía`],
      
        },
        {
          pregunta: `De acuerdo a la imagen esta señal indica, No exceder de 30 km/h`,
          imagen: `/img/8.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `De acuerdo a la imagen, ¿esta señal indica prohibido el cambio de 
          carril?`,
          imagen: `/img/9.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Qué debe hacer cuando usted observa esta señal?`,
          imagen: `/img/10.png`,
          respuesta: `Detenerse antes de la línea de pare y ceder el paso a todo tránsito`,
          distractores: [`Detenerse antes de la línea de pare`, `Reducir la velocidad y estar preparado para detenerse solamente si 
    hay peligro de colisión con otro vehículo o peatón`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/11.png`,
          respuesta: `Viraje prohibido al sentido de la flecha`,
          distractores: [`Viraje permitido en el sentido de la flecha`, `Viraje seguro`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/12.png`,
          respuesta: `No girar en "U"`,
          distractores: [`No virar a la izquierda`, `Tener cuidado con el tráfico que vira`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/13.png`,
          respuesta: `Tránsito pesado debe circular por la derecha`,
          distractores: [`Estacionamiento permitido para camiones`, `Circulación solo tránsito pesado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/14.png`,
          respuesta: `Prohibido circular vehículos pesados`,
          distractores: [`Prohibido circular vehículos pesados`, `Solo tránsito pesado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/15.png`,
          respuesta: `Prohibido adelantar o rebasar`,
          distractores: [`Solo tránsito liviano`, `Prohibido doble columna`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/16.png`,
          respuesta: `Vehículos livianos deben mantener su derecha`,
          distractores: [`Vehículos livianos deben girar a la derecha`, `Permitido estacionarse vehículos livianos`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/17.png`,
          respuesta: `Comienza doble vía, pueden venir vehículos en sentido contrario. 
          Precaución`,
          distractores: [`Cambio de carril`, `No entre`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/18.png`,
          respuesta: `Prohibido estacionarse o detenerse`,
          distractores: [`Prohibido estacionarse`, `Permite detenerse por un minuto solamente`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/19.png`,
          respuesta: `No entre`,
          distractores: [`Ninguna de las anteriores`, `Ingreso permitido`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/20.png`,
          respuesta: `Prohibido virar en U o a la izquierda`,
          distractores: [`Prohibido virar en U o a la derecha`, `Prohibido virar a la derecha o a la izquierda`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/21.png`,
          respuesta: `Prohibido cambio de carril a la izquierda`,
          distractores: [`Prohibido virar en U o a la izquierda`, `Prohibido tomar la vía sinuosa`],
      
        },
        {
          pregunta: `¿Cuáles son las consecuencias de incumplimiento de esta señal 
          (prohibido estacionarse)?`,
          imagen: `/img/22.png`,
          respuesta: `Sanción con el 10% del salario básico unificado`,
          distractores: [`Pena privativa de libertad`, `Retiro de la licencia de conducir.`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/23.png`,
          respuesta: `Prohibido estacionar al lado izquierdo de la señal hasta la próxima 
          intersección`,
          distractores: [`Permitido estacionar a la izquierda hasta la próxima intersección`, `Prohibido estacionar a la derecha hasta la próxima intersección`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/24.png`,
          respuesta: `Prohibido estacionar al lado derecho de la señal hasta la próxima intersección`,
          distractores: [`Prohibición de estacionar a la izquierda hasta la próxima intersección`, `Estacionar a la derecha hasta la próxima intersección`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/25.png`,
          respuesta: `Prohibido estacionar a los dos lados de la señal hasta la próxima intersección a izquierda y derecha`,
          distractores: [`Estacionar a la derecha hasta la próxima intersección`, `Prohibición de estacionar a la izquierda`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/26.png`,
          respuesta: `Estacionamiento permitido`,
          distractores: [`Estacionamiento tarifado`, `Prohibición de estacionar`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/27.png`,
          respuesta: `Estacionamiento tarifado durante los días y horarios señalados`,
          distractores: [`Estacionamiento tarifado a excepción de los días y horarios definidos`, `Estacionamiento no tarifado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/28.png`,
          respuesta: `Estacionamiento reservado solo para personas con discapacidad`,
          distractores: [`Estacionamiento prohibido para personas con discapacidad`, `Estacionamiento reservado para vehículos grandes`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/29.png`,
          respuesta: `Estacionamiento en reservado solo para personas con discapacidad a los lados de la señal`,
          distractores: [`Estacionamiento en paralelo reservado para vehículos grandes`, `Estacionamiento en paralelo prohibido para personas con discapacidad`],
      
        },
        {
          pregunta: `¿Qué debe hacer el conductor de un vehículo particular frente a esta señal?`,
          imagen: `/img/30.png`,
          respuesta: `Abstenerse de estacionar o detener el vehículo en esta zona`,
          distractores: [`Detener o estacionar el vehículo en esta zona`, `Aumentar la velocidad o rebasar al bus`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/31.png`,
          respuesta: `Largo máximo de los vehículos que circulan por esa vía`,
          distractores: [`Ancho máximo de los vehículos que circulan por esa vía`, `Ancho de la vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/32.png`,
          respuesta: `Carril exclusivo para peatones y ciclistas`,
          distractores: [`Circular con precaución sobre ese carril ya que es compartido con peatones y ciclistas`, `Circular utilizando luces de estacionamiento`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/33.png`,
          respuesta: `Que el carril segregado exclusivo para ciclistas y peatones se termina`,
          distractores: [`Que el carril segregado exclusivo para ciclistas y peatones se termina e inicia en la siguiente intersección`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `¿Qué debe hacer al observar la siguiente señal regulatoria?`,
          imagen: `/img/34.png`,
          respuesta: `Ceder el paso a los conductores, peatones y ciclistas`,
          distractores: [`Ceder el paso solo a los conductores`, `Ceder el paso solo a los peatones`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/35.png`,
          respuesta: `Más adelante intersección en “Y”`,
          distractores: [`Más adelante vía lateral`, `Más adelante cruce de vías`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/36.png`,
          respuesta: `Aproximación a redondel`,
          distractores: [`Más adelante vire en U`, `Más adelante zona de derrumbes`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/37.png`,
          respuesta: `Más adelante circulación en doble vía (bidireccional)`,
          distractores: [`Más adelante circulación en una vía`, `Más adelante vire a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/38.png`,
          respuesta: `Más adelante curva abierta hacia el lado que indica la flecha`,
          distractores: [`Más adelante curva cerrada hacia la derecha`, `Más adelante curva y contra curva`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/39.png`,
          respuesta: `Más adelante curva y contra curva cerrada a la derecha en la dirección señalada`,
          distractores: [`Más adelante curva pronunciada a la derecha`, `Más adelante curva abierta a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/40.png`,
          respuesta: `Más adelante curva y contra curva abierta a la izquierda en la dirección señalada`,
          distractores: [`Más adelante curva pronunciada a la izquierda`, `Más adelante curva abierta a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/41.png`,
          respuesta: `Más adelante vía sinuosa a la izquierda`,
          distractores: [`Más adelante curva y contra curva cerradas a la izquierda`, `Más adelante curva pronunciada a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/42.png`,
          respuesta: `Más adelante curva en "U" a la izquierda`,
          distractores: [`Más adelante curva en "U" a la izquierda`, `Más adelante curva en "U" a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/43.png`,
          respuesta: `Más adelante bandas transversales de alerta o de retumbo`,
          distractores: [`Más adelante agua en la vía`, `Más adelante hondonada`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/44.png`,
          respuesta: `Más adelante reductor de velocidad`,
          distractores: [`Más adelante calzada irregular`, `Más adelante hondonada`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/45.png`,
          respuesta: `Más adelante angostamiento en la vía`,
          distractores: [`Más adelante pendiente peligrosa`, `Más adelante pendiente peligrosa`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/46.png`,
          respuesta: `Disminuir la velocidad antes de ingresar a la curva`,
          distractores: [`Acelerar`, `Mantener la misma velocidad en la que se encuentra circulando`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/47.png`,
          respuesta: `Aproximación a una curva cerrada en la dirección señalada`,
          distractores: [`Aproximación a una curva abierta a la derecha`, `Aproximación a una contra curva a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/48.png`,
          respuesta: `Aproximación a una vía lateral izquierda o derecha en curva`,
          distractores: [`Aproximación a un cruce de vías al virar a la izquierda`, `Aproximación a una curva cerrada a la izquierda`],
        },
        {
          pregunta: `Esta señal se utiliza para indicar:`,
          imagen: `/img/49.png`,
          respuesta: `Dirección y lugar por el cual el flujo de tránsito debe dejar la calzada normal y desviarse por otras calles`,
          distractores: [`Que el desvío se ha terminado y que el flujo del tránsito regresa por el desvío a la ruta original `, `La ubicación y el lugar por el cual el flujo de tránsito debe continuar la calzada normal`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/50.png`,
          respuesta: `Señal con lugar de destino y flecha de asignación de carril`,
          distractores: [`Señal con lugar de destino y mensaje de acciones en palabras`, `Señal con código de ruta, lugar de destino y mensaje de salida con distancia en kilómetros`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/51.png`,
          respuesta: `Zona Escolar`,
          distractores: [`La velocidad máxima en una zona donde se encuentran centros educativos`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/52.png`,
          respuesta: `Ambas`,
          distractores: [`Reducir la velocidad y conducir con precaución`, `La velocidad máxima de circulación en una zona escolar`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/53.png`,
          respuesta: `El inicio del paradero de una zona escolar`,
          distractores: [`Parada de vehículos pesados`, `Zona permitida para familiares de estudiantes`],
        },
        {
          pregunta: `La luz amarilla intermitente en un semáforo significa:`,
          respuesta: `Disminuir la velocidad y atravesar la intersección con mucha precaución`,
          distractores: [`Parar siempre y cuando lo pueda hacer con seguridad`, `Parar, ceda el paso a todo el tráfico que cruza antes de atravesar la intersección`],
      
        },
        {
          pregunta: `¿Las instrucciones del agente de tránsito prevalecen sobre las señales de tránsito aunque el semáforo se 
          encuentre en luz roja?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿En cuál de las siguientes luces de semáforo debería siempre detener su vehículo?`,
          respuesta: `Luces rojas y luces rojas intermitentes `,
          distractores: [`Luces rojas continuas, flechas rojas y luces amarillas intermitentes `, `Luces rojas continuas, luces rojas intermitentes y luces amarillas`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/54.png`,
          respuesta: `Motocicletas deben mantenerse en el carril derecho`,
          distractores: [`Motocicletas deben girar a la derecha`, `Permitido estacionarse motocicletas`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/55.png`,
          respuesta: `Prohibido el ingreso o circulación de vehículos motorizados`,
          distractores: [`Prohibida la circulación de buses`, `Prohibida la circulación de vehículos pesados`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/56.png`,
          respuesta: `Más adelante intersección en “T”`,
          distractores: [`Más adelante redondel`, `Más adelante cruce de tren`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/57.png`,
          respuesta: `Más adelante parterre termina`,
          distractores: [`Más adelante angostamiento de vía`, `Más adelante parterre comienza`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/58.png`,
          respuesta: `Más adelante parterre comienza`,
          distractores: [`Más adelante parterre termina`, `Más adelante angostamiento de vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/59.png`,
          respuesta: `Más adelante cruce de ganado`,
          distractores: [`Más adelante cruce de peatones`, `Más adelante cruce de maquinaria agrícola`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/60.png`,
          respuesta: `Aproximación a túnel`,
          distractores: [`Túnel obstruido`, `Trabajos en el túnel`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/61.png`,
          respuesta: `Más adelante vía sinuosa a la derecha`,
          distractores: [`Más adelante curva pronunciada a la derecha`, `Más adelante curva y contra curva abiertas a la derecha`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/62.png`,
          respuesta: `Más adelante vía compartida con ciclistas`,
          distractores: [`Más adelante solo vehículos livianos`, `Más adelante solo vehículos livianos`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/63.png`,
          respuesta: `Más adelante depresión en la vía`,
          distractores: [`Más adelante vía cerrada`, `Más adelante reductor de velocidad`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/64.png`,
          respuesta: `Más adelante puente angosto`,
          distractores: [`Más adelante pendiente peligrosa`, `Más adelante estrechamiento en la vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/65.png`,
          respuesta: `Límite máximo de velocidad`,
          distractores: [`Velocidad mínima de Km/h`, `Usted se encuentra en el Kilómetro 30`],
        },
        {
          pregunta: `La luz roja intermitente en un semáforo significa:`,
          respuesta: `Parar siempre`,
          distractores: [`Disminuir la velocidad y atravesar la intersección con mucha precaución`, `Parar, ceda el paso a todo el tráfico que cruza antes de atravesar la intersección`],
      
        },
        {
          pregunta: `La luz roja intermitente en una señal de PARE en el extremo lateral izquierdo de un transporte escolar, 
          ¿Qué significa?`,
          respuesta: `Prohibido adelantar o rebasar al transporte escolar, hasta que se apague la luz intermitente y se 
          guarde la señal de PARE`,
          distractores: [`Disminuir la velocidad y adelantar con precaución al transporte escolar`, `Que se encendió las luces de estacionamiento del transporte escolar`],
      
        },
        {
          pregunta: `La licencia digital es válida para circular en el Ecuador:`,
          respuesta: `Si`,
          distractores: [`Solo si está impresa`, `Ninguna de las anteriores`],
      
        },
      
        {
          pregunta: `Es válido portar una copia simple de la licencia o matrícula para circular en el Ecuador:`,
          respuesta: `No, nunca`,
          distractores: [`Solo si está impresa`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `Debo hacer un curso de conducción para obtener mi licencia de conductor en el Ecuador:`,
          respuesta: `Sí, siempre`,
          distractores: [`Solo si me pide el agente de tránsito`, `No`],
      
        },
        {
          pregunta: `El no cancelar el valor de multas no le permitirá: renovar la licencia, realizar la revisión técnica vehicular y 
          matricular su vehículo`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿El conductor que NO haya sido sancionado durante el tiempo de vigencia de la licencia de conducir y 
          mantenga los 30 (puntos), se beneficiará del 30% en el pago del valor de la especie de la licencia de 
          conducir?`,
          respuesta: `FALSO`,
          distractores: [`VERDADERO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Si conduce su vehículo con un nivel de alcohol por litro de sangre de 0,3 a 0,8; ¿Qué sanción será aplicada?`,
          respuesta: `Cinco días de privación de libertad y retención del vehículo por veinticuatro horas`,
          distractores: [`Treinta días de privación de libertad y retención del vehículo por veinticuatro horas`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `El conductor que falte de obra a la autoridad o agente de tránsito, ¿Será sancionado con pena 
          privativa de libertad de tres días?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
      
        {
          pregunta: `Si se queda sin combustible y por esa razón obstaculiza el tránsito vehicular, ¿Está cometiendo una 
          contravención?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Es una contravención de tránsito, la o el conductor que conduzca con licencia caducada?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor y pasajeros que no hagan uso del cinturón de seguridad, ¿Corresponde a una 
          contravención de transito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor que invada con su vehículo circulando o estacionándose, en las vías asignadas para uso 
          exclusivo de los ciclistas, ¿Corresponde a una contravención de tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Los conductores que conduzcan en estado de embriaguez o bajo los efectos de sustancias estupefacientes, 
          psicotrópicas o preparados que las contengan y del cual provoquen un siniestro del cual resulte la muerte, 
          ¿Qué sanción corresponde?`,
          respuesta: `Revocatoria definitiva de la licencia para conducir`,
          distractores: [`Pena privativa de la libertad de doce a veinte años`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `El conducir un vehículo cuyas llantas se encuentren lisas o en mal estado, ¿Será sancionado con pena 
          privativa de libertad de cinco a quince días?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `El conducir un vehículo con las luces no encendidas en horas de la noche o conduzca en sitios oscuros 
          como túneles con la luces apagadas, ¿Corresponde a una contravención de tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Cuál de los siguientes enunciados corresponde a una contravención de tránsito?`,
          respuesta: `La o el conductor que conduce sin mantener una distancia prudente de seguimiento respecto al 
          vehículo y ciclista de adelante `,
          distractores: [`La o el conductor que conduce con una distancia prudente de seguimiento respecto al vehículo de 
          adelante`, `La o el conductor que cede el paso y permite el adelantamiento de otros vehículos.`],
      
        },
        {
          pregunta: `La o el conductor de un vehículo automotor que circule con personas en los estribos o pisaderas, baldes de 
          camionetas, parachoques o colgados de las carrocerías de los vehículos , ¿Es una contravención de 
          transito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor de un vehículo particular que tenga vidrios con películas antisolares oscuras, polarizados 
          o cualquier tipo de adhesivo y que no tenga la debida autorización, ¿Corresponde a una contravención de 
          tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        
        {
          pregunta: `¿Qué indican las líneas blancas que se observan en la imagen?`,
          imagen: `/img/66.png`,
          respuesta: `Líneas de borde`,
          distractores: [`Línea de prohibición de estacionamiento`, `Línea de prohibición de estacionamiento`],
      
        },
        {
          pregunta: `¿Qué indican las líneas blancas que se observan en la imagen?`,
          imagen: `/img/67.png`,
          respuesta: `Indican a los conductores, especialmente en caso de poca 
          visibilidad, dónde se encuentra el borde de la calzada`,
          distractores: [`Indican a los conductores que es permitido el parqueo vehículos`, `Indican a los conductores que es permitido el parqueo vehículos`],
      
        },
        {
          pregunta: `¿Qué significan las líneas segmentadas pintadas de blanco en una 
          intersección, que se observan en la imagen ?`,
          imagen: `/img/68.png`,
          respuesta: `Cruce de ciclovías`,
          distractores: [`Cruce peatonal`, `Cruce de mascotas`],
      
        },
      
        {
          pregunta: `De acuerdo a la imagen, ¿Las señales reglamentarias indican la 
          dirección obligatoria en el carril que deben seguir los vehículos en la 
          próxima intersección?`,
          imagen: `/img/69.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`,`DESCONOCE`],
      
        },
      
      
        {
          pregunta: `¿Qué debe hacer el conductor cuando en la vía observe un cruce 
          peatonal demarcado tipo cebra como indica la imagen?`,
          imagen: `/img/70.png`,
          respuesta: `Debe ceder el paso a los peatones que se encuentran cruzando la vía`,
          distractores: [`Debe pitar para alertar al peatón que no baje de la vereda`, `Debe acelerar para pasar más rápido`],
      
        },
      
        {
          pregunta: `¿Qué debe hacer el conductor cuando se encuentre con una señal 
          horizontal pintada en la vía como se observa en la imagen?`,
          imagen: `/img/71.png`,
          respuesta: `Velocidad máxima de 40 km/h`,
          distractores: [`Velocidad mínima de 40 km/h`, `Velocidad mínima de 40 km/h`],
      
        },
        {
          pregunta: `De acuerdo a la imagen , ¿Qué significa la siguiente señal horizontal 
          pintada en la vía?`,
          imagen: `/img/72.png`,
          respuesta: `Prohibido estacionar`,
          distractores: [`Estacionar temporalmente cuando no exista un agente de tránsito`, `Estacionar temporalmente cuando no exista un agente de tránsito`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señalización horizontal?`,
          imagen: `/img/73.png`,
          respuesta: `Estacionamiento tarifado`,
          distractores: [`No estacionar`, `Estacionamiento gratuito`],
      
        },
      
        {
          pregunta: `El conductor cuando se encuentre en una intersección, ¿Debe detener 
          su vehículo, bicicleta y peatones completamente y dar el paso a 
          cualquier vehículo que se encuentre cruzando la intersección?`,
          imagen: `/img/74.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
      
        {
          pregunta: `Los vehículos A y B llegan al mismo tiempo a una intersección en cruz 
          por diferentes lados ¿Cuál tiene derecho de vía?`,
          imagen: `/img/75.png`,
          respuesta: `El vehículo que se aproxima a la derecha`,
          distractores: [`Cualquiera de los dos`, `El vehículo que se aproxima a la izquierda`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/76.png`,
          respuesta: `Giro a izquierda`,
          distractores: [`Saludo`, `Disminuir la velocidad`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/77.png`,
          respuesta: `Giro a derecha`,
          distractores: [`Saludo`, `Parar`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/78.png`,
          respuesta: `Estacionarse o detenerse`,
          distractores: [`Solicita ayuda`, `Giro a izquierda`],
      
        },
      
        {
          pregunta: `¿Qué significa el espacio de la berma?`,
          imagen: `/img/79.png`,
          respuesta: `Espacio exclusivo para circulación de peatones, ciclistas y 
          movilidad personal`,
          distractores: [`Estacionamiento`, `Carril para circulación de motos`],
      
        },
        {
          pregunta: `¿Qué es la movilidad activa?`,
          respuesta: `Es aquella que depende del movimiento físico de las personas, incluye la caminata el uso de la 
          bicicleta y movilidad personal`,
          distractores: [`Es aquella que depende del movimiento motorizado, incluye el transporte público`, `Ninguna de las anteriores`],
      
        },
      
        {
          pregunta: `¿Cuáles son derechos de los biciusuarios? `,
          respuesta: `Todas las anteriores`,
          distractores: [`Derecho preferente de vía o circulación en los desvíos de avenidas y carreteras, cruce de caminos 
          intersecciones no señalizadas y ciclovías`, `Disponer vías de circulación privilegiada dentro de las ciudades y en carreteras, como ciclovías y 
          espacios similares`],
      
        },
        {
          pregunta: `De acuerdo a la normativa, ¿Dónde tienen derecho a circular los ciclistas?`,
          respuesta: `Transitar por todas las vías del país con respeto y seguridad.`,
          distractores: [`En las aceras, con cuidado respectivo a los peatones`, `Únicamente en las sendas especiales destinadas al uso de bicicletas`],
      
        },
        {
          pregunta: `¿En qué momento los conductores son responsables de su seguridad, de los pasajeros y el resto de 
          usuarios viales?`,
          respuesta: `En todo momento`,
          distractores: [`Los conductores no son responsables de lo que el pasajero haga o deje de hacer en cuanto a su 
          seguridad`, `Solo cuando esté encendido el motor del vehículo `],
        },
        {
          pregunta: `¿Qué se entiende por vía pública?`,
          respuesta: `Espacio público destinado al libre tránsito vehicular, bicicletas y peatonal`,
          distractores: [`El lugar por donde circulan los vehículos`, `El lugar por donde circulan los peatones`],
        },
        {
          pregunta: `¿Cuáles son las obligaciones de los peatones en la vía pública?`,
          respuesta: `Cruzar por las esquinas o cruces peatonales en las vías primarías y vías secundarias con más 
          de dos carriles efectivos de circulación; en vías secundarias que cuenten con un máximo de dos 
          carriles efectivos de circulación podrán cruzar en cualquier punto; y siempre y cuando le sea posible 
          hacerlo de manera segura`,
          distractores: [`Solicitar transporte o pedir ayuda a los automovilistas en lugares apropiados o prohibidos `, `Cruzar la calle por la parte anterior y posterior de los automotores que se hayan detenido momentáneamente`],
        },
        {
          pregunta: `¿Cuáles son derechos de los peatones?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Tener libre circulación sobre las aceras y zonas peatonales exclusivas, tener preferencia de vía sobre 
          todas las intersecciones, inclusive en las que tienen semáforo cuando la luz verde peatonal esté 
          encendida, todo el tiempo en los pasos cebra y en las esquinas no reguladas por semáforos, procurando 
          su propia seguridad y la de los demás`, `Disponer de las vías públicas libres de obstáculos y no invadidas, y contar con infraestructura y 
          señalización vial adecuadas que les brinden seguridad, en especial para las personas pertenecientes a los 
          grupos de atención prioritaria`],
        },
        {
          pregunta: `Uno de los objetivos de la Ley de Transporte Terrestre Tránsito y Seguridad Vial, es:`,
          respuesta: `El establecimiento de programas de capacitación y difusión para conductores, peatones, pasajeros y 
          autoridades, en materia de seguridad vial, para la creación de una cultura y conciencia vial 
          responsable y solidaria`,
          distractores: [`Definir sus principios, garantizar el derecho a la educación superior de calidad que propenda a la 
          excelencia`, `Contribuir al desarrollo del País a través de la formulación de políticas, regulaciones, planes, programas 
          y proyectos, que garanticen el buen vivir`],
        },
        {
          pregunta: `¿Cómo se sanciona a los responsables de un siniestro de tránsito que no sean conductores (pasajeros, 
            controladores, peatones, etc.)?`,
          respuesta: `Con las mismas sanciones que un conductor, exceptuando la pérdida de puntos`,
          distractores: [`Con las mismas penas que a un conductor responsable del siniestro`, `Con la mitad de las penas establecidas para un conductor responsable de un siniestro `],
        },
        {
          pregunta: `¿Qué implica conducir con prudencia a pesar de las acciones incorrectas de otros conductores y de las 
          condiciones adversas del clima y las vías, practicando normas de seguridad para no provocar un siniestro 
          de tránsito?`,
          respuesta: `Conducción con precaución`,
          distractores: [`Conducir con rapidez`, `Cumplir con las normas de tránsito`],
        },
        {
          pregunta: `¿Qué implica la prevención de siniestros?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Evitar las conductas riesgosas : exceso de velocidad, consumo de alcohol, no uso de cinturón, casco o 
          sistemas de retención infantil, no respetar las señales de tránsito, manejo distraído`, `Desarrollar un sistema seguro de movilidad, lo cual implica infraestructura para la seguridad vial, control 
          y capacitación de los actores viales`],
        },
        {
          pregunta: `¿Cuáles son los objetivos de la educación vial?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Reducir de forma sistemática los siniestros de tránsito`, `Formar y capacitar a las personas en general para el uso correcto de todos los medios de transporte
          terrestre`],
        },
        {
          pregunta: `Los niños menores de 12 años podrán viajar en:`,
          respuesta: `En los asientos posteriores utilizando los sistemas de retención infantil de acuerdo a la edad y altura`,
          distractores: [`En el asiento delantero con cinturón de seguridad`, `En el asiento delantero sin cinturón de seguridad`],
        },
        {
          pregunta: `¿Para qué sirven los seguros a prueba de niños en los vehículos?`,
          respuesta: `Impiden que las puertas puedan abrirse desde el interior`,
          distractores: [`Bloquean las ventanas traseras manteniéndolas cerradas`, `Aseguran las hebillas de los cinturones de seguridad`],
        },
        {
          pregunta: `El dispositivo de seguridad pasiva que reduce el desplazamiento de la cabeza hacia atrás para evitar el 
          efecto latigazo cervical, se conoce como:`,
          respuesta: `Apoya cabeza`,
          distractores: [`Cinturón de seguridad`, `Airbag`],
        },
        {
          pregunta: `¿Cómo se conoce al dispositivo de seguridad pasiva incluida en algunos vehículos el cual se acciona en 
          milisegundos al producirse un impacto?`,
          respuesta: `Airbag o bolsa de aire`,
          distractores: [`Apoya cabeza`, `Cinturón de seguridad`],
        },
        {
          pregunta: `¿Cuál es la distancia mínima que debe mantener entre vehículo y ciclista al conducir?`,
          respuesta: `1,5 metros`,
          distractores: [`1,0 metros`, `Todas las anteriores`],
        },
        {
          pregunta: `¿Cuándo debo llevar el kit de emergencia o carretero en mi vehículo?`,
          respuesta: `Siempre`,
          distractores: [`Solo Triángulos`, `Solo en caso de emergencia`],
        },
        {
          pregunta: `En un paso cebra no regulado, ¿Quién tiene la preferencia de paso?`,
          respuesta: `Los Peatones`,
          distractores: [`Los vehículos en circulación`, `El transporte público`],
        },
        {
          pregunta: `En caso de avería mecánica en la vía, ¿Debo poner los triángulos o conos de seguridad?`,
          respuesta: `Si, siempre`,
          distractores: [`No es necesario`, `Solo en caso de haber agentes de tránsito`],
        },
        {
        pregunta: `¿A qué distancia mínima antes de una maniobra es obligatorio poner la respectiva luz intermitente?`,
        respuesta: `Mínimo 30 metros antes`,
        distractores: [`Cuando ejecuto la maniobra`, `Mínimo 10 metros antes`],
         },
        {
          pregunta: `En un siniestro de tránsito las autoridades encargadas del control del tránsito, ¿Pueden detener a las 
          personas involucradas?`,
          respuesta: `Pueden detenerlos y ponerlos a las órdenes del fiscal, en el caso donde resultaron personas lesionadas 
          o fallecidas`,
          distractores: [`No pueden detener a nadie hasta que tengan una orden 
          judicial`, `Solamente pueden detener a los vehículos`],
        },
        {
          pregunta: `Al momento de rebasar a un ciclista en circulación, ¿Qué distancia mínima debe tomar el vehículo?`,
          respuesta: `1,5 metros`,
          distractores: [`1 metro`, `50 centímetros`],
        },
        {
          pregunta: `¿Cuál es la distancia mínima de seguimiento que debe mantener entre vehículo y ciclista al conducir?`,
          respuesta: `3 metros`,
          distractores: [`1 metro`, `2 metros`],
        },
        {
          pregunta: `¿Qué debe realizar un conductor cuando ve que un bus escolar e institucional se detiene en una parada?`,
          respuesta: `Esperar y dar el paso a todos los peatones que se bajan del bus`,
          distractores: [`Acelerar la marcha para pasar al bus`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `En las carreteras, ¿Los conductores cuando circulan deben dar preferencia?`,
          respuesta: `Peatones, ciclistas, motociclistas y similares.`,
          distractores: [`Otros conductores`, `Otros conductores`],
        },
        {
          pregunta: `¿Qué se presume si un conductor se niega a realizarse pruebas para la detección de alcohol o de la 
          presencia de sustancias estupefacientes?`,
          respuesta: `Será considerada como presunción de estar en el máximo grado de intoxicación`,
          distractores: [`Solo en el día`, `No`],
        },
        {
          pregunta: `Cuando se conduzca un vehículo a motor y se requiera cruzar una acera para entrar o salir de un 
          estacionamiento, ¿quién tiene preferencia de paso?`,
          respuesta: `Peatones, ciclistas y vehículos`,
          distractores: [`El conductor que está saliendo`, `Solo los vehículos`],
        },
        {
          pregunta: `¿A qué velocidad se estima que un atropellamiento de un niño/a sería mortal?`,
          respuesta: `25km/h`,
          distractores: [`15km/h`, `10km/h`],
        },
        {
          pregunta: `¿A qué velocidad se estima que el atropellamiento a un adulto sería mortal?`,
          respuesta: `60km/h`,
          distractores: [`40km/h`, `20km/h`],
        },
        {
          pregunta: `En caso de que no exista señalización horizontal y/o vertical, ¿Quién tendría derecho de paso en la vía en
          caso de que los usuarios presentes incluyan peatones, ciclistas, vehículos livianos, carga pesada?`,
          respuesta: `Peatones`,
          distractores: [`Biciusuarios`, `Transporte público de pasajeros`],
        },
        {
          pregunta: `Si un peatón va a cruzar una intersección con luz verde del semáforo vehicular o una intersección no 
          regulada y un vehículo va girar, ¿Quién tiene la preferencia de paso?`,
          respuesta: `El peatón `,
          distractores: [`El auto`, `Los dos`],
        },
        {
          pregunta: `¿Qué son las zonas 30?`,
          respuesta: `Zona en donde la velocidad máxima es 30 km/h`,
          distractores: [`Es una zona peatonal`, `Zona en donde la velocidad mínima es 30km/h`],
        },
        {
          pregunta: `¿Por qué la movilidad activa es importante?`,
          respuesta: `Genera menor impacto ambiental, social y económico`,
          distractores: [`Ninguna de las anteriores`, `Está de moda`],
        },
        {
          pregunta: `¿Un pasajero de transporte público puede denunciar ante la autoridad de tránsito, cuando el conductor 
          esté en estado de ebriedad?`,
          respuesta: `Es su obligación`,
          distractores: [`Talvez`, `No`],
        },
        {
          pregunta: `¿En qué porcentaje disminuyen las probabilidades de muerte para un motociclista o ciclista durante un 
          siniestro si utiliza casco homologado?`,
          respuesta: `39%`,
          distractores: [`No disminuyen, aumentan`, `1%`],
        },
      
    
    
    //*PREGUNTAS ESPECIFICAS AQUI*//
    
    
      {
        pregunta: `En una vía urbana, ¿cuál es el límite máximo de velocidad en el que debe circular un
    vehículo liviano?`,
        respuesta: `50 Km/h`,
        distractores: [`40 Km/h`, `60 Km/h`],
    
      },
    
      {
        pregunta: `En una vía perimetral, ¿cuál es el límite máximo de velocidad en el que debe circular
    un vehículo liviano?`,
        respuesta: `90 Km/h`,
        distractores: [`100 Km/h`, `120 Km/h`],
    
      },
    
      {
        pregunta: `En vías rectas, ¿cuál es el límite máximo de velocidad en el que debe circular un
    vehículo liviano?`,
        respuesta: `100 Km/h`,
        distractores: [` 110 Km/h`, `135 Km/h`],
    
      },
    
      {
        pregunta: `¿Qué tipo de licencia profesional es aquella destinada para vehículos policiales,
    ambulancias, militares, municipales y en general todo vehículo público o particular de
    emergencia y control de seguridad?`,
        respuesta: `C1`,
        distractores: [`D`, `C`],
    
      },
    
      {
        pregunta: `Cuando uno o más pasajeros abordan un vehículo, quienes deben colocarse el cinturón
    de seguridad?:`,
        respuesta: `Todos los ocupantes del vehículo`,
        distractores: [`Solo el conductor y el pasajero que viaja en el asiento delantero`, `Solo el conductor`],
    
      },
    
      {
        pregunta: `¿De los siguientes enunciados cual corresponde a una Contravención de Tránsito?`,
        respuesta: `La o el conductor que utilice el teléfono celular mientras conduce y no haga uso del
    dispositivo homologado de manos libres`,
        distractores: [`La o el conductor que se detenga en lugares permitidos para hacer uso de su teléfono celular`, ` La o el conductor que utilice el teléfono celular mientras conduce y utilice dispositivos instalados
    en su vehículo mediante bluetooth`],
    
      },
      {
        pregunta: `Con que periodicidad los conductores profesionales se someterán a exámenes
    médicos, psicológicos, psicosensométricos, y teóricos:`,
        respuesta: `Anualmente`,
        distractores: [`Cada dos años`, `Semestralmente`],
    
      },
    
      {
        pregunta: `Para el caso de conductores profesionales, que deseen acceder a una licencia de
    categoría superior, deberán acreditar:`,
        respuesta: `Todas las anteriores`,
        distractores: [`Haber mantenido mínimo por el lapso de dos años, una licencia profesional de categoría inferior a
    la que se pretende obtener`, `Las escuelas de formación deberán comprobar la experiencia adquirida dentro del lapso de dos
    años `],
    
      },
    
      {
        pregunta: `Los usuarios que deseen renovar su licencia de conducir lo podrán hacer:`,
        respuesta: `Noventa días antes de su caducidad`,
        distractores: [`Cuarenta y cinco días antes de su caducidad`, `Treinta días antes de su caducidad`],
    
      },
    
      {
        pregunta: `Los vehículos que pertenecen al sector público o particular de emergencia y control de
    seguridad, deberán cumplir con los requisitos determinados en la normativa aplicable
    expedida por:`,
        respuesta: `Contraloría General del Estado`,
        distractores: [`Registro Civil`, `Ministerio de Transporte y Obras Públicas`],
    
      },
    
      {
        pregunta: `Es prohibida la instalación de luces extras no definidas dentro de las normas INEN como luces 
        estroboscópicas y balizas, salvo autorización de la autoridad competente, exceptuando en los 
        siguientes vehículos:`,
        respuesta: `Vehículos especiales y de emergencia como ambulancias, bomberos y policía`,
        distractores: [`Vehículos públicos destinados a Autoridades`, `Vehículos públicos en genera`],
    
      }
    
    ],
    D: [
        {
          pregunta: `El no acatar lo indicado en una señal de tránsito, ¿Es una infracción?`,
          respuesta: `VERDADERO`,
          distractores: [`DESCONOCE`, `FALSO`],
      
        },
        {
          pregunta: `En un semáforo con giro a la derecha o izquierda, si el conductor tiene el semáforo en verde, al igual que los peatones, ¿Quién tiene la preferencia?`,
          imagen: `/img/1.png`,
          respuesta: `Los peatones`,
          distractores: [`Los vehículos`, `Ambos`],
      
        },
        {
          pregunta: `Cuándo un cruce de vía está regulado por un agente de tránsito, ¿Las señales manuales de éste prevalecen sobre cualquier otra existente, incluso semáforos?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Una línea continua pintada en la calzada de color blanco o amarillo restringe la circulación vehicular de tal manera que ningún vehículo podrá cruzar como se observa en la imagen?`,
          imagen: `/img/2.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Una línea discontinua de color amarillo o blanco pintada en la
          calzada, de acuerdo a la imagen ¿Que indica?`,
          imagen: `/img/3.png`,
          respuesta: `Permite rebasar o adelantar sobre esta línea, siempre que exista 
          seguridad para hacerlo`,
          distractores: [`El conductor no puede realizar maniobras de adelantamiento 
          cuando la línea es discontinua`, `Restringe la circulación vehicular de tal manera que ningún 
          vehículo podrá cruzar esta línea`],
      
        },
        {
          pregunta: `Cuando vea pintado en la calzada una línea mixta, con la línea 
          continua a la izquierda del conductor, de acuerdo a la imagen ¿Qué 
          indica esta línea?`,
          imagen: `/img/4.png`,
          respuesta: `El conductor no puede realizar maniobras de adelantamiento 
    cuando tiene la línea continua a su izquierda`,
          distractores: [`El conductor no puede realizar maniobras de adelantamiento 
    cuando tiene la línea continua a su izquierda
    `, `Puede realizar maniobras de adelantamiento cuando tiene la línea 
    discontinua a la izquierda del conductor`],
      
        },
        {
          pregunta: `Cuando ve pintado de color blanco o amarillo en la calzada doble línea 
          continua ¿Qué indica?`,
          imagen: `/img/5.png`,
          respuesta: `Están totalmente prohibidas las maniobras para rebasar o 
          adelantar por cualquiera de los carriles que se transite para evitar 
          choques`,
          distractores: [`Puede realizar maniobras de adelantamiento cuando tiene la línea 
    discontinua a la izquierda del conductor`, `Le permite al conductor rebasar o adelantar, siempre que exista la 
          seguridad de hacerlo`],
      
        },
        {
          pregunta: `De acuerdo a la imagen, ¿Qué debe hacer si compararte carril con un 
          ciclista?`,
          imagen: `/img/6.png`,
          respuesta: `Ambas`,
          distractores: [`Da prioridad a ciclista en el carril compartido`, `Reduce a velocidad y comparte la vía con el ciclista`],
      
        },
        {
          pregunta: `¿Esta señal se utiliza para indicar?`,
          imagen: `/img/7.png`,
          respuesta: `Obligación de los conductores circular solo en la dirección 
          indicada
          `,
          distractores: [`Un solo carril`, `Doble vía`],
      
        },
        {
          pregunta: `De acuerdo a la imagen esta señal indica, No exceder de 30 km/h`,
          imagen: `/img/8.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `De acuerdo a la imagen, ¿esta señal indica prohibido el cambio de 
          carril?`,
          imagen: `/img/9.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Qué debe hacer cuando usted observa esta señal?`,
          imagen: `/img/10.png`,
          respuesta: `Detenerse antes de la línea de pare y ceder el paso a todo tránsito`,
          distractores: [`Detenerse antes de la línea de pare`, `Reducir la velocidad y estar preparado para detenerse solamente si 
    hay peligro de colisión con otro vehículo o peatón`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/11.png`,
          respuesta: `Viraje prohibido al sentido de la flecha`,
          distractores: [`Viraje permitido en el sentido de la flecha`, `Viraje seguro`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/12.png`,
          respuesta: `No girar en "U"`,
          distractores: [`No virar a la izquierda`, `Tener cuidado con el tráfico que vira`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/13.png`,
          respuesta: `Tránsito pesado debe circular por la derecha`,
          distractores: [`Estacionamiento permitido para camiones`, `Circulación solo tránsito pesado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/14.png`,
          respuesta: `Prohibido circular vehículos pesados`,
          distractores: [`Prohibido circular vehículos pesados`, `Solo tránsito pesado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/15.png`,
          respuesta: `Prohibido adelantar o rebasar`,
          distractores: [`Solo tránsito liviano`, `Prohibido doble columna`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/16.png`,
          respuesta: `Vehículos livianos deben mantener su derecha`,
          distractores: [`Vehículos livianos deben girar a la derecha`, `Permitido estacionarse vehículos livianos`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/17.png`,
          respuesta: `Comienza doble vía, pueden venir vehículos en sentido contrario. 
          Precaución`,
          distractores: [`Cambio de carril`, `No entre`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/18.png`,
          respuesta: `Prohibido estacionarse o detenerse`,
          distractores: [`Prohibido estacionarse`, `Permite detenerse por un minuto solamente`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/19.png`,
          respuesta: `No entre`,
          distractores: [`Ninguna de las anteriores`, `Ingreso permitido`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/20.png`,
          respuesta: `Prohibido virar en U o a la izquierda`,
          distractores: [`Prohibido virar en U o a la derecha`, `Prohibido virar a la derecha o a la izquierda`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/21.png`,
          respuesta: `Prohibido cambio de carril a la izquierda`,
          distractores: [`Prohibido virar en U o a la izquierda`, `Prohibido tomar la vía sinuosa`],
      
        },
        {
          pregunta: `¿Cuáles son las consecuencias de incumplimiento de esta señal 
          (prohibido estacionarse)?`,
          imagen: `/img/22.png`,
          respuesta: `Sanción con el 10% del salario básico unificado`,
          distractores: [`Pena privativa de libertad`, `Retiro de la licencia de conducir.`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/23.png`,
          respuesta: `Prohibido estacionar al lado izquierdo de la señal hasta la próxima 
          intersección`,
          distractores: [`Permitido estacionar a la izquierda hasta la próxima intersección`, `Prohibido estacionar a la derecha hasta la próxima intersección`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/24.png`,
          respuesta: `Prohibido estacionar al lado derecho de la señal hasta la próxima intersección`,
          distractores: [`Prohibición de estacionar a la izquierda hasta la próxima intersección`, `Estacionar a la derecha hasta la próxima intersección`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/25.png`,
          respuesta: `Prohibido estacionar a los dos lados de la señal hasta la próxima intersección a izquierda y derecha`,
          distractores: [`Estacionar a la derecha hasta la próxima intersección`, `Prohibición de estacionar a la izquierda`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/26.png`,
          respuesta: `Estacionamiento permitido`,
          distractores: [`Estacionamiento tarifado`, `Prohibición de estacionar`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/27.png`,
          respuesta: `Estacionamiento tarifado durante los días y horarios señalados`,
          distractores: [`Estacionamiento tarifado a excepción de los días y horarios definidos`, `Estacionamiento no tarifado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/28.png`,
          respuesta: `Estacionamiento reservado solo para personas con discapacidad`,
          distractores: [`Estacionamiento prohibido para personas con discapacidad`, `Estacionamiento reservado para vehículos grandes`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/29.png`,
          respuesta: `Estacionamiento en reservado solo para personas con discapacidad a los lados de la señal`,
          distractores: [`Estacionamiento en paralelo reservado para vehículos grandes`, `Estacionamiento en paralelo prohibido para personas con discapacidad`],
      
        },
        {
          pregunta: `¿Qué debe hacer el conductor de un vehículo particular frente a esta señal?`,
          imagen: `/img/30.png`,
          respuesta: `Abstenerse de estacionar o detener el vehículo en esta zona`,
          distractores: [`Detener o estacionar el vehículo en esta zona`, `Aumentar la velocidad o rebasar al bus`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/31.png`,
          respuesta: `Largo máximo de los vehículos que circulan por esa vía`,
          distractores: [`Ancho máximo de los vehículos que circulan por esa vía`, `Ancho de la vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/32.png`,
          respuesta: `Carril exclusivo para peatones y ciclistas`,
          distractores: [`Circular con precaución sobre ese carril ya que es compartido con peatones y ciclistas`, `Circular utilizando luces de estacionamiento`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/33.png`,
          respuesta: `Que el carril segregado exclusivo para ciclistas y peatones se termina`,
          distractores: [`Que el carril segregado exclusivo para ciclistas y peatones se termina e inicia en la siguiente intersección`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `¿Qué debe hacer al observar la siguiente señal regulatoria?`,
          imagen: `/img/34.png`,
          respuesta: `Ceder el paso a los conductores, peatones y ciclistas`,
          distractores: [`Ceder el paso solo a los conductores`, `Ceder el paso solo a los peatones`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/35.png`,
          respuesta: `Más adelante intersección en “Y”`,
          distractores: [`Más adelante vía lateral`, `Más adelante cruce de vías`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/36.png`,
          respuesta: `Aproximación a redondel`,
          distractores: [`Más adelante vire en U`, `Más adelante zona de derrumbes`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/37.png`,
          respuesta: `Más adelante circulación en doble vía (bidireccional)`,
          distractores: [`Más adelante circulación en una vía`, `Más adelante vire a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/38.png`,
          respuesta: `Más adelante curva abierta hacia el lado que indica la flecha`,
          distractores: [`Más adelante curva cerrada hacia la derecha`, `Más adelante curva y contra curva`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/39.png`,
          respuesta: `Más adelante curva y contra curva cerrada a la derecha en la dirección señalada`,
          distractores: [`Más adelante curva pronunciada a la derecha`, `Más adelante curva abierta a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/40.png`,
          respuesta: `Más adelante curva y contra curva abierta a la izquierda en la dirección señalada`,
          distractores: [`Más adelante curva pronunciada a la izquierda`, `Más adelante curva abierta a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/41.png`,
          respuesta: `Más adelante vía sinuosa a la izquierda`,
          distractores: [`Más adelante curva y contra curva cerradas a la izquierda`, `Más adelante curva pronunciada a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/42.png`,
          respuesta: `Más adelante curva en "U" a la izquierda`,
          distractores: [`Más adelante curva en "U" a la izquierda`, `Más adelante curva en "U" a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/43.png`,
          respuesta: `Más adelante bandas transversales de alerta o de retumbo`,
          distractores: [`Más adelante agua en la vía`, `Más adelante hondonada`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/44.png`,
          respuesta: `Más adelante reductor de velocidad`,
          distractores: [`Más adelante calzada irregular`, `Más adelante hondonada`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/45.png`,
          respuesta: `Más adelante angostamiento en la vía`,
          distractores: [`Más adelante pendiente peligrosa`, `Más adelante pendiente peligrosa`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/46.png`,
          respuesta: `Disminuir la velocidad antes de ingresar a la curva`,
          distractores: [`Acelerar`, `Mantener la misma velocidad en la que se encuentra circulando`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/47.png`,
          respuesta: `Aproximación a una curva cerrada en la dirección señalada`,
          distractores: [`Aproximación a una curva abierta a la derecha`, `Aproximación a una contra curva a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/48.png`,
          respuesta: `Aproximación a una vía lateral izquierda o derecha en curva`,
          distractores: [`Aproximación a un cruce de vías al virar a la izquierda`, `Aproximación a una curva cerrada a la izquierda`],
        },
        {
          pregunta: `Esta señal se utiliza para indicar:`,
          imagen: `/img/49.png`,
          respuesta: `Dirección y lugar por el cual el flujo de tránsito debe dejar la calzada normal y desviarse por otras calles`,
          distractores: [`Que el desvío se ha terminado y que el flujo del tránsito regresa por el desvío a la ruta original `, `La ubicación y el lugar por el cual el flujo de tránsito debe continuar la calzada normal`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/50.png`,
          respuesta: `Señal con lugar de destino y flecha de asignación de carril`,
          distractores: [`Señal con lugar de destino y mensaje de acciones en palabras`, `Señal con código de ruta, lugar de destino y mensaje de salida con distancia en kilómetros`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/51.png`,
          respuesta: `Zona Escolar`,
          distractores: [`La velocidad máxima en una zona donde se encuentran centros educativos`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/52.png`,
          respuesta: `Ambas`,
          distractores: [`Reducir la velocidad y conducir con precaución`, `La velocidad máxima de circulación en una zona escolar`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/53.png`,
          respuesta: `El inicio del paradero de una zona escolar`,
          distractores: [`Parada de vehículos pesados`, `Zona permitida para familiares de estudiantes`],
        },
        {
          pregunta: `La luz amarilla intermitente en un semáforo significa:`,
          respuesta: `Disminuir la velocidad y atravesar la intersección con mucha precaución`,
          distractores: [`Parar siempre y cuando lo pueda hacer con seguridad`, `Parar, ceda el paso a todo el tráfico que cruza antes de atravesar la intersección`],
      
        },
        {
          pregunta: `¿Las instrucciones del agente de tránsito prevalecen sobre las señales de tránsito aunque el semáforo se 
          encuentre en luz roja?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿En cuál de las siguientes luces de semáforo debería siempre detener su vehículo?`,
          respuesta: `Luces rojas y luces rojas intermitentes `,
          distractores: [`Luces rojas continuas, flechas rojas y luces amarillas intermitentes `, `Luces rojas continuas, luces rojas intermitentes y luces amarillas`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/54.png`,
          respuesta: `Motocicletas deben mantenerse en el carril derecho`,
          distractores: [`Motocicletas deben girar a la derecha`, `Permitido estacionarse motocicletas`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/55.png`,
          respuesta: `Prohibido el ingreso o circulación de vehículos motorizados`,
          distractores: [`Prohibida la circulación de buses`, `Prohibida la circulación de vehículos pesados`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/56.png`,
          respuesta: `Más adelante intersección en “T”`,
          distractores: [`Más adelante redondel`, `Más adelante cruce de tren`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/57.png`,
          respuesta: `Más adelante parterre termina`,
          distractores: [`Más adelante angostamiento de vía`, `Más adelante parterre comienza`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/58.png`,
          respuesta: `Más adelante parterre comienza`,
          distractores: [`Más adelante parterre termina`, `Más adelante angostamiento de vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/59.png`,
          respuesta: `Más adelante cruce de ganado`,
          distractores: [`Más adelante cruce de peatones`, `Más adelante cruce de maquinaria agrícola`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/60.png`,
          respuesta: `Aproximación a túnel`,
          distractores: [`Túnel obstruido`, `Trabajos en el túnel`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/61.png`,
          respuesta: `Más adelante vía sinuosa a la derecha`,
          distractores: [`Más adelante curva pronunciada a la derecha`, `Más adelante curva y contra curva abiertas a la derecha`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/62.png`,
          respuesta: `Más adelante vía compartida con ciclistas`,
          distractores: [`Más adelante solo vehículos livianos`, `Más adelante solo vehículos livianos`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/63.png`,
          respuesta: `Más adelante depresión en la vía`,
          distractores: [`Más adelante vía cerrada`, `Más adelante reductor de velocidad`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/64.png`,
          respuesta: `Más adelante puente angosto`,
          distractores: [`Más adelante pendiente peligrosa`, `Más adelante estrechamiento en la vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/65.png`,
          respuesta: `Límite máximo de velocidad`,
          distractores: [`Velocidad mínima de Km/h`, `Usted se encuentra en el Kilómetro 30`],
        },
        {
          pregunta: `La luz roja intermitente en un semáforo significa:`,
          respuesta: `Parar siempre`,
          distractores: [`Disminuir la velocidad y atravesar la intersección con mucha precaución`, `Parar, ceda el paso a todo el tráfico que cruza antes de atravesar la intersección`],
      
        },
        {
          pregunta: `La luz roja intermitente en una señal de PARE en el extremo lateral izquierdo de un transporte escolar, 
          ¿Qué significa?`,
          respuesta: `Prohibido adelantar o rebasar al transporte escolar, hasta que se apague la luz intermitente y se 
          guarde la señal de PARE`,
          distractores: [`Disminuir la velocidad y adelantar con precaución al transporte escolar`, `Que se encendió las luces de estacionamiento del transporte escolar`],
      
        },
        {
          pregunta: `La licencia digital es válida para circular en el Ecuador:`,
          respuesta: `Si`,
          distractores: [`Solo si está impresa`, `Ninguna de las anteriores`],
      
        },
      
        {
          pregunta: `Es válido portar una copia simple de la licencia o matrícula para circular en el Ecuador:`,
          respuesta: `No, nunca`,
          distractores: [`Solo si está impresa`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `Debo hacer un curso de conducción para obtener mi licencia de conductor en el Ecuador:`,
          respuesta: `Sí, siempre`,
          distractores: [`Solo si me pide el agente de tránsito`, `No`],
      
        },
        {
          pregunta: `El no cancelar el valor de multas no le permitirá: renovar la licencia, realizar la revisión técnica vehicular y 
          matricular su vehículo`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿El conductor que NO haya sido sancionado durante el tiempo de vigencia de la licencia de conducir y 
          mantenga los 30 (puntos), se beneficiará del 30% en el pago del valor de la especie de la licencia de 
          conducir?`,
          respuesta: `FALSO`,
          distractores: [`VERDADERO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Si conduce su vehículo con un nivel de alcohol por litro de sangre de 0,3 a 0,8; ¿Qué sanción será aplicada?`,
          respuesta: `Cinco días de privación de libertad y retención del vehículo por veinticuatro horas`,
          distractores: [`Treinta días de privación de libertad y retención del vehículo por veinticuatro horas`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `El conductor que falte de obra a la autoridad o agente de tránsito, ¿Será sancionado con pena 
          privativa de libertad de tres días?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
      
        {
          pregunta: `Si se queda sin combustible y por esa razón obstaculiza el tránsito vehicular, ¿Está cometiendo una 
          contravención?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Es una contravención de tránsito, la o el conductor que conduzca con licencia caducada?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor y pasajeros que no hagan uso del cinturón de seguridad, ¿Corresponde a una 
          contravención de transito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor que invada con su vehículo circulando o estacionándose, en las vías asignadas para uso 
          exclusivo de los ciclistas, ¿Corresponde a una contravención de tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Los conductores que conduzcan en estado de embriaguez o bajo los efectos de sustancias estupefacientes, 
          psicotrópicas o preparados que las contengan y del cual provoquen un siniestro del cual resulte la muerte, 
          ¿Qué sanción corresponde?`,
          respuesta: `Revocatoria definitiva de la licencia para conducir`,
          distractores: [`Pena privativa de la libertad de doce a veinte años`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `El conducir un vehículo cuyas llantas se encuentren lisas o en mal estado, ¿Será sancionado con pena 
          privativa de libertad de cinco a quince días?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `El conducir un vehículo con las luces no encendidas en horas de la noche o conduzca en sitios oscuros 
          como túneles con la luces apagadas, ¿Corresponde a una contravención de tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Cuál de los siguientes enunciados corresponde a una contravención de tránsito?`,
          respuesta: `La o el conductor que conduce sin mantener una distancia prudente de seguimiento respecto al 
          vehículo y ciclista de adelante `,
          distractores: [`La o el conductor que conduce con una distancia prudente de seguimiento respecto al vehículo de 
          adelante`, `La o el conductor que cede el paso y permite el adelantamiento de otros vehículos.`],
      
        },
        {
          pregunta: `La o el conductor de un vehículo automotor que circule con personas en los estribos o pisaderas, baldes de 
          camionetas, parachoques o colgados de las carrocerías de los vehículos , ¿Es una contravención de 
          transito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor de un vehículo particular que tenga vidrios con películas antisolares oscuras, polarizados 
          o cualquier tipo de adhesivo y que no tenga la debida autorización, ¿Corresponde a una contravención de 
          tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        
        {
          pregunta: `¿Qué indican las líneas blancas que se observan en la imagen?`,
          imagen: `/img/66.png`,
          respuesta: `Líneas de borde`,
          distractores: [`Línea de prohibición de estacionamiento`, `Línea de prohibición de estacionamiento`],
      
        },
        {
          pregunta: `¿Qué indican las líneas blancas que se observan en la imagen?`,
          imagen: `/img/67.png`,
          respuesta: `Indican a los conductores, especialmente en caso de poca 
          visibilidad, dónde se encuentra el borde de la calzada`,
          distractores: [`Indican a los conductores que es permitido el parqueo vehículos`, `Indican a los conductores que es permitido el parqueo vehículos`],
      
        },
        {
          pregunta: `¿Qué significan las líneas segmentadas pintadas de blanco en una 
          intersección, que se observan en la imagen ?`,
          imagen: `/img/68.png`,
          respuesta: `Cruce de ciclovías`,
          distractores: [`Cruce peatonal`, `Cruce de mascotas`],
      
        },
      
        {
          pregunta: `De acuerdo a la imagen, ¿Las señales reglamentarias indican la 
          dirección obligatoria en el carril que deben seguir los vehículos en la 
          próxima intersección?`,
          imagen: `/img/69.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`,`DESCONOCE`],
      
        },
      
      
        {
          pregunta: `¿Qué debe hacer el conductor cuando en la vía observe un cruce 
          peatonal demarcado tipo cebra como indica la imagen?`,
          imagen: `/img/70.png`,
          respuesta: `Debe ceder el paso a los peatones que se encuentran cruzando la vía`,
          distractores: [`Debe pitar para alertar al peatón que no baje de la vereda`, `Debe acelerar para pasar más rápido`],
      
        },
      
        {
          pregunta: `¿Qué debe hacer el conductor cuando se encuentre con una señal 
          horizontal pintada en la vía como se observa en la imagen?`,
          imagen: `/img/71.png`,
          respuesta: `Velocidad máxima de 40 km/h`,
          distractores: [`Velocidad mínima de 40 km/h`, `Velocidad mínima de 40 km/h`],
      
        },
        {
          pregunta: `De acuerdo a la imagen , ¿Qué significa la siguiente señal horizontal 
          pintada en la vía?`,
          imagen: `/img/72.png`,
          respuesta: `Prohibido estacionar`,
          distractores: [`Estacionar temporalmente cuando no exista un agente de tránsito`, `Estacionar temporalmente cuando no exista un agente de tránsito`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señalización horizontal?`,
          imagen: `/img/73.png`,
          respuesta: `Estacionamiento tarifado`,
          distractores: [`No estacionar`, `Estacionamiento gratuito`],
      
        },
      
        {
          pregunta: `El conductor cuando se encuentre en una intersección, ¿Debe detener 
          su vehículo, bicicleta y peatones completamente y dar el paso a 
          cualquier vehículo que se encuentre cruzando la intersección?`,
          imagen: `/img/74.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
      
        {
          pregunta: `Los vehículos A y B llegan al mismo tiempo a una intersección en cruz 
          por diferentes lados ¿Cuál tiene derecho de vía?`,
          imagen: `/img/75.png`,
          respuesta: `El vehículo que se aproxima a la derecha`,
          distractores: [`Cualquiera de los dos`, `El vehículo que se aproxima a la izquierda`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/76.png`,
          respuesta: `Giro a izquierda`,
          distractores: [`Saludo`, `Disminuir la velocidad`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/77.png`,
          respuesta: `Giro a derecha`,
          distractores: [`Saludo`, `Parar`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/78.png`,
          respuesta: `Estacionarse o detenerse`,
          distractores: [`Solicita ayuda`, `Giro a izquierda`],
      
        },
      
        {
          pregunta: `¿Qué significa el espacio de la berma?`,
          imagen: `/img/79.png`,
          respuesta: `Espacio exclusivo para circulación de peatones, ciclistas y 
          movilidad personal`,
          distractores: [`Estacionamiento`, `Carril para circulación de motos`],
      
        },
        {
          pregunta: `¿Qué es la movilidad activa?`,
          respuesta: `Es aquella que depende del movimiento físico de las personas, incluye la caminata el uso de la 
          bicicleta y movilidad personal`,
          distractores: [`Es aquella que depende del movimiento motorizado, incluye el transporte público`, `Ninguna de las anteriores`],
      
        },
      
        {
          pregunta: `¿Cuáles son derechos de los biciusuarios? `,
          respuesta: `Todas las anteriores`,
          distractores: [`Derecho preferente de vía o circulación en los desvíos de avenidas y carreteras, cruce de caminos 
          intersecciones no señalizadas y ciclovías`, `Disponer vías de circulación privilegiada dentro de las ciudades y en carreteras, como ciclovías y 
          espacios similares`],
      
        },
        {
          pregunta: `De acuerdo a la normativa, ¿Dónde tienen derecho a circular los ciclistas?`,
          respuesta: `Transitar por todas las vías del país con respeto y seguridad.`,
          distractores: [`En las aceras, con cuidado respectivo a los peatones`, `Únicamente en las sendas especiales destinadas al uso de bicicletas`],
      
        },
        {
          pregunta: `¿En qué momento los conductores son responsables de su seguridad, de los pasajeros y el resto de 
          usuarios viales?`,
          respuesta: `En todo momento`,
          distractores: [`Los conductores no son responsables de lo que el pasajero haga o deje de hacer en cuanto a su 
          seguridad`, `Solo cuando esté encendido el motor del vehículo `],
        },
        {
          pregunta: `¿Qué se entiende por vía pública?`,
          respuesta: `Espacio público destinado al libre tránsito vehicular, bicicletas y peatonal`,
          distractores: [`El lugar por donde circulan los vehículos`, `El lugar por donde circulan los peatones`],
        },
        {
          pregunta: `¿Cuáles son las obligaciones de los peatones en la vía pública?`,
          respuesta: `Cruzar por las esquinas o cruces peatonales en las vías primarías y vías secundarias con más 
          de dos carriles efectivos de circulación; en vías secundarias que cuenten con un máximo de dos 
          carriles efectivos de circulación podrán cruzar en cualquier punto; y siempre y cuando le sea posible 
          hacerlo de manera segura`,
          distractores: [`Solicitar transporte o pedir ayuda a los automovilistas en lugares apropiados o prohibidos `, `Cruzar la calle por la parte anterior y posterior de los automotores que se hayan detenido momentáneamente`],
        },
        {
          pregunta: `¿Cuáles son derechos de los peatones?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Tener libre circulación sobre las aceras y zonas peatonales exclusivas, tener preferencia de vía sobre 
          todas las intersecciones, inclusive en las que tienen semáforo cuando la luz verde peatonal esté 
          encendida, todo el tiempo en los pasos cebra y en las esquinas no reguladas por semáforos, procurando 
          su propia seguridad y la de los demás`, `Disponer de las vías públicas libres de obstáculos y no invadidas, y contar con infraestructura y 
          señalización vial adecuadas que les brinden seguridad, en especial para las personas pertenecientes a los 
          grupos de atención prioritaria`],
        },
        {
          pregunta: `Uno de los objetivos de la Ley de Transporte Terrestre Tránsito y Seguridad Vial, es:`,
          respuesta: `El establecimiento de programas de capacitación y difusión para conductores, peatones, pasajeros y 
          autoridades, en materia de seguridad vial, para la creación de una cultura y conciencia vial 
          responsable y solidaria`,
          distractores: [`Definir sus principios, garantizar el derecho a la educación superior de calidad que propenda a la 
          excelencia`, `Contribuir al desarrollo del País a través de la formulación de políticas, regulaciones, planes, programas 
          y proyectos, que garanticen el buen vivir`],
        },
        {
          pregunta: `¿Cómo se sanciona a los responsables de un siniestro de tránsito que no sean conductores (pasajeros, 
            controladores, peatones, etc.)?`,
          respuesta: `Con las mismas sanciones que un conductor, exceptuando la pérdida de puntos`,
          distractores: [`Con las mismas penas que a un conductor responsable del siniestro`, `Con la mitad de las penas establecidas para un conductor responsable de un siniestro `],
        },
        {
          pregunta: `¿Qué implica conducir con prudencia a pesar de las acciones incorrectas de otros conductores y de las 
          condiciones adversas del clima y las vías, practicando normas de seguridad para no provocar un siniestro 
          de tránsito?`,
          respuesta: `Conducción con precaución`,
          distractores: [`Conducir con rapidez`, `Cumplir con las normas de tránsito`],
        },
        {
          pregunta: `¿Qué implica la prevención de siniestros?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Evitar las conductas riesgosas : exceso de velocidad, consumo de alcohol, no uso de cinturón, casco o 
          sistemas de retención infantil, no respetar las señales de tránsito, manejo distraído`, `Desarrollar un sistema seguro de movilidad, lo cual implica infraestructura para la seguridad vial, control 
          y capacitación de los actores viales`],
        },
        {
          pregunta: `¿Cuáles son los objetivos de la educación vial?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Reducir de forma sistemática los siniestros de tránsito`, `Formar y capacitar a las personas en general para el uso correcto de todos los medios de transporte
          terrestre`],
        },
        {
          pregunta: `Los niños menores de 12 años podrán viajar en:`,
          respuesta: `En los asientos posteriores utilizando los sistemas de retención infantil de acuerdo a la edad y altura`,
          distractores: [`En el asiento delantero con cinturón de seguridad`, `En el asiento delantero sin cinturón de seguridad`],
        },
        {
          pregunta: `¿Para qué sirven los seguros a prueba de niños en los vehículos?`,
          respuesta: `Impiden que las puertas puedan abrirse desde el interior`,
          distractores: [`Bloquean las ventanas traseras manteniéndolas cerradas`, `Aseguran las hebillas de los cinturones de seguridad`],
        },
        {
          pregunta: `El dispositivo de seguridad pasiva que reduce el desplazamiento de la cabeza hacia atrás para evitar el 
          efecto latigazo cervical, se conoce como:`,
          respuesta: `Apoya cabeza`,
          distractores: [`Cinturón de seguridad`, `Airbag`],
        },
        {
          pregunta: `¿Cómo se conoce al dispositivo de seguridad pasiva incluida en algunos vehículos el cual se acciona en 
          milisegundos al producirse un impacto?`,
          respuesta: `Airbag o bolsa de aire`,
          distractores: [`Apoya cabeza`, `Cinturón de seguridad`],
        },
        {
          pregunta: `¿Cuál es la distancia mínima que debe mantener entre vehículo y ciclista al conducir?`,
          respuesta: `1,5 metros`,
          distractores: [`1,0 metros`, `Todas las anteriores`],
        },
        {
          pregunta: `¿Cuándo debo llevar el kit de emergencia o carretero en mi vehículo?`,
          respuesta: `Siempre`,
          distractores: [`Solo Triángulos`, `Solo en caso de emergencia`],
        },
        {
          pregunta: `En un paso cebra no regulado, ¿Quién tiene la preferencia de paso?`,
          respuesta: `Los Peatones`,
          distractores: [`Los vehículos en circulación`, `El transporte público`],
        },
        {
          pregunta: `En caso de avería mecánica en la vía, ¿Debo poner los triángulos o conos de seguridad?`,
          respuesta: `Si, siempre`,
          distractores: [`No es necesario`, `Solo en caso de haber agentes de tránsito`],
        },
        {
        pregunta: `¿A qué distancia mínima antes de una maniobra es obligatorio poner la respectiva luz intermitente?`,
        respuesta: `Mínimo 30 metros antes`,
        distractores: [`Cuando ejecuto la maniobra`, `Mínimo 10 metros antes`],
         },
        {
          pregunta: `En un siniestro de tránsito las autoridades encargadas del control del tránsito, ¿Pueden detener a las 
          personas involucradas?`,
          respuesta: `Pueden detenerlos y ponerlos a las órdenes del fiscal, en el caso donde resultaron personas lesionadas 
          o fallecidas`,
          distractores: [`No pueden detener a nadie hasta que tengan una orden 
          judicial`, `Solamente pueden detener a los vehículos`],
        },
        {
          pregunta: `Al momento de rebasar a un ciclista en circulación, ¿Qué distancia mínima debe tomar el vehículo?`,
          respuesta: `1,5 metros`,
          distractores: [`1 metro`, `50 centímetros`],
        },
        {
          pregunta: `¿Cuál es la distancia mínima de seguimiento que debe mantener entre vehículo y ciclista al conducir?`,
          respuesta: `3 metros`,
          distractores: [`1 metro`, `2 metros`],
        },
        {
          pregunta: `¿Qué debe realizar un conductor cuando ve que un bus escolar e institucional se detiene en una parada?`,
          respuesta: `Esperar y dar el paso a todos los peatones que se bajan del bus`,
          distractores: [`Acelerar la marcha para pasar al bus`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `En las carreteras, ¿Los conductores cuando circulan deben dar preferencia?`,
          respuesta: `Peatones, ciclistas, motociclistas y similares.`,
          distractores: [`Otros conductores`, `Otros conductores`],
        },
        {
          pregunta: `¿Qué se presume si un conductor se niega a realizarse pruebas para la detección de alcohol o de la 
          presencia de sustancias estupefacientes?`,
          respuesta: `Será considerada como presunción de estar en el máximo grado de intoxicación`,
          distractores: [`Solo en el día`, `No`],
        },
        {
          pregunta: `Cuando se conduzca un vehículo a motor y se requiera cruzar una acera para entrar o salir de un 
          estacionamiento, ¿quién tiene preferencia de paso?`,
          respuesta: `Peatones, ciclistas y vehículos`,
          distractores: [`El conductor que está saliendo`, `Solo los vehículos`],
        },
        {
          pregunta: `¿A qué velocidad se estima que un atropellamiento de un niño/a sería mortal?`,
          respuesta: `25km/h`,
          distractores: [`15km/h`, `10km/h`],
        },
        {
          pregunta: `¿A qué velocidad se estima que el atropellamiento a un adulto sería mortal?`,
          respuesta: `60km/h`,
          distractores: [`40km/h`, `20km/h`],
        },
        {
          pregunta: `En caso de que no exista señalización horizontal y/o vertical, ¿Quién tendría derecho de paso en la vía en
          caso de que los usuarios presentes incluyan peatones, ciclistas, vehículos livianos, carga pesada?`,
          respuesta: `Peatones`,
          distractores: [`Biciusuarios`, `Transporte público de pasajeros`],
        },
        {
          pregunta: `Si un peatón va a cruzar una intersección con luz verde del semáforo vehicular o una intersección no 
          regulada y un vehículo va girar, ¿Quién tiene la preferencia de paso?`,
          respuesta: `El peatón `,
          distractores: [`El auto`, `Los dos`],
        },
        {
          pregunta: `¿Qué son las zonas 30?`,
          respuesta: `Zona en donde la velocidad máxima es 30 km/h`,
          distractores: [`Es una zona peatonal`, `Zona en donde la velocidad mínima es 30km/h`],
        },
        {
          pregunta: `¿Por qué la movilidad activa es importante?`,
          respuesta: `Genera menor impacto ambiental, social y económico`,
          distractores: [`Ninguna de las anteriores`, `Está de moda`],
        },
        {
          pregunta: `¿Un pasajero de transporte público puede denunciar ante la autoridad de tránsito, cuando el conductor 
          esté en estado de ebriedad?`,
          respuesta: `Es su obligación`,
          distractores: [`Talvez`, `No`],
        },
        {
          pregunta: `¿En qué porcentaje disminuyen las probabilidades de muerte para un motociclista o ciclista durante un 
          siniestro si utiliza casco homologado?`,
          respuesta: `39%`,
          distractores: [`No disminuyen, aumentan`, `1%`],
        },
    
    //*PREGUNTAS ESPECIFICAS AQUI*//
    
    
      {
        pregunta: `La persona que conduzca un vehículo de transporte público con daños mecánicos previsibles y 
        como resultado de ello ponga en peligro la seguridad de los pasajeros, será sancionada con:`,
        respuesta: `Todas las anteriores`,
        distractores: [`Dos al año`, `Cada quince días`],
    
      },
    
    
      {
        pregunta: `¿Cuál es el límite máximo de velocidad en el que debe circular un vehículo de transporte
    público de pasajeros en una vía urbana?`,
        respuesta: `40 Km/h`,
        distractores: [`50 Km/h`, `60 Km/h`],
    
      },
    
      {
        pregunta: `En una vía perimetral, ¿cuál es el límite máximo de velocidad en el que debe circular un
    vehículo de transporte público de pasajeros?`,
        respuesta: `70 Km/h`,
        distractores: [`60 Km/h`, `100 Km/h`],
    
      },
    
      {
        pregunta: `En vías rectas, ¿cuál es el límite máximo de velocidad en el que debe circular un vehículo
    de transporte público de pasajeros?`,
        respuesta: `90 Km/h`,
        distractores: [`110 Km/h`, `115 Km/h`],
    
      },
    
      {
        pregunta: `¿Las licencias de conducir otorgadas mediante acto viciado por o por falta de
    cumplimiento de sus requisitos serán:`,
        respuesta: `Anuladas`,
        distractores: [`Suspendidas`, `Todas las anteriores`],
    
      },
    
      {
        pregunta: `¿Cuál de los siguientes enunciados corresponde a una contravención de tránsito ?`,
        respuesta: `La o el conductor de un vehículo de transporte público masivo de pasajeros que
    cargue combustible cuando se encuentren prestando el servicio de transporte`,
        distractores: [`La o el conductor de un vehículo de transporte público masivo de pasajeros que cargue
    combustible cuando no se encuentren prestando el servicio de transporte`, `La o el conductor de un vehículo de transporte público masivo de pasajeros que cargue
    combustible mientras el vehículo se encuentre en proceso de mantenimiento.`],
    
      },
    
    
      {
        pregunta: `¿Cuál de los siguientes enunciados corresponde a una contravención de tránsito?`,
        respuesta: `La o el conductor de transporte público de servicio masivo que incumpla las tarifas
    preferenciales fijadas por la ley en beneficio de los niños, estudiantes, personas adultas
    mayores de sesenta y cinco años de edad y personas con capacidades especiales`,
        distractores: [`La o el conductor de transporte público de servicio masivo que cumpla las tarifas preferenciales
    fijadas por la ley en beneficio de los niños, estudiantes, personas adultas mayores de sesenta y
    cinco años de edad y personas con capacidades especiales`, `La o el conductor de transporte público de servicio masivo que no entregue el boleto
    correspondiente a las personas que gozan de tarifas preferenciales fijadas por la ley en beneficio de
    los niños, estudiantes, personas adultas mayores de sesenta y cinco años de edad y personas con
    capacidades especiales`],
    
      },
    
    
      {
        pregunta: `¿Qué grupos de pasajeros gozarán de atención preferencial?`,
        respuesta: `Todos los anteriores`,
        distractores: [`Mujeres embarazadas y personas de la tercera edad`, `Niñas, niños y adolescentes`],
    
      },
    
      {
        pregunta: `¿Cuál de los siguientes enunciados corresponde a una contravención de tránsito?`,
        respuesta: `Conducir un vehículo sin portar la licencia de conducir`,
        distractores: [`Conducir un vehículo con menos de 15 puntos en la licencia de conducir`, `Conducir un vehículo con menos de 1 punto en la licencia de conducir`],
    
      },
    
      {
        pregunta: `¿Cuál es la tarifa que deberá ser cancelada para que sean transportadas las sillas de
    ruedas, coches para bebes, u otros equipos que requieren las personas con atención
    preferente en el sistema de transporte terrestre?`,
        respuesta: `Ningún valor adicional`,
        distractores: [`el valor de un pasaje adicional`, `El valor de medio pasaje adicional`],
    
      },
    
      {
        pregunta: `El servicio de Transporte Intracantonal es aquel que:`,
        respuesta: `Opera dentro de los límites cantonales, pudiendo ser un servicio urbano (entre
    parroquias urbanas) o un servicio rural (entre parroquias rurales)`,
        distractores: [`Opera entre las provincias que conforman una misma región`, `Se presta dentro de los limites provinciales entre cantones`],
    
      },
    
      {
        pregunta: `El servicio de Transporte Intraprovincial es aquel que:`,
        respuesta: `Se presta dentro de los límites provinciales, entre cantones`,
        distractores: [`Opera dentro de los límites cantonales, pudiendo ser un servicio urbano (entre parroquias
    urbanas) o un servicio rural (entre parroquias rurales)`, `Opera entre las provincias que conforman una misma región`],
    
      },
    
      {
        pregunta: `El servicio de Transporte Internacional es aquel que:`,
        respuesta: `Se presta fuera de los límites del país, teniendo como origen el territorio nacional y
    como destino un país extranjero o viceversa`,
        distractores: [`Se presta dentro de los límites provinciales entre cantones`, `Opera entre las provincias que conforman una misma región`],
    
      },
    
      {
        pregunta: `El servicio de Transporte Interprovincial es aquel que:`,
        respuesta: `Se presta dentro de los límites del territorio nacional, entre provincias de diferentes
    regiones, o entre provincias de una región y las provincias del resto del país o viceversa`,
        distractores: [`Opera dentro de los límites cantonales, pudiendo ser un servicio urbano (entre parroquias
    urbanas) o un servicio rural (entre parroquias rurales)`, `Opera entre las provincias que conforman una misma región`],
    
      },
    
      {
        pregunta: `¿Qué se entiende por ruta o línea de servicio de transporte público?`,
        respuesta: `Designación del origen, puntos intermedios y destino de un itinerario sobre el que se
    desplazan las unidades de la operadora, y cuenta con un punto de partida y de llegada.`,
        distractores: [`La autorización de una operadora para prestar servicios de transporte público`, `Conjunto de sitios específicos y claramente identificados para la subida y descenso de los
    usuarios del transporte público`],
    
      },
    
      {
        pregunta: `¿Qué vehículos están exentos de la obligación de tener cinturones de seguridad para
    los ocupantes?`,
        respuesta: `Ningún vehículo se encuentra exento de esta obligación`,
        distractores: [`Los vehículos destinados a turismo para los pasajeros, excepto el conductor`, `Los buses de transporte intracantonal para los pasajeros, excepto el conductor`],
    
      },
    
      {
        pregunta: `¿Qué no debe hacer el conductor del transporte público de pasajeros?`,
        respuesta: `Dejar y recoger pasajeros en paradas no autorizadas`,
        distractores: [`Llevar menos pasajeros de la capacidad autorizada`, `Todas las anteriores`],
    
      },
    
      {
        pregunta: `La vigencia de un contrato de operación será de:`,
        respuesta: `10 años`,
        distractores: [`5 años`, `8 años`],
    
      },
    
      {
        pregunta: `El documento que contiene la relación de pasajeros transportados por viaje se conoce
    como:`,
        respuesta: `Manifiesto de pasajeros`,
        distractores: [`Ticket o boleto`, `Hoja de ruta`],
    
      },
      {
        pregunta: `Los conductores profesionales que desean acceder al cambio de categoría de la
    licencia de conducir deberán tener:`,
        respuesta: `Asistir, aprobar y obtener el tipo correspondiente que acredite su capacitación.`,
        distractores: [`Tener al menos 1 años de la licencia de conducir en la categoría no profesional tipo B`, `Todas la anteriores`],
    
      },
    
      {
        pregunta: `La persona que conduzca un vehículo de transporte público y cuyas llantas se
    encuentren lisas o en mal estado, será sancionada con:`,
        respuesta: `Pena privativa de libertad de cinco a treinta días`,
        distractores: [`Pena privativa de libertad de cinco a quince días`, `Ninguna de las anteriores`],
    
      },
    
    {
        pregunta: `Para las Operadoras, constituye una infracción administrativa grave:`,
        respuesta: `Ofrecer servicios de transporte y carga distintos a los autorizados o permitidos dentro del título 
        habilitante otorgado`,
        distractores: [`No proveer información solicitada por la Agencia Nacional de Regulación y Control del Transporte 
        Terrestre, Tránsito y Seguridad Vial o los Gobiernos Autónomos Descentralizados, dentro del ámbito 
        de sus competencias, en el término de cuatro días, que sea necesaria para que estos organismos 
        puedan ejercer sus funciones de planificar, evaluar, regular las actividades de transporte`, `No acatar las disposiciones legales, reglamentarias, administrativas o contractuales vigentes o las 
        que norme la Agencia Nacional de Regulación y Control del Transporte Terrestre, Tránsito y Seguridad 
        Vial
        `],
    
      },
    
    {
        pregunta: `Para las Operadoras, constituye una infracción administrativa muy grave:`,
        respuesta: `Mantener vinculación contractual o laboral con conductores que hayan perdido todos
    los puntos de su licencia, que conduzcan con licencia revocada, suspendida o anulada,
    o que no cumplan con los requisitos legales establecidos para la conducción de los
    vehículos habilitados para la operadora`,
        distractores: [`Operar sin cumplir con las normas de protección ambiental expedidas por la autoridad
    competente`, `Incurrir en la prohibición de difusión de contenidos conforme al artículo 4.1 de la Ley Orgánica de
    Transporte Terrestre, Tránsito y Seguridad Vial`],
    
      },
    
      {
        pregunta: `¿Qué contenido audiovisual se deben transmitir en los medios de transporte público?`,
        respuesta: `Que promuevan el turismo, cultura de paz, la inclusión, la no discriminación.`,
        distractores: [`La intolerancia política o religiosa`, `Ninguna de las anteriores`],
    
      },
    
      {
        pregunta: `¿Cuál de los siguientes enunciados corresponde a una contravención de tránsito?`,
        respuesta: `La o el conductor de transporte público o comercial que se niegue a brindar el servicio`,
        distractores: [`La o el conductor de transporte público o comercial que brinde un servicio diferenciado`, `Ninguna de las anteriores`],
    
      },
    
      {
        pregunta: `En caso de que el vehículo con el que se ocasionó un accidente preste un servicio
    público, se sancionará a:`,
        respuesta: `Al conductor, al dueño del vehículo y a la operadora`,
        distractores: [`El conductor del vehículo`, `A la operadora y al dueño del vehículo`],
    
      },
    
      {
        pregunta: `Las personas con discapacidad pagarán una tarifa preferencial de:`,
        respuesta: `Cincuenta por ciento (50%) de la tarifa regular autorizada`,
        distractores: [`Veinticinco por ciento (25%) de la tarifa regular autorizada`, `Todas las anteriores`],
    
      },
    
      {
        pregunta: `¿Con qué tipo de licencia una persona puede conducir vehículos de 4 ruedas o más,
    diseñados para el transporte público o del Estado con una capacidad de más de 26
    asientos?`,
        respuesta: `Profesional tipo D`,
        distractores: [`No profesional tipo D1`, `Profesional tipo E`],
    
      },
    
      {
        pregunta: `Se establecen como paradas autorizadas las siguientes:`,
        respuesta: `Todas las anteriores`,
        distractores: [`Las establecidas en los Terminales Terrestres`, `Las autorizadas en los títulos habilitantes`],
    
      }
      
      
    ],
    E: [
        {
          pregunta: `El no acatar lo indicado en una señal de tránsito, ¿Es una infracción?`,
          respuesta: `VERDADERO`,
          distractores: [`DESCONOCE`, `FALSO`],
      
        },
        {
          pregunta: `En un semáforo con giro a la derecha o izquierda, si el conductor tiene el semáforo en verde, al igual que los peatones, ¿Quién tiene la preferencia?`,
          imagen: `/img/1.png`,
          respuesta: `Los peatones`,
          distractores: [`Los vehículos`, `Ambos`],
      
        },
        {
          pregunta: `Cuándo un cruce de vía está regulado por un agente de tránsito, ¿Las señales manuales de éste prevalecen sobre cualquier otra existente, incluso semáforos?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Una línea continua pintada en la calzada de color blanco o amarillo restringe la circulación vehicular de tal manera que ningún vehículo podrá cruzar como se observa en la imagen?`,
          imagen: `/img/2.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Una línea discontinua de color amarillo o blanco pintada en la
          calzada, de acuerdo a la imagen ¿Que indica?`,
          imagen: `/img/3.png`,
          respuesta: `Permite rebasar o adelantar sobre esta línea, siempre que exista 
          seguridad para hacerlo`,
          distractores: [`El conductor no puede realizar maniobras de adelantamiento 
          cuando la línea es discontinua`, `Restringe la circulación vehicular de tal manera que ningún 
          vehículo podrá cruzar esta línea`],
      
        },
        {
          pregunta: `Cuando vea pintado en la calzada una línea mixta, con la línea 
          continua a la izquierda del conductor, de acuerdo a la imagen ¿Qué 
          indica esta línea?`,
          imagen: `/img/4.png`,
          respuesta: `El conductor no puede realizar maniobras de adelantamiento 
    cuando tiene la línea continua a su izquierda`,
          distractores: [`El conductor no puede realizar maniobras de adelantamiento 
    cuando tiene la línea continua a su izquierda
    `, `Puede realizar maniobras de adelantamiento cuando tiene la línea 
    discontinua a la izquierda del conductor`],
      
        },
        {
          pregunta: `Cuando ve pintado de color blanco o amarillo en la calzada doble línea 
          continua ¿Qué indica?`,
          imagen: `/img/5.png`,
          respuesta: `Están totalmente prohibidas las maniobras para rebasar o 
          adelantar por cualquiera de los carriles que se transite para evitar 
          choques`,
          distractores: [`Puede realizar maniobras de adelantamiento cuando tiene la línea 
    discontinua a la izquierda del conductor`, `Le permite al conductor rebasar o adelantar, siempre que exista la 
          seguridad de hacerlo`],
      
        },
        {
          pregunta: `De acuerdo a la imagen, ¿Qué debe hacer si compararte carril con un 
          ciclista?`,
          imagen: `/img/6.png`,
          respuesta: `Ambas`,
          distractores: [`Da prioridad a ciclista en el carril compartido`, `Reduce a velocidad y comparte la vía con el ciclista`],
      
        },
        {
          pregunta: `¿Esta señal se utiliza para indicar?`,
          imagen: `/img/7.png`,
          respuesta: `Obligación de los conductores circular solo en la dirección 
          indicada
          `,
          distractores: [`Un solo carril`, `Doble vía`],
      
        },
        {
          pregunta: `De acuerdo a la imagen esta señal indica, No exceder de 30 km/h`,
          imagen: `/img/8.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `De acuerdo a la imagen, ¿esta señal indica prohibido el cambio de 
          carril?`,
          imagen: `/img/9.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Qué debe hacer cuando usted observa esta señal?`,
          imagen: `/img/10.png`,
          respuesta: `Detenerse antes de la línea de pare y ceder el paso a todo tránsito`,
          distractores: [`Detenerse antes de la línea de pare`, `Reducir la velocidad y estar preparado para detenerse solamente si 
    hay peligro de colisión con otro vehículo o peatón`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/11.png`,
          respuesta: `Viraje prohibido al sentido de la flecha`,
          distractores: [`Viraje permitido en el sentido de la flecha`, `Viraje seguro`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/12.png`,
          respuesta: `No girar en "U"`,
          distractores: [`No virar a la izquierda`, `Tener cuidado con el tráfico que vira`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/13.png`,
          respuesta: `Tránsito pesado debe circular por la derecha`,
          distractores: [`Estacionamiento permitido para camiones`, `Circulación solo tránsito pesado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/14.png`,
          respuesta: `Prohibido circular vehículos pesados`,
          distractores: [`Prohibido circular vehículos pesados`, `Solo tránsito pesado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/15.png`,
          respuesta: `Prohibido adelantar o rebasar`,
          distractores: [`Solo tránsito liviano`, `Prohibido doble columna`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/16.png`,
          respuesta: `Vehículos livianos deben mantener su derecha`,
          distractores: [`Vehículos livianos deben girar a la derecha`, `Permitido estacionarse vehículos livianos`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/17.png`,
          respuesta: `Comienza doble vía, pueden venir vehículos en sentido contrario. 
          Precaución`,
          distractores: [`Cambio de carril`, `No entre`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/18.png`,
          respuesta: `Prohibido estacionarse o detenerse`,
          distractores: [`Prohibido estacionarse`, `Permite detenerse por un minuto solamente`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/19.png`,
          respuesta: `No entre`,
          distractores: [`Ninguna de las anteriores`, `Ingreso permitido`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/20.png`,
          respuesta: `Prohibido virar en U o a la izquierda`,
          distractores: [`Prohibido virar en U o a la derecha`, `Prohibido virar a la derecha o a la izquierda`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/21.png`,
          respuesta: `Prohibido cambio de carril a la izquierda`,
          distractores: [`Prohibido virar en U o a la izquierda`, `Prohibido tomar la vía sinuosa`],
      
        },
        {
          pregunta: `¿Cuáles son las consecuencias de incumplimiento de esta señal 
          (prohibido estacionarse)?`,
          imagen: `/img/22.png`,
          respuesta: `Sanción con el 10% del salario básico unificado`,
          distractores: [`Pena privativa de libertad`, `Retiro de la licencia de conducir.`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/23.png`,
          respuesta: `Prohibido estacionar al lado izquierdo de la señal hasta la próxima 
          intersección`,
          distractores: [`Permitido estacionar a la izquierda hasta la próxima intersección`, `Prohibido estacionar a la derecha hasta la próxima intersección`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/24.png`,
          respuesta: `Prohibido estacionar al lado derecho de la señal hasta la próxima intersección`,
          distractores: [`Prohibición de estacionar a la izquierda hasta la próxima intersección`, `Estacionar a la derecha hasta la próxima intersección`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/25.png`,
          respuesta: `Prohibido estacionar a los dos lados de la señal hasta la próxima intersección a izquierda y derecha`,
          distractores: [`Estacionar a la derecha hasta la próxima intersección`, `Prohibición de estacionar a la izquierda`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/26.png`,
          respuesta: `Estacionamiento permitido`,
          distractores: [`Estacionamiento tarifado`, `Prohibición de estacionar`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/27.png`,
          respuesta: `Estacionamiento tarifado durante los días y horarios señalados`,
          distractores: [`Estacionamiento tarifado a excepción de los días y horarios definidos`, `Estacionamiento no tarifado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/28.png`,
          respuesta: `Estacionamiento reservado solo para personas con discapacidad`,
          distractores: [`Estacionamiento prohibido para personas con discapacidad`, `Estacionamiento reservado para vehículos grandes`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/29.png`,
          respuesta: `Estacionamiento en reservado solo para personas con discapacidad a los lados de la señal`,
          distractores: [`Estacionamiento en paralelo reservado para vehículos grandes`, `Estacionamiento en paralelo prohibido para personas con discapacidad`],
      
        },
        {
          pregunta: `¿Qué debe hacer el conductor de un vehículo particular frente a esta señal?`,
          imagen: `/img/30.png`,
          respuesta: `Abstenerse de estacionar o detener el vehículo en esta zona`,
          distractores: [`Detener o estacionar el vehículo en esta zona`, `Aumentar la velocidad o rebasar al bus`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/31.png`,
          respuesta: `Largo máximo de los vehículos que circulan por esa vía`,
          distractores: [`Ancho máximo de los vehículos que circulan por esa vía`, `Ancho de la vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/32.png`,
          respuesta: `Carril exclusivo para peatones y ciclistas`,
          distractores: [`Circular con precaución sobre ese carril ya que es compartido con peatones y ciclistas`, `Circular utilizando luces de estacionamiento`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/33.png`,
          respuesta: `Que el carril segregado exclusivo para ciclistas y peatones se termina`,
          distractores: [`Que el carril segregado exclusivo para ciclistas y peatones se termina e inicia en la siguiente intersección`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `¿Qué debe hacer al observar la siguiente señal regulatoria?`,
          imagen: `/img/34.png`,
          respuesta: `Ceder el paso a los conductores, peatones y ciclistas`,
          distractores: [`Ceder el paso solo a los conductores`, `Ceder el paso solo a los peatones`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/35.png`,
          respuesta: `Más adelante intersección en “Y”`,
          distractores: [`Más adelante vía lateral`, `Más adelante cruce de vías`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/36.png`,
          respuesta: `Aproximación a redondel`,
          distractores: [`Más adelante vire en U`, `Más adelante zona de derrumbes`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/37.png`,
          respuesta: `Más adelante circulación en doble vía (bidireccional)`,
          distractores: [`Más adelante circulación en una vía`, `Más adelante vire a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/38.png`,
          respuesta: `Más adelante curva abierta hacia el lado que indica la flecha`,
          distractores: [`Más adelante curva cerrada hacia la derecha`, `Más adelante curva y contra curva`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/39.png`,
          respuesta: `Más adelante curva y contra curva cerrada a la derecha en la dirección señalada`,
          distractores: [`Más adelante curva pronunciada a la derecha`, `Más adelante curva abierta a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/40.png`,
          respuesta: `Más adelante curva y contra curva abierta a la izquierda en la dirección señalada`,
          distractores: [`Más adelante curva pronunciada a la izquierda`, `Más adelante curva abierta a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/41.png`,
          respuesta: `Más adelante vía sinuosa a la izquierda`,
          distractores: [`Más adelante curva y contra curva cerradas a la izquierda`, `Más adelante curva pronunciada a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/42.png`,
          respuesta: `Más adelante curva en "U" a la izquierda`,
          distractores: [`Más adelante curva en "U" a la izquierda`, `Más adelante curva en "U" a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/43.png`,
          respuesta: `Más adelante bandas transversales de alerta o de retumbo`,
          distractores: [`Más adelante agua en la vía`, `Más adelante hondonada`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/44.png`,
          respuesta: `Más adelante reductor de velocidad`,
          distractores: [`Más adelante calzada irregular`, `Más adelante hondonada`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/45.png`,
          respuesta: `Más adelante angostamiento en la vía`,
          distractores: [`Más adelante pendiente peligrosa`, `Más adelante pendiente peligrosa`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/46.png`,
          respuesta: `Disminuir la velocidad antes de ingresar a la curva`,
          distractores: [`Acelerar`, `Mantener la misma velocidad en la que se encuentra circulando`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/47.png`,
          respuesta: `Aproximación a una curva cerrada en la dirección señalada`,
          distractores: [`Aproximación a una curva abierta a la derecha`, `Aproximación a una contra curva a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/48.png`,
          respuesta: `Aproximación a una vía lateral izquierda o derecha en curva`,
          distractores: [`Aproximación a un cruce de vías al virar a la izquierda`, `Aproximación a una curva cerrada a la izquierda`],
        },
        {
          pregunta: `Esta señal se utiliza para indicar:`,
          imagen: `/img/49.png`,
          respuesta: `Dirección y lugar por el cual el flujo de tránsito debe dejar la calzada normal y desviarse por otras calles`,
          distractores: [`Que el desvío se ha terminado y que el flujo del tránsito regresa por el desvío a la ruta original `, `La ubicación y el lugar por el cual el flujo de tránsito debe continuar la calzada normal`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/50.png`,
          respuesta: `Señal con lugar de destino y flecha de asignación de carril`,
          distractores: [`Señal con lugar de destino y mensaje de acciones en palabras`, `Señal con código de ruta, lugar de destino y mensaje de salida con distancia en kilómetros`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/51.png`,
          respuesta: `Zona Escolar`,
          distractores: [`La velocidad máxima en una zona donde se encuentran centros educativos`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/52.png`,
          respuesta: `Ambas`,
          distractores: [`Reducir la velocidad y conducir con precaución`, `La velocidad máxima de circulación en una zona escolar`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/53.png`,
          respuesta: `El inicio del paradero de una zona escolar`,
          distractores: [`Parada de vehículos pesados`, `Zona permitida para familiares de estudiantes`],
        },
        {
          pregunta: `La luz amarilla intermitente en un semáforo significa:`,
          respuesta: `Disminuir la velocidad y atravesar la intersección con mucha precaución`,
          distractores: [`Parar siempre y cuando lo pueda hacer con seguridad`, `Parar, ceda el paso a todo el tráfico que cruza antes de atravesar la intersección`],
      
        },
        {
          pregunta: `¿Las instrucciones del agente de tránsito prevalecen sobre las señales de tránsito aunque el semáforo se 
          encuentre en luz roja?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿En cuál de las siguientes luces de semáforo debería siempre detener su vehículo?`,
          respuesta: `Luces rojas y luces rojas intermitentes `,
          distractores: [`Luces rojas continuas, flechas rojas y luces amarillas intermitentes `, `Luces rojas continuas, luces rojas intermitentes y luces amarillas`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/54.png`,
          respuesta: `Motocicletas deben mantenerse en el carril derecho`,
          distractores: [`Motocicletas deben girar a la derecha`, `Permitido estacionarse motocicletas`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/55.png`,
          respuesta: `Prohibido el ingreso o circulación de vehículos motorizados`,
          distractores: [`Prohibida la circulación de buses`, `Prohibida la circulación de vehículos pesados`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/56.png`,
          respuesta: `Más adelante intersección en “T”`,
          distractores: [`Más adelante redondel`, `Más adelante cruce de tren`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/57.png`,
          respuesta: `Más adelante parterre termina`,
          distractores: [`Más adelante angostamiento de vía`, `Más adelante parterre comienza`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/58.png`,
          respuesta: `Más adelante parterre comienza`,
          distractores: [`Más adelante parterre termina`, `Más adelante angostamiento de vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/59.png`,
          respuesta: `Más adelante cruce de ganado`,
          distractores: [`Más adelante cruce de peatones`, `Más adelante cruce de maquinaria agrícola`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/60.png`,
          respuesta: `Aproximación a túnel`,
          distractores: [`Túnel obstruido`, `Trabajos en el túnel`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/61.png`,
          respuesta: `Más adelante vía sinuosa a la derecha`,
          distractores: [`Más adelante curva pronunciada a la derecha`, `Más adelante curva y contra curva abiertas a la derecha`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/62.png`,
          respuesta: `Más adelante vía compartida con ciclistas`,
          distractores: [`Más adelante solo vehículos livianos`, `Más adelante solo vehículos livianos`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/63.png`,
          respuesta: `Más adelante depresión en la vía`,
          distractores: [`Más adelante vía cerrada`, `Más adelante reductor de velocidad`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/64.png`,
          respuesta: `Más adelante puente angosto`,
          distractores: [`Más adelante pendiente peligrosa`, `Más adelante estrechamiento en la vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/65.png`,
          respuesta: `Límite máximo de velocidad`,
          distractores: [`Velocidad mínima de Km/h`, `Usted se encuentra en el Kilómetro 30`],
        },
        {
          pregunta: `La luz roja intermitente en un semáforo significa:`,
          respuesta: `Parar siempre`,
          distractores: [`Disminuir la velocidad y atravesar la intersección con mucha precaución`, `Parar, ceda el paso a todo el tráfico que cruza antes de atravesar la intersección`],
      
        },
        {
          pregunta: `La luz roja intermitente en una señal de PARE en el extremo lateral izquierdo de un transporte escolar, 
          ¿Qué significa?`,
          respuesta: `Prohibido adelantar o rebasar al transporte escolar, hasta que se apague la luz intermitente y se 
          guarde la señal de PARE`,
          distractores: [`Disminuir la velocidad y adelantar con precaución al transporte escolar`, `Que se encendió las luces de estacionamiento del transporte escolar`],
      
        },
        {
          pregunta: `La licencia digital es válida para circular en el Ecuador:`,
          respuesta: `Si`,
          distractores: [`Solo si está impresa`, `Ninguna de las anteriores`],
      
        },
      
        {
          pregunta: `Es válido portar una copia simple de la licencia o matrícula para circular en el Ecuador:`,
          respuesta: `No, nunca`,
          distractores: [`Solo si está impresa`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `Debo hacer un curso de conducción para obtener mi licencia de conductor en el Ecuador:`,
          respuesta: `Sí, siempre`,
          distractores: [`Solo si me pide el agente de tránsito`, `No`],
      
        },
        {
          pregunta: `El no cancelar el valor de multas no le permitirá: renovar la licencia, realizar la revisión técnica vehicular y 
          matricular su vehículo`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿El conductor que NO haya sido sancionado durante el tiempo de vigencia de la licencia de conducir y 
          mantenga los 30 (puntos), se beneficiará del 30% en el pago del valor de la especie de la licencia de 
          conducir?`,
          respuesta: `FALSO`,
          distractores: [`VERDADERO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Si conduce su vehículo con un nivel de alcohol por litro de sangre de 0,3 a 0,8; ¿Qué sanción será aplicada?`,
          respuesta: `Cinco días de privación de libertad y retención del vehículo por veinticuatro horas`,
          distractores: [`Treinta días de privación de libertad y retención del vehículo por veinticuatro horas`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `El conductor que falte de obra a la autoridad o agente de tránsito, ¿Será sancionado con pena 
          privativa de libertad de tres días?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
      
        {
          pregunta: `Si se queda sin combustible y por esa razón obstaculiza el tránsito vehicular, ¿Está cometiendo una 
          contravención?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Es una contravención de tránsito, la o el conductor que conduzca con licencia caducada?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor y pasajeros que no hagan uso del cinturón de seguridad, ¿Corresponde a una 
          contravención de transito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor que invada con su vehículo circulando o estacionándose, en las vías asignadas para uso 
          exclusivo de los ciclistas, ¿Corresponde a una contravención de tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Los conductores que conduzcan en estado de embriaguez o bajo los efectos de sustancias estupefacientes, 
          psicotrópicas o preparados que las contengan y del cual provoquen un siniestro del cual resulte la muerte, 
          ¿Qué sanción corresponde?`,
          respuesta: `Revocatoria definitiva de la licencia para conducir`,
          distractores: [`Pena privativa de la libertad de doce a veinte años`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `El conducir un vehículo cuyas llantas se encuentren lisas o en mal estado, ¿Será sancionado con pena 
          privativa de libertad de cinco a quince días?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `El conducir un vehículo con las luces no encendidas en horas de la noche o conduzca en sitios oscuros 
          como túneles con la luces apagadas, ¿Corresponde a una contravención de tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Cuál de los siguientes enunciados corresponde a una contravención de tránsito?`,
          respuesta: `La o el conductor que conduce sin mantener una distancia prudente de seguimiento respecto al 
          vehículo y ciclista de adelante `,
          distractores: [`La o el conductor que conduce con una distancia prudente de seguimiento respecto al vehículo de 
          adelante`, `La o el conductor que cede el paso y permite el adelantamiento de otros vehículos.`],
      
        },
        {
          pregunta: `La o el conductor de un vehículo automotor que circule con personas en los estribos o pisaderas, baldes de 
          camionetas, parachoques o colgados de las carrocerías de los vehículos , ¿Es una contravención de 
          transito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor de un vehículo particular que tenga vidrios con películas antisolares oscuras, polarizados 
          o cualquier tipo de adhesivo y que no tenga la debida autorización, ¿Corresponde a una contravención de 
          tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        
        {
          pregunta: `¿Qué indican las líneas blancas que se observan en la imagen?`,
          imagen: `/img/66.png`,
          respuesta: `Líneas de borde`,
          distractores: [`Línea de prohibición de estacionamiento`, `Línea de prohibición de estacionamiento`],
      
        },
        {
          pregunta: `¿Qué indican las líneas blancas que se observan en la imagen?`,
          imagen: `/img/67.png`,
          respuesta: `Indican a los conductores, especialmente en caso de poca 
          visibilidad, dónde se encuentra el borde de la calzada`,
          distractores: [`Indican a los conductores que es permitido el parqueo vehículos`, `Indican a los conductores que es permitido el parqueo vehículos`],
      
        },
        {
          pregunta: `¿Qué significan las líneas segmentadas pintadas de blanco en una 
          intersección, que se observan en la imagen ?`,
          imagen: `/img/68.png`,
          respuesta: `Cruce de ciclovías`,
          distractores: [`Cruce peatonal`, `Cruce de mascotas`],
      
        },
      
        {
          pregunta: `De acuerdo a la imagen, ¿Las señales reglamentarias indican la 
          dirección obligatoria en el carril que deben seguir los vehículos en la 
          próxima intersección?`,
          imagen: `/img/69.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`,`DESCONOCE`],
      
        },
      
      
        {
          pregunta: `¿Qué debe hacer el conductor cuando en la vía observe un cruce 
          peatonal demarcado tipo cebra como indica la imagen?`,
          imagen: `/img/70.png`,
          respuesta: `Debe ceder el paso a los peatones que se encuentran cruzando la vía`,
          distractores: [`Debe pitar para alertar al peatón que no baje de la vereda`, `Debe acelerar para pasar más rápido`],
      
        },
      
        {
          pregunta: `¿Qué debe hacer el conductor cuando se encuentre con una señal 
          horizontal pintada en la vía como se observa en la imagen?`,
          imagen: `/img/71.png`,
          respuesta: `Velocidad máxima de 40 km/h`,
          distractores: [`Velocidad mínima de 40 km/h`, `Velocidad mínima de 40 km/h`],
      
        },
        {
          pregunta: `De acuerdo a la imagen , ¿Qué significa la siguiente señal horizontal 
          pintada en la vía?`,
          imagen: `/img/72.png`,
          respuesta: `Prohibido estacionar`,
          distractores: [`Estacionar temporalmente cuando no exista un agente de tránsito`, `Estacionar temporalmente cuando no exista un agente de tránsito`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señalización horizontal?`,
          imagen: `/img/73.png`,
          respuesta: `Estacionamiento tarifado`,
          distractores: [`No estacionar`, `Estacionamiento gratuito`],
      
        },
      
        {
          pregunta: `El conductor cuando se encuentre en una intersección, ¿Debe detener 
          su vehículo, bicicleta y peatones completamente y dar el paso a 
          cualquier vehículo que se encuentre cruzando la intersección?`,
          imagen: `/img/74.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
      
        {
          pregunta: `Los vehículos A y B llegan al mismo tiempo a una intersección en cruz 
          por diferentes lados ¿Cuál tiene derecho de vía?`,
          imagen: `/img/75.png`,
          respuesta: `El vehículo que se aproxima a la derecha`,
          distractores: [`Cualquiera de los dos`, `El vehículo que se aproxima a la izquierda`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/76.png`,
          respuesta: `Giro a izquierda`,
          distractores: [`Saludo`, `Disminuir la velocidad`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/77.png`,
          respuesta: `Giro a derecha`,
          distractores: [`Saludo`, `Parar`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/78.png`,
          respuesta: `Estacionarse o detenerse`,
          distractores: [`Solicita ayuda`, `Giro a izquierda`],
      
        },
      
        {
          pregunta: `¿Qué significa el espacio de la berma?`,
          imagen: `/img/79.png`,
          respuesta: `Espacio exclusivo para circulación de peatones, ciclistas y 
          movilidad personal`,
          distractores: [`Estacionamiento`, `Carril para circulación de motos`],
      
        },
        {
          pregunta: `¿Qué es la movilidad activa?`,
          respuesta: `Es aquella que depende del movimiento físico de las personas, incluye la caminata el uso de la 
          bicicleta y movilidad personal`,
          distractores: [`Es aquella que depende del movimiento motorizado, incluye el transporte público`, `Ninguna de las anteriores`],
      
        },
      
        {
          pregunta: `¿Cuáles son derechos de los biciusuarios? `,
          respuesta: `Todas las anteriores`,
          distractores: [`Derecho preferente de vía o circulación en los desvíos de avenidas y carreteras, cruce de caminos 
          intersecciones no señalizadas y ciclovías`, `Disponer vías de circulación privilegiada dentro de las ciudades y en carreteras, como ciclovías y 
          espacios similares`],
      
        },
        {
          pregunta: `De acuerdo a la normativa, ¿Dónde tienen derecho a circular los ciclistas?`,
          respuesta: `Transitar por todas las vías del país con respeto y seguridad.`,
          distractores: [`En las aceras, con cuidado respectivo a los peatones`, `Únicamente en las sendas especiales destinadas al uso de bicicletas`],
      
        },
        {
          pregunta: `¿En qué momento los conductores son responsables de su seguridad, de los pasajeros y el resto de 
          usuarios viales?`,
          respuesta: `En todo momento`,
          distractores: [`Los conductores no son responsables de lo que el pasajero haga o deje de hacer en cuanto a su 
          seguridad`, `Solo cuando esté encendido el motor del vehículo `],
        },
        {
          pregunta: `¿Qué se entiende por vía pública?`,
          respuesta: `Espacio público destinado al libre tránsito vehicular, bicicletas y peatonal`,
          distractores: [`El lugar por donde circulan los vehículos`, `El lugar por donde circulan los peatones`],
        },
        {
          pregunta: `¿Cuáles son las obligaciones de los peatones en la vía pública?`,
          respuesta: `Cruzar por las esquinas o cruces peatonales en las vías primarías y vías secundarias con más 
          de dos carriles efectivos de circulación; en vías secundarias que cuenten con un máximo de dos 
          carriles efectivos de circulación podrán cruzar en cualquier punto; y siempre y cuando le sea posible 
          hacerlo de manera segura`,
          distractores: [`Solicitar transporte o pedir ayuda a los automovilistas en lugares apropiados o prohibidos `, `Cruzar la calle por la parte anterior y posterior de los automotores que se hayan detenido momentáneamente`],
        },
        {
          pregunta: `¿Cuáles son derechos de los peatones?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Tener libre circulación sobre las aceras y zonas peatonales exclusivas, tener preferencia de vía sobre 
          todas las intersecciones, inclusive en las que tienen semáforo cuando la luz verde peatonal esté 
          encendida, todo el tiempo en los pasos cebra y en las esquinas no reguladas por semáforos, procurando 
          su propia seguridad y la de los demás`, `Disponer de las vías públicas libres de obstáculos y no invadidas, y contar con infraestructura y 
          señalización vial adecuadas que les brinden seguridad, en especial para las personas pertenecientes a los 
          grupos de atención prioritaria`],
        },
        {
          pregunta: `Uno de los objetivos de la Ley de Transporte Terrestre Tránsito y Seguridad Vial, es:`,
          respuesta: `El establecimiento de programas de capacitación y difusión para conductores, peatones, pasajeros y 
          autoridades, en materia de seguridad vial, para la creación de una cultura y conciencia vial 
          responsable y solidaria`,
          distractores: [`Definir sus principios, garantizar el derecho a la educación superior de calidad que propenda a la 
          excelencia`, `Contribuir al desarrollo del País a través de la formulación de políticas, regulaciones, planes, programas 
          y proyectos, que garanticen el buen vivir`],
        },
        {
          pregunta: `¿Cómo se sanciona a los responsables de un siniestro de tránsito que no sean conductores (pasajeros, 
            controladores, peatones, etc.)?`,
          respuesta: `Con las mismas sanciones que un conductor, exceptuando la pérdida de puntos`,
          distractores: [`Con las mismas penas que a un conductor responsable del siniestro`, `Con la mitad de las penas establecidas para un conductor responsable de un siniestro `],
        },
        {
          pregunta: `¿Qué implica conducir con prudencia a pesar de las acciones incorrectas de otros conductores y de las 
          condiciones adversas del clima y las vías, practicando normas de seguridad para no provocar un siniestro 
          de tránsito?`,
          respuesta: `Conducción con precaución`,
          distractores: [`Conducir con rapidez`, `Cumplir con las normas de tránsito`],
        },
        {
          pregunta: `¿Qué implica la prevención de siniestros?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Evitar las conductas riesgosas : exceso de velocidad, consumo de alcohol, no uso de cinturón, casco o 
          sistemas de retención infantil, no respetar las señales de tránsito, manejo distraído`, `Desarrollar un sistema seguro de movilidad, lo cual implica infraestructura para la seguridad vial, control 
          y capacitación de los actores viales`],
        },
        {
          pregunta: `¿Cuáles son los objetivos de la educación vial?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Reducir de forma sistemática los siniestros de tránsito`, `Formar y capacitar a las personas en general para el uso correcto de todos los medios de transporte
          terrestre`],
        },
        {
          pregunta: `Los niños menores de 12 años podrán viajar en:`,
          respuesta: `En los asientos posteriores utilizando los sistemas de retención infantil de acuerdo a la edad y altura`,
          distractores: [`En el asiento delantero con cinturón de seguridad`, `En el asiento delantero sin cinturón de seguridad`],
        },
        {
          pregunta: `¿Para qué sirven los seguros a prueba de niños en los vehículos?`,
          respuesta: `Impiden que las puertas puedan abrirse desde el interior`,
          distractores: [`Bloquean las ventanas traseras manteniéndolas cerradas`, `Aseguran las hebillas de los cinturones de seguridad`],
        },
        {
          pregunta: `El dispositivo de seguridad pasiva que reduce el desplazamiento de la cabeza hacia atrás para evitar el 
          efecto latigazo cervical, se conoce como:`,
          respuesta: `Apoya cabeza`,
          distractores: [`Cinturón de seguridad`, `Airbag`],
        },
        {
          pregunta: `¿Cómo se conoce al dispositivo de seguridad pasiva incluida en algunos vehículos el cual se acciona en 
          milisegundos al producirse un impacto?`,
          respuesta: `Airbag o bolsa de aire`,
          distractores: [`Apoya cabeza`, `Cinturón de seguridad`],
        },
        {
          pregunta: `¿Cuál es la distancia mínima que debe mantener entre vehículo y ciclista al conducir?`,
          respuesta: `1,5 metros`,
          distractores: [`1,0 metros`, `Todas las anteriores`],
        },
        {
          pregunta: `¿Cuándo debo llevar el kit de emergencia o carretero en mi vehículo?`,
          respuesta: `Siempre`,
          distractores: [`Solo Triángulos`, `Solo en caso de emergencia`],
        },
        {
          pregunta: `En un paso cebra no regulado, ¿Quién tiene la preferencia de paso?`,
          respuesta: `Los Peatones`,
          distractores: [`Los vehículos en circulación`, `El transporte público`],
        },
        {
          pregunta: `En caso de avería mecánica en la vía, ¿Debo poner los triángulos o conos de seguridad?`,
          respuesta: `Si, siempre`,
          distractores: [`No es necesario`, `Solo en caso de haber agentes de tránsito`],
        },
        {
        pregunta: `¿A qué distancia mínima antes de una maniobra es obligatorio poner la respectiva luz intermitente?`,
        respuesta: `Mínimo 30 metros antes`,
        distractores: [`Cuando ejecuto la maniobra`, `Mínimo 10 metros antes`],
         },
        {
          pregunta: `En un siniestro de tránsito las autoridades encargadas del control del tránsito, ¿Pueden detener a las 
          personas involucradas?`,
          respuesta: `Pueden detenerlos y ponerlos a las órdenes del fiscal, en el caso donde resultaron personas lesionadas 
          o fallecidas`,
          distractores: [`No pueden detener a nadie hasta que tengan una orden 
          judicial`, `Solamente pueden detener a los vehículos`],
        },
        {
          pregunta: `Al momento de rebasar a un ciclista en circulación, ¿Qué distancia mínima debe tomar el vehículo?`,
          respuesta: `1,5 metros`,
          distractores: [`1 metro`, `50 centímetros`],
        },
        {
          pregunta: `¿Cuál es la distancia mínima de seguimiento que debe mantener entre vehículo y ciclista al conducir?`,
          respuesta: `3 metros`,
          distractores: [`1 metro`, `2 metros`],
        },
        {
          pregunta: `¿Qué debe realizar un conductor cuando ve que un bus escolar e institucional se detiene en una parada?`,
          respuesta: `Esperar y dar el paso a todos los peatones que se bajan del bus`,
          distractores: [`Acelerar la marcha para pasar al bus`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `En las carreteras, ¿Los conductores cuando circulan deben dar preferencia?`,
          respuesta: `Peatones, ciclistas, motociclistas y similares.`,
          distractores: [`Otros conductores`, `Otros conductores`],
        },
        {
          pregunta: `¿Qué se presume si un conductor se niega a realizarse pruebas para la detección de alcohol o de la 
          presencia de sustancias estupefacientes?`,
          respuesta: `Será considerada como presunción de estar en el máximo grado de intoxicación`,
          distractores: [`Solo en el día`, `No`],
        },
        {
          pregunta: `Cuando se conduzca un vehículo a motor y se requiera cruzar una acera para entrar o salir de un 
          estacionamiento, ¿quién tiene preferencia de paso?`,
          respuesta: `Peatones, ciclistas y vehículos`,
          distractores: [`El conductor que está saliendo`, `Solo los vehículos`],
        },
        {
          pregunta: `¿A qué velocidad se estima que un atropellamiento de un niño/a sería mortal?`,
          respuesta: `25km/h`,
          distractores: [`15km/h`, `10km/h`],
        },
        {
          pregunta: `¿A qué velocidad se estima que el atropellamiento a un adulto sería mortal?`,
          respuesta: `60km/h`,
          distractores: [`40km/h`, `20km/h`],
        },
        {
          pregunta: `En caso de que no exista señalización horizontal y/o vertical, ¿Quién tendría derecho de paso en la vía en
          caso de que los usuarios presentes incluyan peatones, ciclistas, vehículos livianos, carga pesada?`,
          respuesta: `Peatones`,
          distractores: [`Biciusuarios`, `Transporte público de pasajeros`],
        },
        {
          pregunta: `Si un peatón va a cruzar una intersección con luz verde del semáforo vehicular o una intersección no 
          regulada y un vehículo va girar, ¿Quién tiene la preferencia de paso?`,
          respuesta: `El peatón `,
          distractores: [`El auto`, `Los dos`],
        },
        {
          pregunta: `¿Qué son las zonas 30?`,
          respuesta: `Zona en donde la velocidad máxima es 30 km/h`,
          distractores: [`Es una zona peatonal`, `Zona en donde la velocidad mínima es 30km/h`],
        },
        {
          pregunta: `¿Por qué la movilidad activa es importante?`,
          respuesta: `Genera menor impacto ambiental, social y económico`,
          distractores: [`Ninguna de las anteriores`, `Está de moda`],
        },
        {
          pregunta: `¿Un pasajero de transporte público puede denunciar ante la autoridad de tránsito, cuando el conductor 
          esté en estado de ebriedad?`,
          respuesta: `Es su obligación`,
          distractores: [`Talvez`, `No`],
        },
        {
          pregunta: `¿En qué porcentaje disminuyen las probabilidades de muerte para un motociclista o ciclista durante un 
          siniestro si utiliza casco homologado?`,
          respuesta: `39%`,
          distractores: [`No disminuyen, aumentan`, `1%`],
        },
    
    
    
    //*PREGUNTAS ESPECIFICAS AQUI*//
    
    
    
    
      {
        pregunta: `¿Cuál es la forma correcta de balancear el peso de la carga?`,
        imagen: `https://simulador.infotransitoec.com/img-simulador/img-a/Imagen79.png`,
        respuesta: `A`,
        distractores: [`Las dos anteriores`, `B`],
    
      },
      {
        pregunta: `¿Cuál es el límite máximo de velocidad que debe circular un vehículo de carga pesada
    en una vía urbana?`,
        respuesta: `40 km/h`,
        distractores: [`50 Km/h`, `60 km/h`],
    
      },
    
      {
        pregunta: `En una vía perimetral, ¿Cuál es el límite máximo de velocidad en el que debe circular un
    vehículo de carga pesada?`,
        respuesta: `70 km/h`,
        distractores: [`60 km/h`, `80 km/h`],
    
      },
    
      {
        pregunta: `En vías rectas, ¿Cuál es el límite máximo de velocidad en el que debe circular un
    vehículo de carga pesada?`,
        respuesta: `70 km/h`,
        distractores: [`80 km/h`, `100 km/h`],
    
      },
      {
        pregunta: `¿Qué deberán presentar las operadoras habilitadas para realizar el servicio de transporte
    terrestre de sustancias tóxicas y peligrosas?`,
        respuesta: `Un Plan de Seguridad Industrial`,
        distractores: [`Facturas de compra de los vehículos que garantice la antigüedad de los mismos`, `Permiso de circulación emitido por el Gobierno Autónomo Descentralizado`],
    
      },
    
      {
        pregunta: `¿Qué prohibiciones tienen los conductores de vehículos que transportan sustancias
    tóxicas o peligrosas?`,
        respuesta: `Todas las anteriores`,
        distractores: [`Llevar a bordo personas ajenas a su operación`, `Estacionar los vehículos en la vía pública o en la proximidad de fuentes de riesgo`],
    
      },
    
      {
        pregunta: `Los conductores de vehículos de transporte terrestre de sustancias tóxicas y peligrosas
    deben:`,
        respuesta: `Realizar un curso de capacitación obligatorio, del cual obtendrán un certificado que
    abalice que se encuentran aptos para realizar esta actividad`,
        distractores: [`Emplear técnicas de conducción a la defensiva`, `Todas las anteriores`],
    
      },
    
      {
        pregunta: `Cuando una carga sobresalga de la plataforma o carrocería del camión o vehículo, el
    borde trasero y los lados de la carga deben estar marcados con:`,
        respuesta: `(4) Banderas rojas`,
        distractores: [`4) Banderas negras`, `4) Banderas blancas`],
    
      },
    
      {
        pregunta: `La capacidad del extintor de incendios que deberá portar un vehículo de carga pesada
    es:`,
        respuesta: `Mínimo 10 kg. de polvo químico seco`,
        distractores: [`Inferior a 10 kg. de polvo químico seco`, `Mínimo 9 kg. y máximo 15 kg de polvo químico seco`],
    
      },
    
      {
        pregunta: `Para rebasar o adelantar a otros vehículos se lo hará siempre por la izquierda y en
    ningún caso o circunstancia por la derecha, salvo en los siguientes casos:`,
        respuesta: `Todas las anteriores`,
        distractores: [`Para evitar una desgracia o accidente inminente`, `En vías de dos o más carriles de circulación en el mismo sentido, siempre que la señalética lo
    permita`],
    
      },
    
      {
        pregunta: `Para estacionar de manera emergente un vehículo de carga pesada ¿Qué medidas de
    seguridad que debe adoptar inmediatamente?`,
        respuesta: `Todas las anteriores`,
        distractores: [`Asegurarse de que la carga esté debidamente protegida y señalizada`, `Colocar los triángulos de seguridad en la parte posterior a una distancia de 3 metros del vehículo`],
    
      },
    
      {
        pregunta: `¿Con que periodicidad los conductores profesionales se someterán a exámenes
    médicos?`,
        respuesta: `Cada año`,
        distractores: [`Cada dos años`, `No requieren exámenes médicos`],
    
      },
    
      {
        pregunta: `¿Cuál de las siguientes es una infracción o sanción grave, cometida por una operadora
    de transporte de mercancías, sustancias y material peligroso?`,
        respuesta: `Operar con vehículos que no posean revisión técnica vehicular, matrícula y póliza de
    seguro con responsabilidad civil a terceros, vigentes`,
        distractores: [`Ninguna de las anteriores`, `No encontrarse al día en el cumplimiento de sus obligaciones`],
    
      },
    
      {
        pregunta: `¿Cuál de los siguientes enunciados constituye una contravención de tránsito?`,
        respuesta: `El conductor que transporte una carga que exceda los límites máximos permitidos para
    circular`,
        distractores: [`El conductor que transporte una carga que no exceda los límites máximos permitidos para circular`, `El conductor que transporte una carga que circule dentro de los rangos de velocidad permitidos.`],
    
      },
    
      {
        pregunta: `El dispositivo de enganche del chasis de un tracto camión se denomina:`,
        respuesta: `Quinta rueda`,
        distractores: [`Quinto eje`, `Cuarta rueda`],
    
      },
    
      {
        pregunta: `El vehículo concebido y construido para realizar principalmente el arrastre de un
    semirremolque se denomina:`,
        respuesta: `Tracto camión`,
        distractores: [`Tren de carretera`, `Vehículo articulado`],
    
      },
    
    
      {
        pregunta: `El conjunto de vehículos acoplados siendo uno de ellos automotor se denomina:`,
        respuesta: `Vehículo articulado`,
        distractores: [`Remolque`, `Camión`],
    
      },
    
      {
        pregunta: `¿Cuál es el peso bruto vehicular máximo permitido para un camión de 2 ejes pequeño?`,
        respuesta: `7 toneladas`,
        distractores: [`5 toneladas`, `3.5 toneladas`],
    
      },
    
      {
        pregunta: `¿Cuál es la vigencia del certificado de operación especial emitido por el Ministerio de
    Transporte y Obras Públicas para transportar carga extra pesada?`,
        respuesta: `Un viaje`,
        distractores: [`Cinco viajes`, `Tres viajes`],
    
      },
    
      {
        pregunta: `¿Cuál es la vigencia del certificado de operación regular emitido por el Ministerio de
    Transporte y Obras Públicas para transportar carga pesada?`,
        respuesta: `2 años`,
        distractores: [`18 meses`, `1 año`],
    
      },
    
    {
        pregunta: `¿Cuál es la forma correcta de colocar cargas pesadas
    que no ocupan el área total del piso de un remolque?`,
    imagen: `https://simulador.infotransitoec.com/img-simulador/img-a/Imagen80.png`,
        respuesta: `B`,
        distractores: [`Las dos anteriores`, `A`],
    
      },
    
      {
        pregunta: `De los siguientes enunciados, ¿Cuál constituye una contravención de tránsito?`,
        respuesta: `Los conductores de vehículos pesados que circulen por zonas restringidas`,
        distractores: [`La o el conductor de vehículos pesados que deje carga en zonas permitidas`, `La o el conductor de vehículos pesados que circule con acompañantes en la cabina`],
    
      },
    
      {
        pregunta: `La licencia de conducir tipo E le permite conducir automotores cuyo peso sea:`,
        respuesta: `Mayor a 3.5 toneladas`,
        distractores: [`Menor a 3.5 toneladas`, `Ninguna de las anteriores`],
    
      },
    
      {
        pregunta: `¿Cuál es el peso bruto vehicular máximo permitido para circular en los puentes de la
    red vial estatal?`,
        respuesta: `48 toneladas`,
        distractores: [`45 toneladas`, `38 toneladas`],
    
      },
    
      {
        pregunta: `Los espejos convexos hacen que la imagen se vea:`,
        respuesta: `Más cercana de lo que realmente se encuentra`,
        distractores: [`Más lejana de lo que realmente se encuentra`, `Exactamente igual a lo que realmente se encuentra`],
    
      },
    
    ],
    F: [
        {
          pregunta: `El no acatar lo indicado en una señal de tránsito, ¿Es una infracción?`,
          respuesta: `VERDADERO`,
          distractores: [`DESCONOCE`, `FALSO`],
      
        },
        {
          pregunta: `En un semáforo con giro a la derecha o izquierda, si el conductor tiene el semáforo en verde, al igual que los peatones, ¿Quién tiene la preferencia?`,
          imagen: `/img/1.png`,
          respuesta: `Los peatones`,
          distractores: [`Los vehículos`, `Ambos`],
      
        },
        {
          pregunta: `Cuándo un cruce de vía está regulado por un agente de tránsito, ¿Las señales manuales de éste prevalecen sobre cualquier otra existente, incluso semáforos?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Una línea continua pintada en la calzada de color blanco o amarillo restringe la circulación vehicular de tal manera que ningún vehículo podrá cruzar como se observa en la imagen?`,
          imagen: `/img/2.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Una línea discontinua de color amarillo o blanco pintada en la
          calzada, de acuerdo a la imagen ¿Que indica?`,
          imagen: `/img/3.png`,
          respuesta: `Permite rebasar o adelantar sobre esta línea, siempre que exista 
          seguridad para hacerlo`,
          distractores: [`El conductor no puede realizar maniobras de adelantamiento 
          cuando la línea es discontinua`, `Restringe la circulación vehicular de tal manera que ningún 
          vehículo podrá cruzar esta línea`],
      
        },
        {
          pregunta: `Cuando vea pintado en la calzada una línea mixta, con la línea 
          continua a la izquierda del conductor, de acuerdo a la imagen ¿Qué 
          indica esta línea?`,
          imagen: `/img/4.png`,
          respuesta: `El conductor no puede realizar maniobras de adelantamiento 
    cuando tiene la línea continua a su izquierda`,
          distractores: [`El conductor no puede realizar maniobras de adelantamiento 
    cuando tiene la línea continua a su izquierda
    `, `Puede realizar maniobras de adelantamiento cuando tiene la línea 
    discontinua a la izquierda del conductor`],
      
        },
        {
          pregunta: `Cuando ve pintado de color blanco o amarillo en la calzada doble línea 
          continua ¿Qué indica?`,
          imagen: `/img/5.png`,
          respuesta: `Están totalmente prohibidas las maniobras para rebasar o 
          adelantar por cualquiera de los carriles que se transite para evitar 
          choques`,
          distractores: [`Puede realizar maniobras de adelantamiento cuando tiene la línea 
    discontinua a la izquierda del conductor`, `Le permite al conductor rebasar o adelantar, siempre que exista la 
          seguridad de hacerlo`],
      
        },
        {
          pregunta: `De acuerdo a la imagen, ¿Qué debe hacer si compararte carril con un 
          ciclista?`,
          imagen: `/img/6.png`,
          respuesta: `Ambas`,
          distractores: [`Da prioridad a ciclista en el carril compartido`, `Reduce a velocidad y comparte la vía con el ciclista`],
      
        },
        {
          pregunta: `¿Esta señal se utiliza para indicar?`,
          imagen: `/img/7.png`,
          respuesta: `Obligación de los conductores circular solo en la dirección 
          indicada
          `,
          distractores: [`Un solo carril`, `Doble vía`],
      
        },
        {
          pregunta: `De acuerdo a la imagen esta señal indica, No exceder de 30 km/h`,
          imagen: `/img/8.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `De acuerdo a la imagen, ¿esta señal indica prohibido el cambio de 
          carril?`,
          imagen: `/img/9.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Qué debe hacer cuando usted observa esta señal?`,
          imagen: `/img/10.png`,
          respuesta: `Detenerse antes de la línea de pare y ceder el paso a todo tránsito`,
          distractores: [`Detenerse antes de la línea de pare`, `Reducir la velocidad y estar preparado para detenerse solamente si 
    hay peligro de colisión con otro vehículo o peatón`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/11.png`,
          respuesta: `Viraje prohibido al sentido de la flecha`,
          distractores: [`Viraje permitido en el sentido de la flecha`, `Viraje seguro`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/12.png`,
          respuesta: `No girar en "U"`,
          distractores: [`No virar a la izquierda`, `Tener cuidado con el tráfico que vira`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/13.png`,
          respuesta: `Tránsito pesado debe circular por la derecha`,
          distractores: [`Estacionamiento permitido para camiones`, `Circulación solo tránsito pesado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/14.png`,
          respuesta: `Prohibido circular vehículos pesados`,
          distractores: [`Prohibido circular vehículos pesados`, `Solo tránsito pesado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/15.png`,
          respuesta: `Prohibido adelantar o rebasar`,
          distractores: [`Solo tránsito liviano`, `Prohibido doble columna`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/16.png`,
          respuesta: `Vehículos livianos deben mantener su derecha`,
          distractores: [`Vehículos livianos deben girar a la derecha`, `Permitido estacionarse vehículos livianos`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/17.png`,
          respuesta: `Comienza doble vía, pueden venir vehículos en sentido contrario. 
          Precaución`,
          distractores: [`Cambio de carril`, `No entre`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/18.png`,
          respuesta: `Prohibido estacionarse o detenerse`,
          distractores: [`Prohibido estacionarse`, `Permite detenerse por un minuto solamente`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/19.png`,
          respuesta: `No entre`,
          distractores: [`Ninguna de las anteriores`, `Ingreso permitido`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/20.png`,
          respuesta: `Prohibido virar en U o a la izquierda`,
          distractores: [`Prohibido virar en U o a la derecha`, `Prohibido virar a la derecha o a la izquierda`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/21.png`,
          respuesta: `Prohibido cambio de carril a la izquierda`,
          distractores: [`Prohibido virar en U o a la izquierda`, `Prohibido tomar la vía sinuosa`],
      
        },
        {
          pregunta: `¿Cuáles son las consecuencias de incumplimiento de esta señal 
          (prohibido estacionarse)?`,
          imagen: `/img/22.png`,
          respuesta: `Sanción con el 10% del salario básico unificado`,
          distractores: [`Pena privativa de libertad`, `Retiro de la licencia de conducir.`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/23.png`,
          respuesta: `Prohibido estacionar al lado izquierdo de la señal hasta la próxima 
          intersección`,
          distractores: [`Permitido estacionar a la izquierda hasta la próxima intersección`, `Prohibido estacionar a la derecha hasta la próxima intersección`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/24.png`,
          respuesta: `Prohibido estacionar al lado derecho de la señal hasta la próxima intersección`,
          distractores: [`Prohibición de estacionar a la izquierda hasta la próxima intersección`, `Estacionar a la derecha hasta la próxima intersección`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/25.png`,
          respuesta: `Prohibido estacionar a los dos lados de la señal hasta la próxima intersección a izquierda y derecha`,
          distractores: [`Estacionar a la derecha hasta la próxima intersección`, `Prohibición de estacionar a la izquierda`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/26.png`,
          respuesta: `Estacionamiento permitido`,
          distractores: [`Estacionamiento tarifado`, `Prohibición de estacionar`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/27.png`,
          respuesta: `Estacionamiento tarifado durante los días y horarios señalados`,
          distractores: [`Estacionamiento tarifado a excepción de los días y horarios definidos`, `Estacionamiento no tarifado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/28.png`,
          respuesta: `Estacionamiento reservado solo para personas con discapacidad`,
          distractores: [`Estacionamiento prohibido para personas con discapacidad`, `Estacionamiento reservado para vehículos grandes`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/29.png`,
          respuesta: `Estacionamiento en reservado solo para personas con discapacidad a los lados de la señal`,
          distractores: [`Estacionamiento en paralelo reservado para vehículos grandes`, `Estacionamiento en paralelo prohibido para personas con discapacidad`],
      
        },
        {
          pregunta: `¿Qué debe hacer el conductor de un vehículo particular frente a esta señal?`,
          imagen: `/img/30.png`,
          respuesta: `Abstenerse de estacionar o detener el vehículo en esta zona`,
          distractores: [`Detener o estacionar el vehículo en esta zona`, `Aumentar la velocidad o rebasar al bus`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/31.png`,
          respuesta: `Largo máximo de los vehículos que circulan por esa vía`,
          distractores: [`Ancho máximo de los vehículos que circulan por esa vía`, `Ancho de la vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/32.png`,
          respuesta: `Carril exclusivo para peatones y ciclistas`,
          distractores: [`Circular con precaución sobre ese carril ya que es compartido con peatones y ciclistas`, `Circular utilizando luces de estacionamiento`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/33.png`,
          respuesta: `Que el carril segregado exclusivo para ciclistas y peatones se termina`,
          distractores: [`Que el carril segregado exclusivo para ciclistas y peatones se termina e inicia en la siguiente intersección`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `¿Qué debe hacer al observar la siguiente señal regulatoria?`,
          imagen: `/img/34.png`,
          respuesta: `Ceder el paso a los conductores, peatones y ciclistas`,
          distractores: [`Ceder el paso solo a los conductores`, `Ceder el paso solo a los peatones`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/35.png`,
          respuesta: `Más adelante intersección en “Y”`,
          distractores: [`Más adelante vía lateral`, `Más adelante cruce de vías`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/36.png`,
          respuesta: `Aproximación a redondel`,
          distractores: [`Más adelante vire en U`, `Más adelante zona de derrumbes`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/37.png`,
          respuesta: `Más adelante circulación en doble vía (bidireccional)`,
          distractores: [`Más adelante circulación en una vía`, `Más adelante vire a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/38.png`,
          respuesta: `Más adelante curva abierta hacia el lado que indica la flecha`,
          distractores: [`Más adelante curva cerrada hacia la derecha`, `Más adelante curva y contra curva`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/39.png`,
          respuesta: `Más adelante curva y contra curva cerrada a la derecha en la dirección señalada`,
          distractores: [`Más adelante curva pronunciada a la derecha`, `Más adelante curva abierta a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/40.png`,
          respuesta: `Más adelante curva y contra curva abierta a la izquierda en la dirección señalada`,
          distractores: [`Más adelante curva pronunciada a la izquierda`, `Más adelante curva abierta a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/41.png`,
          respuesta: `Más adelante vía sinuosa a la izquierda`,
          distractores: [`Más adelante curva y contra curva cerradas a la izquierda`, `Más adelante curva pronunciada a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/42.png`,
          respuesta: `Más adelante curva en "U" a la izquierda`,
          distractores: [`Más adelante curva en "U" a la izquierda`, `Más adelante curva en "U" a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/43.png`,
          respuesta: `Más adelante bandas transversales de alerta o de retumbo`,
          distractores: [`Más adelante agua en la vía`, `Más adelante hondonada`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/44.png`,
          respuesta: `Más adelante reductor de velocidad`,
          distractores: [`Más adelante calzada irregular`, `Más adelante hondonada`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/45.png`,
          respuesta: `Más adelante angostamiento en la vía`,
          distractores: [`Más adelante pendiente peligrosa`, `Más adelante pendiente peligrosa`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/46.png`,
          respuesta: `Disminuir la velocidad antes de ingresar a la curva`,
          distractores: [`Acelerar`, `Mantener la misma velocidad en la que se encuentra circulando`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/47.png`,
          respuesta: `Aproximación a una curva cerrada en la dirección señalada`,
          distractores: [`Aproximación a una curva abierta a la derecha`, `Aproximación a una contra curva a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/48.png`,
          respuesta: `Aproximación a una vía lateral izquierda o derecha en curva`,
          distractores: [`Aproximación a un cruce de vías al virar a la izquierda`, `Aproximación a una curva cerrada a la izquierda`],
        },
        {
          pregunta: `Esta señal se utiliza para indicar:`,
          imagen: `/img/49.png`,
          respuesta: `Dirección y lugar por el cual el flujo de tránsito debe dejar la calzada normal y desviarse por otras calles`,
          distractores: [`Que el desvío se ha terminado y que el flujo del tránsito regresa por el desvío a la ruta original `, `La ubicación y el lugar por el cual el flujo de tránsito debe continuar la calzada normal`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/50.png`,
          respuesta: `Señal con lugar de destino y flecha de asignación de carril`,
          distractores: [`Señal con lugar de destino y mensaje de acciones en palabras`, `Señal con código de ruta, lugar de destino y mensaje de salida con distancia en kilómetros`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/51.png`,
          respuesta: `Zona Escolar`,
          distractores: [`La velocidad máxima en una zona donde se encuentran centros educativos`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/52.png`,
          respuesta: `Ambas`,
          distractores: [`Reducir la velocidad y conducir con precaución`, `La velocidad máxima de circulación en una zona escolar`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/53.png`,
          respuesta: `El inicio del paradero de una zona escolar`,
          distractores: [`Parada de vehículos pesados`, `Zona permitida para familiares de estudiantes`],
        },
        {
          pregunta: `La luz amarilla intermitente en un semáforo significa:`,
          respuesta: `Disminuir la velocidad y atravesar la intersección con mucha precaución`,
          distractores: [`Parar siempre y cuando lo pueda hacer con seguridad`, `Parar, ceda el paso a todo el tráfico que cruza antes de atravesar la intersección`],
      
        },
        {
          pregunta: `¿Las instrucciones del agente de tránsito prevalecen sobre las señales de tránsito aunque el semáforo se 
          encuentre en luz roja?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿En cuál de las siguientes luces de semáforo debería siempre detener su vehículo?`,
          respuesta: `Luces rojas y luces rojas intermitentes `,
          distractores: [`Luces rojas continuas, flechas rojas y luces amarillas intermitentes `, `Luces rojas continuas, luces rojas intermitentes y luces amarillas`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/54.png`,
          respuesta: `Motocicletas deben mantenerse en el carril derecho`,
          distractores: [`Motocicletas deben girar a la derecha`, `Permitido estacionarse motocicletas`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/55.png`,
          respuesta: `Prohibido el ingreso o circulación de vehículos motorizados`,
          distractores: [`Prohibida la circulación de buses`, `Prohibida la circulación de vehículos pesados`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/56.png`,
          respuesta: `Más adelante intersección en “T”`,
          distractores: [`Más adelante redondel`, `Más adelante cruce de tren`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/57.png`,
          respuesta: `Más adelante parterre termina`,
          distractores: [`Más adelante angostamiento de vía`, `Más adelante parterre comienza`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/58.png`,
          respuesta: `Más adelante parterre comienza`,
          distractores: [`Más adelante parterre termina`, `Más adelante angostamiento de vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/59.png`,
          respuesta: `Más adelante cruce de ganado`,
          distractores: [`Más adelante cruce de peatones`, `Más adelante cruce de maquinaria agrícola`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/60.png`,
          respuesta: `Aproximación a túnel`,
          distractores: [`Túnel obstruido`, `Trabajos en el túnel`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/61.png`,
          respuesta: `Más adelante vía sinuosa a la derecha`,
          distractores: [`Más adelante curva pronunciada a la derecha`, `Más adelante curva y contra curva abiertas a la derecha`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/62.png`,
          respuesta: `Más adelante vía compartida con ciclistas`,
          distractores: [`Más adelante solo vehículos livianos`, `Más adelante solo vehículos livianos`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/63.png`,
          respuesta: `Más adelante depresión en la vía`,
          distractores: [`Más adelante vía cerrada`, `Más adelante reductor de velocidad`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/64.png`,
          respuesta: `Más adelante puente angosto`,
          distractores: [`Más adelante pendiente peligrosa`, `Más adelante estrechamiento en la vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/65.png`,
          respuesta: `Límite máximo de velocidad`,
          distractores: [`Velocidad mínima de Km/h`, `Usted se encuentra en el Kilómetro 30`],
        },
        {
          pregunta: `La luz roja intermitente en un semáforo significa:`,
          respuesta: `Parar siempre`,
          distractores: [`Disminuir la velocidad y atravesar la intersección con mucha precaución`, `Parar, ceda el paso a todo el tráfico que cruza antes de atravesar la intersección`],
      
        },
        {
          pregunta: `La luz roja intermitente en una señal de PARE en el extremo lateral izquierdo de un transporte escolar, 
          ¿Qué significa?`,
          respuesta: `Prohibido adelantar o rebasar al transporte escolar, hasta que se apague la luz intermitente y se 
          guarde la señal de PARE`,
          distractores: [`Disminuir la velocidad y adelantar con precaución al transporte escolar`, `Que se encendió las luces de estacionamiento del transporte escolar`],
      
        },
        {
          pregunta: `La licencia digital es válida para circular en el Ecuador:`,
          respuesta: `Si`,
          distractores: [`Solo si está impresa`, `Ninguna de las anteriores`],
      
        },
      
        {
          pregunta: `Es válido portar una copia simple de la licencia o matrícula para circular en el Ecuador:`,
          respuesta: `No, nunca`,
          distractores: [`Solo si está impresa`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `Debo hacer un curso de conducción para obtener mi licencia de conductor en el Ecuador:`,
          respuesta: `Sí, siempre`,
          distractores: [`Solo si me pide el agente de tránsito`, `No`],
      
        },
        {
          pregunta: `El no cancelar el valor de multas no le permitirá: renovar la licencia, realizar la revisión técnica vehicular y 
          matricular su vehículo`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿El conductor que NO haya sido sancionado durante el tiempo de vigencia de la licencia de conducir y 
          mantenga los 30 (puntos), se beneficiará del 30% en el pago del valor de la especie de la licencia de 
          conducir?`,
          respuesta: `FALSO`,
          distractores: [`VERDADERO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Si conduce su vehículo con un nivel de alcohol por litro de sangre de 0,3 a 0,8; ¿Qué sanción será aplicada?`,
          respuesta: `Cinco días de privación de libertad y retención del vehículo por veinticuatro horas`,
          distractores: [`Treinta días de privación de libertad y retención del vehículo por veinticuatro horas`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `El conductor que falte de obra a la autoridad o agente de tránsito, ¿Será sancionado con pena 
          privativa de libertad de tres días?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
      
        {
          pregunta: `Si se queda sin combustible y por esa razón obstaculiza el tránsito vehicular, ¿Está cometiendo una 
          contravención?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Es una contravención de tránsito, la o el conductor que conduzca con licencia caducada?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor y pasajeros que no hagan uso del cinturón de seguridad, ¿Corresponde a una 
          contravención de transito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor que invada con su vehículo circulando o estacionándose, en las vías asignadas para uso 
          exclusivo de los ciclistas, ¿Corresponde a una contravención de tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Los conductores que conduzcan en estado de embriaguez o bajo los efectos de sustancias estupefacientes, 
          psicotrópicas o preparados que las contengan y del cual provoquen un siniestro del cual resulte la muerte, 
          ¿Qué sanción corresponde?`,
          respuesta: `Revocatoria definitiva de la licencia para conducir`,
          distractores: [`Pena privativa de la libertad de doce a veinte años`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `El conducir un vehículo cuyas llantas se encuentren lisas o en mal estado, ¿Será sancionado con pena 
          privativa de libertad de cinco a quince días?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `El conducir un vehículo con las luces no encendidas en horas de la noche o conduzca en sitios oscuros 
          como túneles con la luces apagadas, ¿Corresponde a una contravención de tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Cuál de los siguientes enunciados corresponde a una contravención de tránsito?`,
          respuesta: `La o el conductor que conduce sin mantener una distancia prudente de seguimiento respecto al 
          vehículo y ciclista de adelante `,
          distractores: [`La o el conductor que conduce con una distancia prudente de seguimiento respecto al vehículo de 
          adelante`, `La o el conductor que cede el paso y permite el adelantamiento de otros vehículos.`],
      
        },
        {
          pregunta: `La o el conductor de un vehículo automotor que circule con personas en los estribos o pisaderas, baldes de 
          camionetas, parachoques o colgados de las carrocerías de los vehículos , ¿Es una contravención de 
          transito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor de un vehículo particular que tenga vidrios con películas antisolares oscuras, polarizados 
          o cualquier tipo de adhesivo y que no tenga la debida autorización, ¿Corresponde a una contravención de 
          tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        
        {
          pregunta: `¿Qué indican las líneas blancas que se observan en la imagen?`,
          imagen: `/img/66.png`,
          respuesta: `Líneas de borde`,
          distractores: [`Línea de prohibición de estacionamiento`, `Línea de prohibición de estacionamiento`],
      
        },
        {
          pregunta: `¿Qué indican las líneas blancas que se observan en la imagen?`,
          imagen: `/img/67.png`,
          respuesta: `Indican a los conductores, especialmente en caso de poca 
          visibilidad, dónde se encuentra el borde de la calzada`,
          distractores: [`Indican a los conductores que es permitido el parqueo vehículos`, `Indican a los conductores que es permitido el parqueo vehículos`],
      
        },
        {
          pregunta: `¿Qué significan las líneas segmentadas pintadas de blanco en una 
          intersección, que se observan en la imagen ?`,
          imagen: `/img/68.png`,
          respuesta: `Cruce de ciclovías`,
          distractores: [`Cruce peatonal`, `Cruce de mascotas`],
      
        },
      
        {
          pregunta: `De acuerdo a la imagen, ¿Las señales reglamentarias indican la 
          dirección obligatoria en el carril que deben seguir los vehículos en la 
          próxima intersección?`,
          imagen: `/img/69.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`,`DESCONOCE`],
      
        },
      
      
        {
          pregunta: `¿Qué debe hacer el conductor cuando en la vía observe un cruce 
          peatonal demarcado tipo cebra como indica la imagen?`,
          imagen: `/img/70.png`,
          respuesta: `Debe ceder el paso a los peatones que se encuentran cruzando la vía`,
          distractores: [`Debe pitar para alertar al peatón que no baje de la vereda`, `Debe acelerar para pasar más rápido`],
      
        },
      
        {
          pregunta: `¿Qué debe hacer el conductor cuando se encuentre con una señal 
          horizontal pintada en la vía como se observa en la imagen?`,
          imagen: `/img/71.png`,
          respuesta: `Velocidad máxima de 40 km/h`,
          distractores: [`Velocidad mínima de 40 km/h`, `Velocidad mínima de 40 km/h`],
      
        },
        {
          pregunta: `De acuerdo a la imagen , ¿Qué significa la siguiente señal horizontal 
          pintada en la vía?`,
          imagen: `/img/72.png`,
          respuesta: `Prohibido estacionar`,
          distractores: [`Estacionar temporalmente cuando no exista un agente de tránsito`, `Estacionar temporalmente cuando no exista un agente de tránsito`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señalización horizontal?`,
          imagen: `/img/73.png`,
          respuesta: `Estacionamiento tarifado`,
          distractores: [`No estacionar`, `Estacionamiento gratuito`],
      
        },
      
        {
          pregunta: `El conductor cuando se encuentre en una intersección, ¿Debe detener 
          su vehículo, bicicleta y peatones completamente y dar el paso a 
          cualquier vehículo que se encuentre cruzando la intersección?`,
          imagen: `/img/74.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
      
        {
          pregunta: `Los vehículos A y B llegan al mismo tiempo a una intersección en cruz 
          por diferentes lados ¿Cuál tiene derecho de vía?`,
          imagen: `/img/75.png`,
          respuesta: `El vehículo que se aproxima a la derecha`,
          distractores: [`Cualquiera de los dos`, `El vehículo que se aproxima a la izquierda`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/76.png`,
          respuesta: `Giro a izquierda`,
          distractores: [`Saludo`, `Disminuir la velocidad`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/77.png`,
          respuesta: `Giro a derecha`,
          distractores: [`Saludo`, `Parar`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/78.png`,
          respuesta: `Estacionarse o detenerse`,
          distractores: [`Solicita ayuda`, `Giro a izquierda`],
      
        },
      
        {
          pregunta: `¿Qué significa el espacio de la berma?`,
          imagen: `/img/79.png`,
          respuesta: `Espacio exclusivo para circulación de peatones, ciclistas y 
          movilidad personal`,
          distractores: [`Estacionamiento`, `Carril para circulación de motos`],
      
        },
        {
          pregunta: `¿Qué es la movilidad activa?`,
          respuesta: `Es aquella que depende del movimiento físico de las personas, incluye la caminata el uso de la 
          bicicleta y movilidad personal`,
          distractores: [`Es aquella que depende del movimiento motorizado, incluye el transporte público`, `Ninguna de las anteriores`],
      
        },
      
        {
          pregunta: `¿Cuáles son derechos de los biciusuarios? `,
          respuesta: `Todas las anteriores`,
          distractores: [`Derecho preferente de vía o circulación en los desvíos de avenidas y carreteras, cruce de caminos 
          intersecciones no señalizadas y ciclovías`, `Disponer vías de circulación privilegiada dentro de las ciudades y en carreteras, como ciclovías y 
          espacios similares`],
      
        },
        {
          pregunta: `De acuerdo a la normativa, ¿Dónde tienen derecho a circular los ciclistas?`,
          respuesta: `Transitar por todas las vías del país con respeto y seguridad.`,
          distractores: [`En las aceras, con cuidado respectivo a los peatones`, `Únicamente en las sendas especiales destinadas al uso de bicicletas`],
      
        },
        {
          pregunta: `¿En qué momento los conductores son responsables de su seguridad, de los pasajeros y el resto de 
          usuarios viales?`,
          respuesta: `En todo momento`,
          distractores: [`Los conductores no son responsables de lo que el pasajero haga o deje de hacer en cuanto a su 
          seguridad`, `Solo cuando esté encendido el motor del vehículo `],
        },
        {
          pregunta: `¿Qué se entiende por vía pública?`,
          respuesta: `Espacio público destinado al libre tránsito vehicular, bicicletas y peatonal`,
          distractores: [`El lugar por donde circulan los vehículos`, `El lugar por donde circulan los peatones`],
        },
        {
          pregunta: `¿Cuáles son las obligaciones de los peatones en la vía pública?`,
          respuesta: `Cruzar por las esquinas o cruces peatonales en las vías primarías y vías secundarias con más 
          de dos carriles efectivos de circulación; en vías secundarias que cuenten con un máximo de dos 
          carriles efectivos de circulación podrán cruzar en cualquier punto; y siempre y cuando le sea posible 
          hacerlo de manera segura`,
          distractores: [`Solicitar transporte o pedir ayuda a los automovilistas en lugares apropiados o prohibidos `, `Cruzar la calle por la parte anterior y posterior de los automotores que se hayan detenido momentáneamente`],
        },
        {
          pregunta: `¿Cuáles son derechos de los peatones?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Tener libre circulación sobre las aceras y zonas peatonales exclusivas, tener preferencia de vía sobre 
          todas las intersecciones, inclusive en las que tienen semáforo cuando la luz verde peatonal esté 
          encendida, todo el tiempo en los pasos cebra y en las esquinas no reguladas por semáforos, procurando 
          su propia seguridad y la de los demás`, `Disponer de las vías públicas libres de obstáculos y no invadidas, y contar con infraestructura y 
          señalización vial adecuadas que les brinden seguridad, en especial para las personas pertenecientes a los 
          grupos de atención prioritaria`],
        },
        {
          pregunta: `Uno de los objetivos de la Ley de Transporte Terrestre Tránsito y Seguridad Vial, es:`,
          respuesta: `El establecimiento de programas de capacitación y difusión para conductores, peatones, pasajeros y 
          autoridades, en materia de seguridad vial, para la creación de una cultura y conciencia vial 
          responsable y solidaria`,
          distractores: [`Definir sus principios, garantizar el derecho a la educación superior de calidad que propenda a la 
          excelencia`, `Contribuir al desarrollo del País a través de la formulación de políticas, regulaciones, planes, programas 
          y proyectos, que garanticen el buen vivir`],
        },
        {
          pregunta: `¿Cómo se sanciona a los responsables de un siniestro de tránsito que no sean conductores (pasajeros, 
            controladores, peatones, etc.)?`,
          respuesta: `Con las mismas sanciones que un conductor, exceptuando la pérdida de puntos`,
          distractores: [`Con las mismas penas que a un conductor responsable del siniestro`, `Con la mitad de las penas establecidas para un conductor responsable de un siniestro `],
        },
        {
          pregunta: `¿Qué implica conducir con prudencia a pesar de las acciones incorrectas de otros conductores y de las 
          condiciones adversas del clima y las vías, practicando normas de seguridad para no provocar un siniestro 
          de tránsito?`,
          respuesta: `Conducción con precaución`,
          distractores: [`Conducir con rapidez`, `Cumplir con las normas de tránsito`],
        },
        {
          pregunta: `¿Qué implica la prevención de siniestros?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Evitar las conductas riesgosas : exceso de velocidad, consumo de alcohol, no uso de cinturón, casco o 
          sistemas de retención infantil, no respetar las señales de tránsito, manejo distraído`, `Desarrollar un sistema seguro de movilidad, lo cual implica infraestructura para la seguridad vial, control 
          y capacitación de los actores viales`],
        },
        {
          pregunta: `¿Cuáles son los objetivos de la educación vial?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Reducir de forma sistemática los siniestros de tránsito`, `Formar y capacitar a las personas en general para el uso correcto de todos los medios de transporte
          terrestre`],
        },
        {
          pregunta: `Los niños menores de 12 años podrán viajar en:`,
          respuesta: `En los asientos posteriores utilizando los sistemas de retención infantil de acuerdo a la edad y altura`,
          distractores: [`En el asiento delantero con cinturón de seguridad`, `En el asiento delantero sin cinturón de seguridad`],
        },
        {
          pregunta: `¿Para qué sirven los seguros a prueba de niños en los vehículos?`,
          respuesta: `Impiden que las puertas puedan abrirse desde el interior`,
          distractores: [`Bloquean las ventanas traseras manteniéndolas cerradas`, `Aseguran las hebillas de los cinturones de seguridad`],
        },
        {
          pregunta: `El dispositivo de seguridad pasiva que reduce el desplazamiento de la cabeza hacia atrás para evitar el 
          efecto latigazo cervical, se conoce como:`,
          respuesta: `Apoya cabeza`,
          distractores: [`Cinturón de seguridad`, `Airbag`],
        },
        {
          pregunta: `¿Cómo se conoce al dispositivo de seguridad pasiva incluida en algunos vehículos el cual se acciona en 
          milisegundos al producirse un impacto?`,
          respuesta: `Airbag o bolsa de aire`,
          distractores: [`Apoya cabeza`, `Cinturón de seguridad`],
        },
        {
          pregunta: `¿Cuál es la distancia mínima que debe mantener entre vehículo y ciclista al conducir?`,
          respuesta: `1,5 metros`,
          distractores: [`1,0 metros`, `Todas las anteriores`],
        },
        {
          pregunta: `¿Cuándo debo llevar el kit de emergencia o carretero en mi vehículo?`,
          respuesta: `Siempre`,
          distractores: [`Solo Triángulos`, `Solo en caso de emergencia`],
        },
        {
          pregunta: `En un paso cebra no regulado, ¿Quién tiene la preferencia de paso?`,
          respuesta: `Los Peatones`,
          distractores: [`Los vehículos en circulación`, `El transporte público`],
        },
        {
          pregunta: `En caso de avería mecánica en la vía, ¿Debo poner los triángulos o conos de seguridad?`,
          respuesta: `Si, siempre`,
          distractores: [`No es necesario`, `Solo en caso de haber agentes de tránsito`],
        },
        {
        pregunta: `¿A qué distancia mínima antes de una maniobra es obligatorio poner la respectiva luz intermitente?`,
        respuesta: `Mínimo 30 metros antes`,
        distractores: [`Cuando ejecuto la maniobra`, `Mínimo 10 metros antes`],
         },
        {
          pregunta: `En un siniestro de tránsito las autoridades encargadas del control del tránsito, ¿Pueden detener a las 
          personas involucradas?`,
          respuesta: `Pueden detenerlos y ponerlos a las órdenes del fiscal, en el caso donde resultaron personas lesionadas 
          o fallecidas`,
          distractores: [`No pueden detener a nadie hasta que tengan una orden 
          judicial`, `Solamente pueden detener a los vehículos`],
        },
        {
          pregunta: `Al momento de rebasar a un ciclista en circulación, ¿Qué distancia mínima debe tomar el vehículo?`,
          respuesta: `1,5 metros`,
          distractores: [`1 metro`, `50 centímetros`],
        },
        {
          pregunta: `¿Cuál es la distancia mínima de seguimiento que debe mantener entre vehículo y ciclista al conducir?`,
          respuesta: `3 metros`,
          distractores: [`1 metro`, `2 metros`],
        },
        {
          pregunta: `¿Qué debe realizar un conductor cuando ve que un bus escolar e institucional se detiene en una parada?`,
          respuesta: `Esperar y dar el paso a todos los peatones que se bajan del bus`,
          distractores: [`Acelerar la marcha para pasar al bus`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `En las carreteras, ¿Los conductores cuando circulan deben dar preferencia?`,
          respuesta: `Peatones, ciclistas, motociclistas y similares.`,
          distractores: [`Otros conductores`, `Otros conductores`],
        },
        {
          pregunta: `¿Qué se presume si un conductor se niega a realizarse pruebas para la detección de alcohol o de la 
          presencia de sustancias estupefacientes?`,
          respuesta: `Será considerada como presunción de estar en el máximo grado de intoxicación`,
          distractores: [`Solo en el día`, `No`],
        },
        {
          pregunta: `Cuando se conduzca un vehículo a motor y se requiera cruzar una acera para entrar o salir de un 
          estacionamiento, ¿quién tiene preferencia de paso?`,
          respuesta: `Peatones, ciclistas y vehículos`,
          distractores: [`El conductor que está saliendo`, `Solo los vehículos`],
        },
        {
          pregunta: `¿A qué velocidad se estima que un atropellamiento de un niño/a sería mortal?`,
          respuesta: `25km/h`,
          distractores: [`15km/h`, `10km/h`],
        },
        {
          pregunta: `¿A qué velocidad se estima que el atropellamiento a un adulto sería mortal?`,
          respuesta: `60km/h`,
          distractores: [`40km/h`, `20km/h`],
        },
        {
          pregunta: `En caso de que no exista señalización horizontal y/o vertical, ¿Quién tendría derecho de paso en la vía en
          caso de que los usuarios presentes incluyan peatones, ciclistas, vehículos livianos, carga pesada?`,
          respuesta: `Peatones`,
          distractores: [`Biciusuarios`, `Transporte público de pasajeros`],
        },
        {
          pregunta: `Si un peatón va a cruzar una intersección con luz verde del semáforo vehicular o una intersección no 
          regulada y un vehículo va girar, ¿Quién tiene la preferencia de paso?`,
          respuesta: `El peatón `,
          distractores: [`El auto`, `Los dos`],
        },
        {
          pregunta: `¿Qué son las zonas 30?`,
          respuesta: `Zona en donde la velocidad máxima es 30 km/h`,
          distractores: [`Es una zona peatonal`, `Zona en donde la velocidad mínima es 30km/h`],
        },
        {
          pregunta: `¿Por qué la movilidad activa es importante?`,
          respuesta: `Genera menor impacto ambiental, social y económico`,
          distractores: [`Ninguna de las anteriores`, `Está de moda`],
        },
        {
          pregunta: `¿Un pasajero de transporte público puede denunciar ante la autoridad de tránsito, cuando el conductor 
          esté en estado de ebriedad?`,
          respuesta: `Es su obligación`,
          distractores: [`Talvez`, `No`],
        },
        {
          pregunta: `¿En qué porcentaje disminuyen las probabilidades de muerte para un motociclista o ciclista durante un 
          siniestro si utiliza casco homologado?`,
          respuesta: `39%`,
          distractores: [`No disminuyen, aumentan`, `1%`],
        },
      
      
      //*PREGUNTAS ESPECIFICAS AQUI*//
      
    
        {
          pregunta: `¿A quién se otorga la licencia tipo F?`,
          respuesta: `A las personas mayores de edad con discapacidad`,
          distractores: [`A las personas mayores de edad que conducen vehículos comerciales`, `A las personas de 16 años que no cumplen la mayoría de edad`],
      
        },
      
        {
          pregunta: `¿Cuál es la manera más segura para llevar a un niño en el vehículo?`,
          respuesta: `En el asiento trasero, en una silla de seguridad`,
          distractores: [`En la falda de un adulto que va con su cinturón de seguridad`, `En cualquier asiento, siempre que lleve puesto el cinturón de seguridad para adultos`],
      
        },
        {
          pregunta: `En una vía urbana, ¿cuál es el límite máximo de velocidad en el que debe circular un
          vehículo considerado liviano?`,
          respuesta: `50 Km/h`,
          distractores: [`40 Km/h`, `60 Km/h`],
      
        },
        {
          pregunta: `¿Cuál es la razón principal por la que la distancia mínima entre el conductor y el volante
          debe ser de 25 cm?`,
          respuesta: `Porque es la distancia de acción del airbag al explotar`,
          distractores: [`Ninguna de las anteriores`, `Porque al sentarse a mayor distancia no tendría suficiente visibilidad`],
      
        },
      
        {
          pregunta: `En una vía perimetral, ¿cuál es el límite máximo de velocidad en el que debe circular un
          vehículo considerado liviano?`,
          respuesta: `90 Km/h`,
          distractores: [`95 Km/h`, `80 Km/h`],
      
        },
        {
          pregunta: `¿Para quién es obligatorio el uso del cinturón de seguridad?`,
          respuesta: `Para todos los ocupantes del vehículo`,
          distractores: [`Solamente para el conductor`, `Solamente para los pasajeros`],
      
        },
      
        {
          pregunta: `En vías rectas, ¿cuál es el límite máximo de velocidad en el que debe circular un vehículo
          considerado liviano?`,
          respuesta: `90 Km/h`,
          distractores: [`80 Km/h`, `100 Km/h`],
      
        },
      
        {
          pregunta: `El freno de mano actúa sobre las ruedas:`,
          respuesta: `Posteriores`,
          distractores: [`Delanteras`, `Todas las anteriores`],
      
        },
      
        {
          pregunta: `¿Qué tiempo de vigencia tienen las licencias no profesional tipo B?`,
          respuesta: `5 años`,
          distractores: [`6 años`, `4 años`],
      
        },
      
        {
          pregunta: `¿Cuándo una Licencia de conducir puede ser anulada?`,
          respuesta: `Cuando se detecte que éstas han sido otorgadas mediante un acto viciado por defectos
          de forma o falta de requisitos de fondo.`,
          distractores: [`Cuando los exámenes correspondientes determinen la incapacidad física mental y psicológica de
          su titular.`, `Cuando esté caducada.`],
      
        },
        {
          pregunta: `¿Qué tipo de vehículos puede conducir una persona que posee licencia tipo B?`,
          respuesta: `Automóviles y camionetas con acoplados de hasta 1,75 toneladas de carga útil o casas
          rodantes`,
          distractores: [`Automóviles y camionetas con acoplados de hasta 1,55 toneladas de carga útil o casas rodantes A`, `Automóviles y camionetas con acoplados de hasta 1,65 toneladas de carga útil o casas rodantes`],
      
        },
        {
          pregunta: `El ajuste del asiento debe:`,
          respuesta: `Facilitar el acceso a los mandos y disponer de buena visibilidad`,
          distractores: [`Ser lo más alto posible para ver mejor hacia delante`, `Estar lo más adelante posible para poder acelerar mejor`],
      
        },
        
      ],
      G:  [
        {
          pregunta: `El no acatar lo indicado en una señal de tránsito, ¿Es una infracción?`,
          respuesta: `VERDADERO`,
          distractores: [`DESCONOCE`, `FALSO`],
      
        },
        {
          pregunta: `En un semáforo con giro a la derecha o izquierda, si el conductor tiene el semáforo en verde, al igual que los peatones, ¿Quién tiene la preferencia?`,
          imagen: `/img/1.png`,
          respuesta: `Los peatones`,
          distractores: [`Los vehículos`, `Ambos`],
      
        },
        {
          pregunta: `Cuándo un cruce de vía está regulado por un agente de tránsito, ¿Las señales manuales de éste prevalecen sobre cualquier otra existente, incluso semáforos?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Una línea continua pintada en la calzada de color blanco o amarillo restringe la circulación vehicular de tal manera que ningún vehículo podrá cruzar como se observa en la imagen?`,
          imagen: `/img/2.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Una línea discontinua de color amarillo o blanco pintada en la
          calzada, de acuerdo a la imagen ¿Que indica?`,
          imagen: `/img/3.png`,
          respuesta: `Permite rebasar o adelantar sobre esta línea, siempre que exista 
          seguridad para hacerlo`,
          distractores: [`El conductor no puede realizar maniobras de adelantamiento 
          cuando la línea es discontinua`, `Restringe la circulación vehicular de tal manera que ningún 
          vehículo podrá cruzar esta línea`],
      
        },
        {
          pregunta: `Cuando vea pintado en la calzada una línea mixta, con la línea 
          continua a la izquierda del conductor, de acuerdo a la imagen ¿Qué 
          indica esta línea?`,
          imagen: `/img/4.png`,
          respuesta: `El conductor no puede realizar maniobras de adelantamiento 
    cuando tiene la línea continua a su izquierda`,
          distractores: [`El conductor no puede realizar maniobras de adelantamiento 
    cuando tiene la línea continua a su izquierda
    `, `Puede realizar maniobras de adelantamiento cuando tiene la línea 
    discontinua a la izquierda del conductor`],
      
        },
        {
          pregunta: `Cuando ve pintado de color blanco o amarillo en la calzada doble línea 
          continua ¿Qué indica?`,
          imagen: `/img/5.png`,
          respuesta: `Están totalmente prohibidas las maniobras para rebasar o 
          adelantar por cualquiera de los carriles que se transite para evitar 
          choques`,
          distractores: [`Puede realizar maniobras de adelantamiento cuando tiene la línea 
    discontinua a la izquierda del conductor`, `Le permite al conductor rebasar o adelantar, siempre que exista la 
          seguridad de hacerlo`],
      
        },
        {
          pregunta: `De acuerdo a la imagen, ¿Qué debe hacer si compararte carril con un 
          ciclista?`,
          imagen: `/img/6.png`,
          respuesta: `Ambas`,
          distractores: [`Da prioridad a ciclista en el carril compartido`, `Reduce a velocidad y comparte la vía con el ciclista`],
      
        },
        {
          pregunta: `¿Esta señal se utiliza para indicar?`,
          imagen: `/img/7.png`,
          respuesta: `Obligación de los conductores circular solo en la dirección 
          indicada
          `,
          distractores: [`Un solo carril`, `Doble vía`],
      
        },
        {
          pregunta: `De acuerdo a la imagen esta señal indica, No exceder de 30 km/h`,
          imagen: `/img/8.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `De acuerdo a la imagen, ¿esta señal indica prohibido el cambio de 
          carril?`,
          imagen: `/img/9.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Qué debe hacer cuando usted observa esta señal?`,
          imagen: `/img/10.png`,
          respuesta: `Detenerse antes de la línea de pare y ceder el paso a todo tránsito`,
          distractores: [`Detenerse antes de la línea de pare`, `Reducir la velocidad y estar preparado para detenerse solamente si 
    hay peligro de colisión con otro vehículo o peatón`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/11.png`,
          respuesta: `Viraje prohibido al sentido de la flecha`,
          distractores: [`Viraje permitido en el sentido de la flecha`, `Viraje seguro`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/12.png`,
          respuesta: `No girar en "U"`,
          distractores: [`No virar a la izquierda`, `Tener cuidado con el tráfico que vira`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/13.png`,
          respuesta: `Tránsito pesado debe circular por la derecha`,
          distractores: [`Estacionamiento permitido para camiones`, `Circulación solo tránsito pesado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/14.png`,
          respuesta: `Prohibido circular vehículos pesados`,
          distractores: [`Prohibido circular vehículos pesados`, `Solo tránsito pesado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/15.png`,
          respuesta: `Prohibido adelantar o rebasar`,
          distractores: [`Solo tránsito liviano`, `Prohibido doble columna`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/16.png`,
          respuesta: `Vehículos livianos deben mantener su derecha`,
          distractores: [`Vehículos livianos deben girar a la derecha`, `Permitido estacionarse vehículos livianos`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/17.png`,
          respuesta: `Comienza doble vía, pueden venir vehículos en sentido contrario. 
          Precaución`,
          distractores: [`Cambio de carril`, `No entre`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/18.png`,
          respuesta: `Prohibido estacionarse o detenerse`,
          distractores: [`Prohibido estacionarse`, `Permite detenerse por un minuto solamente`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/19.png`,
          respuesta: `No entre`,
          distractores: [`Ninguna de las anteriores`, `Ingreso permitido`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/20.png`,
          respuesta: `Prohibido virar en U o a la izquierda`,
          distractores: [`Prohibido virar en U o a la derecha`, `Prohibido virar a la derecha o a la izquierda`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/21.png`,
          respuesta: `Prohibido cambio de carril a la izquierda`,
          distractores: [`Prohibido virar en U o a la izquierda`, `Prohibido tomar la vía sinuosa`],
      
        },
        {
          pregunta: `¿Cuáles son las consecuencias de incumplimiento de esta señal 
          (prohibido estacionarse)?`,
          imagen: `/img/22.png`,
          respuesta: `Sanción con el 10% del salario básico unificado`,
          distractores: [`Pena privativa de libertad`, `Retiro de la licencia de conducir.`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/23.png`,
          respuesta: `Prohibido estacionar al lado izquierdo de la señal hasta la próxima 
          intersección`,
          distractores: [`Permitido estacionar a la izquierda hasta la próxima intersección`, `Prohibido estacionar a la derecha hasta la próxima intersección`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/24.png`,
          respuesta: `Prohibido estacionar al lado derecho de la señal hasta la próxima intersección`,
          distractores: [`Prohibición de estacionar a la izquierda hasta la próxima intersección`, `Estacionar a la derecha hasta la próxima intersección`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/25.png`,
          respuesta: `Prohibido estacionar a los dos lados de la señal hasta la próxima intersección a izquierda y derecha`,
          distractores: [`Estacionar a la derecha hasta la próxima intersección`, `Prohibición de estacionar a la izquierda`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/26.png`,
          respuesta: `Estacionamiento permitido`,
          distractores: [`Estacionamiento tarifado`, `Prohibición de estacionar`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/27.png`,
          respuesta: `Estacionamiento tarifado durante los días y horarios señalados`,
          distractores: [`Estacionamiento tarifado a excepción de los días y horarios definidos`, `Estacionamiento no tarifado`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/28.png`,
          respuesta: `Estacionamiento reservado solo para personas con discapacidad`,
          distractores: [`Estacionamiento prohibido para personas con discapacidad`, `Estacionamiento reservado para vehículos grandes`],
      
        },
        {
          pregunta: `¿Qué significa está señal?`,
          imagen: `/img/29.png`,
          respuesta: `Estacionamiento en reservado solo para personas con discapacidad a los lados de la señal`,
          distractores: [`Estacionamiento en paralelo reservado para vehículos grandes`, `Estacionamiento en paralelo prohibido para personas con discapacidad`],
      
        },
        {
          pregunta: `¿Qué debe hacer el conductor de un vehículo particular frente a esta señal?`,
          imagen: `/img/30.png`,
          respuesta: `Abstenerse de estacionar o detener el vehículo en esta zona`,
          distractores: [`Detener o estacionar el vehículo en esta zona`, `Aumentar la velocidad o rebasar al bus`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/31.png`,
          respuesta: `Largo máximo de los vehículos que circulan por esa vía`,
          distractores: [`Ancho máximo de los vehículos que circulan por esa vía`, `Ancho de la vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/32.png`,
          respuesta: `Carril exclusivo para peatones y ciclistas`,
          distractores: [`Circular con precaución sobre ese carril ya que es compartido con peatones y ciclistas`, `Circular utilizando luces de estacionamiento`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/33.png`,
          respuesta: `Que el carril segregado exclusivo para ciclistas y peatones se termina`,
          distractores: [`Que el carril segregado exclusivo para ciclistas y peatones se termina e inicia en la siguiente intersección`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `¿Qué debe hacer al observar la siguiente señal regulatoria?`,
          imagen: `/img/34.png`,
          respuesta: `Ceder el paso a los conductores, peatones y ciclistas`,
          distractores: [`Ceder el paso solo a los conductores`, `Ceder el paso solo a los peatones`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/35.png`,
          respuesta: `Más adelante intersección en “Y”`,
          distractores: [`Más adelante vía lateral`, `Más adelante cruce de vías`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/36.png`,
          respuesta: `Aproximación a redondel`,
          distractores: [`Más adelante vire en U`, `Más adelante zona de derrumbes`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/37.png`,
          respuesta: `Más adelante circulación en doble vía (bidireccional)`,
          distractores: [`Más adelante circulación en una vía`, `Más adelante vire a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/38.png`,
          respuesta: `Más adelante curva abierta hacia el lado que indica la flecha`,
          distractores: [`Más adelante curva cerrada hacia la derecha`, `Más adelante curva y contra curva`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/39.png`,
          respuesta: `Más adelante curva y contra curva cerrada a la derecha en la dirección señalada`,
          distractores: [`Más adelante curva pronunciada a la derecha`, `Más adelante curva abierta a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/40.png`,
          respuesta: `Más adelante curva y contra curva abierta a la izquierda en la dirección señalada`,
          distractores: [`Más adelante curva pronunciada a la izquierda`, `Más adelante curva abierta a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/41.png`,
          respuesta: `Más adelante vía sinuosa a la izquierda`,
          distractores: [`Más adelante curva y contra curva cerradas a la izquierda`, `Más adelante curva pronunciada a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/42.png`,
          respuesta: `Más adelante curva en "U" a la izquierda`,
          distractores: [`Más adelante curva en "U" a la izquierda`, `Más adelante curva en "U" a la izquierda`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/43.png`,
          respuesta: `Más adelante bandas transversales de alerta o de retumbo`,
          distractores: [`Más adelante agua en la vía`, `Más adelante hondonada`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/44.png`,
          respuesta: `Más adelante reductor de velocidad`,
          distractores: [`Más adelante calzada irregular`, `Más adelante hondonada`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/45.png`,
          respuesta: `Más adelante angostamiento en la vía`,
          distractores: [`Más adelante pendiente peligrosa`, `Más adelante pendiente peligrosa`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/46.png`,
          respuesta: `Disminuir la velocidad antes de ingresar a la curva`,
          distractores: [`Acelerar`, `Mantener la misma velocidad en la que se encuentra circulando`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/47.png`,
          respuesta: `Aproximación a una curva cerrada en la dirección señalada`,
          distractores: [`Aproximación a una curva abierta a la derecha`, `Aproximación a una contra curva a la derecha`],
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/48.png`,
          respuesta: `Aproximación a una vía lateral izquierda o derecha en curva`,
          distractores: [`Aproximación a un cruce de vías al virar a la izquierda`, `Aproximación a una curva cerrada a la izquierda`],
        },
        {
          pregunta: `Esta señal se utiliza para indicar:`,
          imagen: `/img/49.png`,
          respuesta: `Dirección y lugar por el cual el flujo de tránsito debe dejar la calzada normal y desviarse por otras calles`,
          distractores: [`Que el desvío se ha terminado y que el flujo del tránsito regresa por el desvío a la ruta original `, `La ubicación y el lugar por el cual el flujo de tránsito debe continuar la calzada normal`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/50.png`,
          respuesta: `Señal con lugar de destino y flecha de asignación de carril`,
          distractores: [`Señal con lugar de destino y mensaje de acciones en palabras`, `Señal con código de ruta, lugar de destino y mensaje de salida con distancia en kilómetros`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/51.png`,
          respuesta: `Zona Escolar`,
          distractores: [`La velocidad máxima en una zona donde se encuentran centros educativos`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/52.png`,
          respuesta: `Ambas`,
          distractores: [`Reducir la velocidad y conducir con precaución`, `La velocidad máxima de circulación en una zona escolar`],
        },
        {
          pregunta: `¿Qué indica esta señal de tránsito?`,
          imagen: `/img/53.png`,
          respuesta: `El inicio del paradero de una zona escolar`,
          distractores: [`Parada de vehículos pesados`, `Zona permitida para familiares de estudiantes`],
        },
        {
          pregunta: `La luz amarilla intermitente en un semáforo significa:`,
          respuesta: `Disminuir la velocidad y atravesar la intersección con mucha precaución`,
          distractores: [`Parar siempre y cuando lo pueda hacer con seguridad`, `Parar, ceda el paso a todo el tráfico que cruza antes de atravesar la intersección`],
      
        },
        {
          pregunta: `¿Las instrucciones del agente de tránsito prevalecen sobre las señales de tránsito aunque el semáforo se 
          encuentre en luz roja?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿En cuál de las siguientes luces de semáforo debería siempre detener su vehículo?`,
          respuesta: `Luces rojas y luces rojas intermitentes `,
          distractores: [`Luces rojas continuas, flechas rojas y luces amarillas intermitentes `, `Luces rojas continuas, luces rojas intermitentes y luces amarillas`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/54.png`,
          respuesta: `Motocicletas deben mantenerse en el carril derecho`,
          distractores: [`Motocicletas deben girar a la derecha`, `Permitido estacionarse motocicletas`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/55.png`,
          respuesta: `Prohibido el ingreso o circulación de vehículos motorizados`,
          distractores: [`Prohibida la circulación de buses`, `Prohibida la circulación de vehículos pesados`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/56.png`,
          respuesta: `Más adelante intersección en “T”`,
          distractores: [`Más adelante redondel`, `Más adelante cruce de tren`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/57.png`,
          respuesta: `Más adelante parterre termina`,
          distractores: [`Más adelante angostamiento de vía`, `Más adelante parterre comienza`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/58.png`,
          respuesta: `Más adelante parterre comienza`,
          distractores: [`Más adelante parterre termina`, `Más adelante angostamiento de vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/59.png`,
          respuesta: `Más adelante cruce de ganado`,
          distractores: [`Más adelante cruce de peatones`, `Más adelante cruce de maquinaria agrícola`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/60.png`,
          respuesta: `Aproximación a túnel`,
          distractores: [`Túnel obstruido`, `Trabajos en el túnel`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/61.png`,
          respuesta: `Más adelante vía sinuosa a la derecha`,
          distractores: [`Más adelante curva pronunciada a la derecha`, `Más adelante curva y contra curva abiertas a la derecha`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/62.png`,
          respuesta: `Más adelante vía compartida con ciclistas`,
          distractores: [`Más adelante solo vehículos livianos`, `Más adelante solo vehículos livianos`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/63.png`,
          respuesta: `Más adelante depresión en la vía`,
          distractores: [`Más adelante vía cerrada`, `Más adelante reductor de velocidad`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/64.png`,
          respuesta: `Más adelante puente angosto`,
          distractores: [`Más adelante pendiente peligrosa`, `Más adelante estrechamiento en la vía`],
      
        },
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/65.png`,
          respuesta: `Límite máximo de velocidad`,
          distractores: [`Velocidad mínima de Km/h`, `Usted se encuentra en el Kilómetro 30`],
        },
        {
          pregunta: `La luz roja intermitente en un semáforo significa:`,
          respuesta: `Parar siempre`,
          distractores: [`Disminuir la velocidad y atravesar la intersección con mucha precaución`, `Parar, ceda el paso a todo el tráfico que cruza antes de atravesar la intersección`],
      
        },
        {
          pregunta: `La luz roja intermitente en una señal de PARE en el extremo lateral izquierdo de un transporte escolar, 
          ¿Qué significa?`,
          respuesta: `Prohibido adelantar o rebasar al transporte escolar, hasta que se apague la luz intermitente y se 
          guarde la señal de PARE`,
          distractores: [`Disminuir la velocidad y adelantar con precaución al transporte escolar`, `Que se encendió las luces de estacionamiento del transporte escolar`],
      
        },
        {
          pregunta: `La licencia digital es válida para circular en el Ecuador:`,
          respuesta: `Si`,
          distractores: [`Solo si está impresa`, `Ninguna de las anteriores`],
      
        },
      
        {
          pregunta: `Es válido portar una copia simple de la licencia o matrícula para circular en el Ecuador:`,
          respuesta: `No, nunca`,
          distractores: [`Solo si está impresa`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `Debo hacer un curso de conducción para obtener mi licencia de conductor en el Ecuador:`,
          respuesta: `Sí, siempre`,
          distractores: [`Solo si me pide el agente de tránsito`, `No`],
      
        },
        {
          pregunta: `El no cancelar el valor de multas no le permitirá: renovar la licencia, realizar la revisión técnica vehicular y 
          matricular su vehículo`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿El conductor que NO haya sido sancionado durante el tiempo de vigencia de la licencia de conducir y 
          mantenga los 30 (puntos), se beneficiará del 30% en el pago del valor de la especie de la licencia de 
          conducir?`,
          respuesta: `FALSO`,
          distractores: [`VERDADERO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Si conduce su vehículo con un nivel de alcohol por litro de sangre de 0,3 a 0,8; ¿Qué sanción será aplicada?`,
          respuesta: `Cinco días de privación de libertad y retención del vehículo por veinticuatro horas`,
          distractores: [`Treinta días de privación de libertad y retención del vehículo por veinticuatro horas`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `El conductor que falte de obra a la autoridad o agente de tránsito, ¿Será sancionado con pena 
          privativa de libertad de tres días?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
      
        {
          pregunta: `Si se queda sin combustible y por esa razón obstaculiza el tránsito vehicular, ¿Está cometiendo una 
          contravención?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Es una contravención de tránsito, la o el conductor que conduzca con licencia caducada?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor y pasajeros que no hagan uso del cinturón de seguridad, ¿Corresponde a una 
          contravención de transito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor que invada con su vehículo circulando o estacionándose, en las vías asignadas para uso 
          exclusivo de los ciclistas, ¿Corresponde a una contravención de tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `Los conductores que conduzcan en estado de embriaguez o bajo los efectos de sustancias estupefacientes, 
          psicotrópicas o preparados que las contengan y del cual provoquen un siniestro del cual resulte la muerte, 
          ¿Qué sanción corresponde?`,
          respuesta: `Revocatoria definitiva de la licencia para conducir`,
          distractores: [`Pena privativa de la libertad de doce a veinte años`, `Ninguna de las anteriores`],
      
        },
        {
          pregunta: `El conducir un vehículo cuyas llantas se encuentren lisas o en mal estado, ¿Será sancionado con pena 
          privativa de libertad de cinco a quince días?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `El conducir un vehículo con las luces no encendidas en horas de la noche o conduzca en sitios oscuros 
          como túneles con la luces apagadas, ¿Corresponde a una contravención de tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `¿Cuál de los siguientes enunciados corresponde a una contravención de tránsito?`,
          respuesta: `La o el conductor que conduce sin mantener una distancia prudente de seguimiento respecto al 
          vehículo y ciclista de adelante `,
          distractores: [`La o el conductor que conduce con una distancia prudente de seguimiento respecto al vehículo de 
          adelante`, `La o el conductor que cede el paso y permite el adelantamiento de otros vehículos.`],
      
        },
        {
          pregunta: `La o el conductor de un vehículo automotor que circule con personas en los estribos o pisaderas, baldes de 
          camionetas, parachoques o colgados de las carrocerías de los vehículos , ¿Es una contravención de 
          transito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        {
          pregunta: `La o el conductor de un vehículo particular que tenga vidrios con películas antisolares oscuras, polarizados 
          o cualquier tipo de adhesivo y que no tenga la debida autorización, ¿Corresponde a una contravención de 
          tránsito?`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
        
        {
          pregunta: `¿Qué indican las líneas blancas que se observan en la imagen?`,
          imagen: `/img/66.png`,
          respuesta: `Líneas de borde`,
          distractores: [`Línea de prohibición de estacionamiento`, `Línea de prohibición de estacionamiento`],
      
        },
        {
          pregunta: `¿Qué indican las líneas blancas que se observan en la imagen?`,
          imagen: `/img/67.png`,
          respuesta: `Indican a los conductores, especialmente en caso de poca 
          visibilidad, dónde se encuentra el borde de la calzada`,
          distractores: [`Indican a los conductores que es permitido el parqueo vehículos`, `Indican a los conductores que es permitido el parqueo vehículos`],
      
        },
        {
          pregunta: `¿Qué significan las líneas segmentadas pintadas de blanco en una 
          intersección, que se observan en la imagen ?`,
          imagen: `/img/68.png`,
          respuesta: `Cruce de ciclovías`,
          distractores: [`Cruce peatonal`, `Cruce de mascotas`],
      
        },
      
        {
          pregunta: `De acuerdo a la imagen, ¿Las señales reglamentarias indican la 
          dirección obligatoria en el carril que deben seguir los vehículos en la 
          próxima intersección?`,
          imagen: `/img/69.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`,`DESCONOCE`],
      
        },
      
      
        {
          pregunta: `¿Qué debe hacer el conductor cuando en la vía observe un cruce 
          peatonal demarcado tipo cebra como indica la imagen?`,
          imagen: `/img/70.png`,
          respuesta: `Debe ceder el paso a los peatones que se encuentran cruzando la vía`,
          distractores: [`Debe pitar para alertar al peatón que no baje de la vereda`, `Debe acelerar para pasar más rápido`],
      
        },
      
        {
          pregunta: `¿Qué debe hacer el conductor cuando se encuentre con una señal 
          horizontal pintada en la vía como se observa en la imagen?`,
          imagen: `/img/71.png`,
          respuesta: `Velocidad máxima de 40 km/h`,
          distractores: [`Velocidad mínima de 40 km/h`, `Velocidad mínima de 40 km/h`],
      
        },
        {
          pregunta: `De acuerdo a la imagen , ¿Qué significa la siguiente señal horizontal 
          pintada en la vía?`,
          imagen: `/img/72.png`,
          respuesta: `Prohibido estacionar`,
          distractores: [`Estacionar temporalmente cuando no exista un agente de tránsito`, `Estacionar temporalmente cuando no exista un agente de tránsito`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señalización horizontal?`,
          imagen: `/img/73.png`,
          respuesta: `Estacionamiento tarifado`,
          distractores: [`No estacionar`, `Estacionamiento gratuito`],
      
        },
      
        {
          pregunta: `El conductor cuando se encuentre en una intersección, ¿Debe detener 
          su vehículo, bicicleta y peatones completamente y dar el paso a 
          cualquier vehículo que se encuentre cruzando la intersección?`,
          imagen: `/img/74.png`,
          respuesta: `VERDADERO`,
          distractores: [`FALSO`, `DESCONOCE`],
      
        },
      
        {
          pregunta: `Los vehículos A y B llegan al mismo tiempo a una intersección en cruz 
          por diferentes lados ¿Cuál tiene derecho de vía?`,
          imagen: `/img/75.png`,
          respuesta: `El vehículo que se aproxima a la derecha`,
          distractores: [`Cualquiera de los dos`, `El vehículo que se aproxima a la izquierda`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/76.png`,
          respuesta: `Giro a izquierda`,
          distractores: [`Saludo`, `Disminuir la velocidad`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/77.png`,
          respuesta: `Giro a derecha`,
          distractores: [`Saludo`, `Parar`],
      
        },
      
        {
          pregunta: `¿Qué significa esta señal?`,
          imagen: `/img/78.png`,
          respuesta: `Estacionarse o detenerse`,
          distractores: [`Solicita ayuda`, `Giro a izquierda`],
      
        },
      
        {
          pregunta: `¿Qué significa el espacio de la berma?`,
          imagen: `/img/79.png`,
          respuesta: `Espacio exclusivo para circulación de peatones, ciclistas y 
          movilidad personal`,
          distractores: [`Estacionamiento`, `Carril para circulación de motos`],
      
        },
        {
          pregunta: `¿Qué es la movilidad activa?`,
          respuesta: `Es aquella que depende del movimiento físico de las personas, incluye la caminata el uso de la 
          bicicleta y movilidad personal`,
          distractores: [`Es aquella que depende del movimiento motorizado, incluye el transporte público`, `Ninguna de las anteriores`],
      
        },
      
        {
          pregunta: `¿Cuáles son derechos de los biciusuarios? `,
          respuesta: `Todas las anteriores`,
          distractores: [`Derecho preferente de vía o circulación en los desvíos de avenidas y carreteras, cruce de caminos 
          intersecciones no señalizadas y ciclovías`, `Disponer vías de circulación privilegiada dentro de las ciudades y en carreteras, como ciclovías y 
          espacios similares`],
      
        },
        {
          pregunta: `De acuerdo a la normativa, ¿Dónde tienen derecho a circular los ciclistas?`,
          respuesta: `Transitar por todas las vías del país con respeto y seguridad.`,
          distractores: [`En las aceras, con cuidado respectivo a los peatones`, `Únicamente en las sendas especiales destinadas al uso de bicicletas`],
      
        },
        {
          pregunta: `¿En qué momento los conductores son responsables de su seguridad, de los pasajeros y el resto de 
          usuarios viales?`,
          respuesta: `En todo momento`,
          distractores: [`Los conductores no son responsables de lo que el pasajero haga o deje de hacer en cuanto a su 
          seguridad`, `Solo cuando esté encendido el motor del vehículo `],
        },
        {
          pregunta: `¿Qué se entiende por vía pública?`,
          respuesta: `Espacio público destinado al libre tránsito vehicular, bicicletas y peatonal`,
          distractores: [`El lugar por donde circulan los vehículos`, `El lugar por donde circulan los peatones`],
        },
        {
          pregunta: `¿Cuáles son las obligaciones de los peatones en la vía pública?`,
          respuesta: `Cruzar por las esquinas o cruces peatonales en las vías primarías y vías secundarias con más 
          de dos carriles efectivos de circulación; en vías secundarias que cuenten con un máximo de dos 
          carriles efectivos de circulación podrán cruzar en cualquier punto; y siempre y cuando le sea posible 
          hacerlo de manera segura`,
          distractores: [`Solicitar transporte o pedir ayuda a los automovilistas en lugares apropiados o prohibidos `, `Cruzar la calle por la parte anterior y posterior de los automotores que se hayan detenido momentáneamente`],
        },
        {
          pregunta: `¿Cuáles son derechos de los peatones?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Tener libre circulación sobre las aceras y zonas peatonales exclusivas, tener preferencia de vía sobre 
          todas las intersecciones, inclusive en las que tienen semáforo cuando la luz verde peatonal esté 
          encendida, todo el tiempo en los pasos cebra y en las esquinas no reguladas por semáforos, procurando 
          su propia seguridad y la de los demás`, `Disponer de las vías públicas libres de obstáculos y no invadidas, y contar con infraestructura y 
          señalización vial adecuadas que les brinden seguridad, en especial para las personas pertenecientes a los 
          grupos de atención prioritaria`],
        },
        {
          pregunta: `Uno de los objetivos de la Ley de Transporte Terrestre Tránsito y Seguridad Vial, es:`,
          respuesta: `El establecimiento de programas de capacitación y difusión para conductores, peatones, pasajeros y 
          autoridades, en materia de seguridad vial, para la creación de una cultura y conciencia vial 
          responsable y solidaria`,
          distractores: [`Definir sus principios, garantizar el derecho a la educación superior de calidad que propenda a la 
          excelencia`, `Contribuir al desarrollo del País a través de la formulación de políticas, regulaciones, planes, programas 
          y proyectos, que garanticen el buen vivir`],
        },
        {
          pregunta: `¿Cómo se sanciona a los responsables de un siniestro de tránsito que no sean conductores (pasajeros, 
            controladores, peatones, etc.)?`,
          respuesta: `Con las mismas sanciones que un conductor, exceptuando la pérdida de puntos`,
          distractores: [`Con las mismas penas que a un conductor responsable del siniestro`, `Con la mitad de las penas establecidas para un conductor responsable de un siniestro `],
        },
        {
          pregunta: `¿Qué implica conducir con prudencia a pesar de las acciones incorrectas de otros conductores y de las 
          condiciones adversas del clima y las vías, practicando normas de seguridad para no provocar un siniestro 
          de tránsito?`,
          respuesta: `Conducción con precaución`,
          distractores: [`Conducir con rapidez`, `Cumplir con las normas de tránsito`],
        },
        {
          pregunta: `¿Qué implica la prevención de siniestros?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Evitar las conductas riesgosas : exceso de velocidad, consumo de alcohol, no uso de cinturón, casco o 
          sistemas de retención infantil, no respetar las señales de tránsito, manejo distraído`, `Desarrollar un sistema seguro de movilidad, lo cual implica infraestructura para la seguridad vial, control 
          y capacitación de los actores viales`],
        },
        {
          pregunta: `¿Cuáles son los objetivos de la educación vial?`,
          respuesta: `Todas las anteriores`,
          distractores: [`Reducir de forma sistemática los siniestros de tránsito`, `Formar y capacitar a las personas en general para el uso correcto de todos los medios de transporte
          terrestre`],
        },
        {
          pregunta: `Los niños menores de 12 años podrán viajar en:`,
          respuesta: `En los asientos posteriores utilizando los sistemas de retención infantil de acuerdo a la edad y altura`,
          distractores: [`En el asiento delantero con cinturón de seguridad`, `En el asiento delantero sin cinturón de seguridad`],
        },
        {
          pregunta: `¿Para qué sirven los seguros a prueba de niños en los vehículos?`,
          respuesta: `Impiden que las puertas puedan abrirse desde el interior`,
          distractores: [`Bloquean las ventanas traseras manteniéndolas cerradas`, `Aseguran las hebillas de los cinturones de seguridad`],
        },
        {
          pregunta: `El dispositivo de seguridad pasiva que reduce el desplazamiento de la cabeza hacia atrás para evitar el 
          efecto latigazo cervical, se conoce como:`,
          respuesta: `Apoya cabeza`,
          distractores: [`Cinturón de seguridad`, `Airbag`],
        },
        {
          pregunta: `¿Cómo se conoce al dispositivo de seguridad pasiva incluida en algunos vehículos el cual se acciona en 
          milisegundos al producirse un impacto?`,
          respuesta: `Airbag o bolsa de aire`,
          distractores: [`Apoya cabeza`, `Cinturón de seguridad`],
        },
        {
          pregunta: `¿Cuál es la distancia mínima que debe mantener entre vehículo y ciclista al conducir?`,
          respuesta: `1,5 metros`,
          distractores: [`1,0 metros`, `Todas las anteriores`],
        },
        {
          pregunta: `¿Cuándo debo llevar el kit de emergencia o carretero en mi vehículo?`,
          respuesta: `Siempre`,
          distractores: [`Solo Triángulos`, `Solo en caso de emergencia`],
        },
        {
          pregunta: `En un paso cebra no regulado, ¿Quién tiene la preferencia de paso?`,
          respuesta: `Los Peatones`,
          distractores: [`Los vehículos en circulación`, `El transporte público`],
        },
        {
          pregunta: `En caso de avería mecánica en la vía, ¿Debo poner los triángulos o conos de seguridad?`,
          respuesta: `Si, siempre`,
          distractores: [`No es necesario`, `Solo en caso de haber agentes de tránsito`],
        },
        {
        pregunta: `¿A qué distancia mínima antes de una maniobra es obligatorio poner la respectiva luz intermitente?`,
        respuesta: `Mínimo 30 metros antes`,
        distractores: [`Cuando ejecuto la maniobra`, `Mínimo 10 metros antes`],
         },
        {
          pregunta: `En un siniestro de tránsito las autoridades encargadas del control del tránsito, ¿Pueden detener a las 
          personas involucradas?`,
          respuesta: `Pueden detenerlos y ponerlos a las órdenes del fiscal, en el caso donde resultaron personas lesionadas 
          o fallecidas`,
          distractores: [`No pueden detener a nadie hasta que tengan una orden 
          judicial`, `Solamente pueden detener a los vehículos`],
        },
        {
          pregunta: `Al momento de rebasar a un ciclista en circulación, ¿Qué distancia mínima debe tomar el vehículo?`,
          respuesta: `1,5 metros`,
          distractores: [`1 metro`, `50 centímetros`],
        },
        {
          pregunta: `¿Cuál es la distancia mínima de seguimiento que debe mantener entre vehículo y ciclista al conducir?`,
          respuesta: `3 metros`,
          distractores: [`1 metro`, `2 metros`],
        },
        {
          pregunta: `¿Qué debe realizar un conductor cuando ve que un bus escolar e institucional se detiene en una parada?`,
          respuesta: `Esperar y dar el paso a todos los peatones que se bajan del bus`,
          distractores: [`Acelerar la marcha para pasar al bus`, `Ninguna de las anteriores`],
        },
        {
          pregunta: `En las carreteras, ¿Los conductores cuando circulan deben dar preferencia?`,
          respuesta: `Peatones, ciclistas, motociclistas y similares.`,
          distractores: [`Otros conductores`, `Otros conductores`],
        },
        {
          pregunta: `¿Qué se presume si un conductor se niega a realizarse pruebas para la detección de alcohol o de la 
          presencia de sustancias estupefacientes?`,
          respuesta: `Será considerada como presunción de estar en el máximo grado de intoxicación`,
          distractores: [`Solo en el día`, `No`],
        },
        {
          pregunta: `Cuando se conduzca un vehículo a motor y se requiera cruzar una acera para entrar o salir de un 
          estacionamiento, ¿quién tiene preferencia de paso?`,
          respuesta: `Peatones, ciclistas y vehículos`,
          distractores: [`El conductor que está saliendo`, `Solo los vehículos`],
        },
        {
          pregunta: `¿A qué velocidad se estima que un atropellamiento de un niño/a sería mortal?`,
          respuesta: `25km/h`,
          distractores: [`15km/h`, `10km/h`],
        },
        {
          pregunta: `¿A qué velocidad se estima que el atropellamiento a un adulto sería mortal?`,
          respuesta: `60km/h`,
          distractores: [`40km/h`, `20km/h`],
        },
        {
          pregunta: `En caso de que no exista señalización horizontal y/o vertical, ¿Quién tendría derecho de paso en la vía en
          caso de que los usuarios presentes incluyan peatones, ciclistas, vehículos livianos, carga pesada?`,
          respuesta: `Peatones`,
          distractores: [`Biciusuarios`, `Transporte público de pasajeros`],
        },
        {
          pregunta: `Si un peatón va a cruzar una intersección con luz verde del semáforo vehicular o una intersección no 
          regulada y un vehículo va girar, ¿Quién tiene la preferencia de paso?`,
          respuesta: `El peatón `,
          distractores: [`El auto`, `Los dos`],
        },
        {
          pregunta: `¿Qué son las zonas 30?`,
          respuesta: `Zona en donde la velocidad máxima es 30 km/h`,
          distractores: [`Es una zona peatonal`, `Zona en donde la velocidad mínima es 30km/h`],
        },
        {
          pregunta: `¿Por qué la movilidad activa es importante?`,
          respuesta: `Genera menor impacto ambiental, social y económico`,
          distractores: [`Ninguna de las anteriores`, `Está de moda`],
        },
        {
          pregunta: `¿Un pasajero de transporte público puede denunciar ante la autoridad de tránsito, cuando el conductor 
          esté en estado de ebriedad?`,
          respuesta: `Es su obligación`,
          distractores: [`Talvez`, `No`],
        },
        {
          pregunta: `¿En qué porcentaje disminuyen las probabilidades de muerte para un motociclista o ciclista durante un 
          siniestro si utiliza casco homologado?`,
          respuesta: `39%`,
          distractores: [`No disminuyen, aumentan`, `1%`],
        },
      
      
    
    //*PREGUNTAS ESPECIFICAS AQUI*//
    
    
      {
        pregunta: `La vida útil de una máquina agrícola dependerá de:`,
        respuesta: `La cantidad de horas trabajadas y el mantenimiento que se aplique a la misma`,
        distractores: [`El plan de mantenimiento previsto para la maquinaria`, `La procedencia y costo de la maquinaria`],
    
      },
      {
        pregunta: `Los registros de datos de una maquinaria agrícola expresan:`,
        respuesta: `Revisión, sellos, fecha, insumos, año de fabricación y datos de maquinaria`,
        distractores: [`Registros de piezas a reponer`, `Datos e indicaciones del catálogo del fabricante`],
    
      },
      {
        pregunta: `Conducir tractores forestales y no tomar medidas preventivas puede producir
    accidentes al operador como:`,
        respuesta: `Desgarros, amputaciones e inclusive la muerte`,
        distractores: [`Daños a la maquinaria`, `Fallos mecánicos`],
    
      },
    
      {
        pregunta: `¿En qué momento se debe utilizar los elementos de seguridad?`,
        respuesta: `Durante todo el tiempo que se use la maquinaria`,
        distractores: [`Cuando detecte algún defecto en el funcionamiento de la maquinaria`, `Cuando el operador considere necesario`],
    
      },
    
      {
        pregunta: `Para poder evitar accidentes el operador debe conocer:`,
        respuesta: `Cómo operar los equipos y la función de todos los controles`,
        distractores: [`Únicamente la dirección del tractor`, `El mantenimiento de maquinaria`],
    
      },
    
    
      {
        pregunta: `¿Qué condiciones ambientales causan daño a la maquinaria agrícola?`,
        respuesta: ` Humedad, rayos ultravioleta y polvo`,
        distractores: [`Lluvia y temperatura ambiente`, `Temperatura ambiente`],
    
      },
    
      {
        pregunta: `El sistema de tracción está formado por :`,
        respuesta: `La ruedas: delanteras de soporte y dirección, y las traseras de soporte y tracción`,
        distractores: [`El embrague, la transmisión y el diferencial`, `El sistema hidráulico y el sistema de control`],
    
      },
    
      {
        pregunta: `Cuando se tiene que dejar el asiento de trabajo aunque sea temporalmente hay que
    cerciorarse de realizar las siguientes acciones de seguridad:`,
        respuesta: `Apoyar los equipos sobre el piso, Conectar los dispositivos de seguridad de los mandos, Aplicar el freno de estacionamiento, Colocar la palanca de control direccional en posición neutral
    Apagar el motor`,
        distractores: [`Apagar el motor, Salir del equipo`, `Ninguna, no es necesario la realización de acciones de seguridad`],
    
      },
    
      {
        pregunta: `Para reducir el riesgo de volcamiento de vehículos o maquinaria agrícola, deberá:`,
        respuesta: `Todas las anteriores`,
        distractores: [`Desplazarse a una velocidad permitida, especialmente en el momento de tomar las curvas`, `Tener en cuenta la densidad de la carga para no sobrepasar las capacidades del vehículo`],
    
      },
    
      {
        pregunta: `Antes de subir a la máquina debe:`,
        respuesta: `Girar alrededor de ella y observar atentamente las personas y los objetos que se
    encuentran en el camino de la máquina`,
        distractores: [`Subir con precaución y encender el motor`, `Solicitar instrucciones al responsable del trabajo`],
    
      },
    
      {
        pregunta: `¿Qué debe hacer en el caso de poca visibilidad?`,
        respuesta: `Encender las luces de carretera y de trabajo`,
        distractores: [`Detener la máquina y apagar el motor`, `Realizar el trabajo con mayor celeridad y mayor cuidado`],
    
      },
    
      {
        pregunta: `El operador de maquinaria debe estar preparado para:`,
        respuesta: `Hacer las verificaciones correspondientes, evitar riesgos y seguir procesos de
    seguridad`,
        distractores: [`Operar así las condiciones sean riesgosas`, `Operar por periodos de trabajo prolongados`],
    
      },
    
      {
        pregunta: `De los siguientes enunciados, ¿Cuál constituye una contravención de tránsito?`,
        respuesta: `Los conductores de vehículos pesados que circulen por zonas restringidas`,
        distractores: [`La o el conductor de vehículos pesados que deje carga en zonas permitidas`, `Los conductores de vehículos pesados que no circulen por zonas restringidas
        `],
    
      },
    
    ]
};
